<sf-loader></sf-loader>
<sf-notification></sf-notification>

<div class="loader-overlay">
    <div>
        <p-progressBar mode="indeterminate"  [style]="{ height: '6px' }" *ngIf="loading"></p-progressBar>
    </div>
</div>
<div *ngIf="!loading">

    <div class="col-12 text-xl">
        <div class="formgrid grid">
            <div class="field col-12">
                <div class="text-2xl text-center font-semibold text-surface-900 dark:text-surface-0">
                    <span translate>label.service</span> #{{itemOrder.code}}
                    <span [style]="{'background-color':''+itemOrder.stageColor+'', 'color': '#FFFFFF'}"
                        [class]="'status-badge-small'">
                        {{itemOrder.statusName}}
                    </span>
                </div>
            </div>

            <div class="flex flex-column mt-2 col-12 md:col-12 lg:col-6 xl:col-6">
                <label class="font-bold text-xl"><span translate>label.client</span></label>
                <label class="text-xl">{{itemOrder.clientName}}</label>
            </div>
            <div class="flex flex-column mt-2 col-12 md:col-12 lg:col-6 xl:col-6">
                <label class="font-bold text-xl"><span translate>label.attendantResponsible</span></label>
                <label class="text-xl">{{itemOrder.userName}}</label>
            </div>

            <div class="flex flex-column mt-2 col-12 md:col-12 lg:col-6 xl:col-6">
                <label class="font-bold text-xl"><span translate>label.task</span></label>
                <label class="text-xl">{{itemTask.code}}</label>
            </div>
            <div class="flex flex-column mt-2 col-12 md:col-12 lg:col-6 xl:col-6">
                <label class="font-bold text-xl"><span translate>label.statusTo</span></label>
                <label class="text-xl">{{itemTask.statusNextName}}</label>
            </div>
        </div>

        <div class="formgrid grid m-5">

            <p-card style="width: 100%;">
                <div style="height: 300px; overflow-y: auto;">

                    <h3>
                        <span translate>label.additionalInformation</span>
                    </h3>

                    <div *ngFor="let form of listForm">

                        <div *ngIf="form.utFormItemType == 'TEXT'">
                            <div class="field col-12">
                                <label for="email" class="mb1 font-bold">
                                    <span>{{form.itemName}}</span>
                                </label>
                                <input pInputText
                                    type="text"
                                    id='{{form.id}}'
                                    [(ngModel)]="form.itemValue"
                                    class="w-full text-xl"
                                    [required]="form.isRequired"
                                    [disabled]="!form.isEnabled"/>
                                <small class="p-invalid" *ngIf="submitted && !form.itemValue"><span translate>label.field_required</span></small>
                            </div>
                        </div>

                        <div *ngIf="form.type == 'DROPDOWN'">
                            <div class="field col-12">
                                <label for="email" class="mb1 font-bold">
                                    <span translate>label.name</span>
                                </label>
                                <input pInputText
                                    type="text"
                                    id='{{form.id}}'
                                    [(ngModel)]="form.itemValue"
                                    class="w-full text-xl"
                                    [required]="form.isRequired"
                                    [disabled]="!form.isEnabled"/>
                                <small class="p-invalid" *ngIf="submitted && !form.value"><span translate>label.field_name_required</span></small>
                            </div>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>

        <div class="field col-12 mt-4">
            <div style="float: right;">
                <button pButton pRipple
                    icon="pi pi-times"
                    style="width: 110px;"
                    class="p-button p-button-danger p-button-outlined p-m-2 mr-2 text-lg font-semibold"
                    [label]="'button.cancel' | translate"
                    (click)="onCancel()">
                </button>
                <button pButton pRipple
                    icon="pi pi-save"
                    style="width: 110px;"
                    class="p-button p-button-primary p-m-2 mr-2 text-lg font-semibold"
                    [label]="'button.save' | translate"
                    (click)="onSaveStatus()"
                    [disabled]="!flUpdate">
                </button>
            </div>
        </div>
    </div>
</div>


<!-- </ng-template>

<ng-template pTemplate="footer">
<div style="float: right;">
    <button pButton pRipple
        icon="pi pi-times"
        style="width: 110px;"
        class="p-button p-button-danger p-button-outlined p-m-2 mr-2 text-lg font-semibold"
        [label]="'button.cancel' | translate"
        (click)="onCancel()">
    </button>
    <button pButton pRipple
        icon="pi pi-save"
        style="width: 110px;"
        class="p-button p-button-primary p-m-2 mr-2 text-lg font-semibold"
        [label]="'button.save' | translate"
        (click)="onSave()">
    </button>
</div>
</ng-template> -->


<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
