import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { DropDownMeta } from 'src/app/shared/model/base-model';
import { WoOrderStatusStageList, WoOrderStatusStageOutput } from 'src/app/models/response/wo-order-status-stage.model';




@Injectable({
    providedIn: 'root'
  })

export class WoOrderStatusStageService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/OrderStatus/Stage/';
    }

    getItem(id: string, language: string): Observable<WoOrderStatusStageOutput> {
        let url = this.environmentUrl + id + "?language="+language;
        return this.httpClient.get<WoOrderStatusStageOutput>(url);
    }

    getList(language: string) : Observable<WoOrderStatusStageList>{
        let url = this.environmentUrl+"?language="+language;
        return this.httpClient.get<WoOrderStatusStageList>(url);
    }

    addItem(model: WoOrderStatusStageOutput, language: string){
        let url = this.environmentUrl;
        model.languageCode = language;
        return this.httpClient.post(url, model);
    }

    updItem(model: WoOrderStatusStageOutput, language: string){
        let url = this.environmentUrl + model.id;
        model.languageCode = language;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        let url = this.environmentUrl + id;
        return this.httpClient.delete(url);
    }

    getDropDownList(language: string): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown?language=" + language;
        return this.httpClient.get<DropDownMeta>(url);
    }






}


