import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ScArchiveTypeGridComponent } from "./pages/sc-archive-type-grid/sc-archive-type-grid.component";
import { AuthorizationGuard } from "src/app/core/guards/authorization.guard";
import { AuthenticationGuard } from "src/app/core/guards/authentication.guard";

const routes: Routes = [
    {
        path: '', component: ScArchiveTypeGridComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data:{roles:['SF_ADMIN']}
    }
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class ScArchiveTypeRoutingModule { }