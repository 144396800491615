import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SfBreadCrumbComponent } from './sf-breadcrumb.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@NgModule({

    declarations: [SfBreadCrumbComponent],
    exports: [SfBreadCrumbComponent],
    imports: [  CommonModule,
                FormsModule,
                BreadcrumbModule
        ]


})

export class SfBreadCrumbModule {

}
