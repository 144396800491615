<div class="login-body">
    <div class="card login-panel">

        <div class="p-grid">
            <div class="col-12 login-icon-container flex align-items-center justify-content-center mt-5">
                <img src="assets/layout/images/sf-logoTabellio.png" style="width: 64px;" alt="TabellioLogo" />
                <span class="fontLoginTabellio">Tabellio</span>
            </div>
            <div class="col-12 lg:col-12 md:col-12 logo-container">
                <p-card [header]="'label.signUp' | translate" class="mt-2 w-full ">
                    <p-steps [model]="items" [readonly]="true" [activeIndex]="activeIndex" ></p-steps>

                        <div class="mt-5 mb-5" *ngIf="activeIndex == 0">
                            <div class="field">
                                <label for="email" class="mb-1 font-bold"><span translate>label.email</span></label>
                                <input type="username" [(ngModel)]="email"  pInputText autofocus class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" email="true" />
                                <small id="username-help" class="p-error block">
                                    <div *ngIf="!valid && !isEmail"><span translate>validation_errors.invalid_email</span></div>
                                </small>
                            </div>

                            <div class="field">
                                <label for="code" class="mb-1 font-bold"><span translate>label.name</span></label>
                                <div class="flex align-items-center justify-content-center">
                                    <input type="name" [(ngModel)]="name"  pInputText class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                                </div>
                                <small id="username-help" class=" p-error block">
                                    <div *ngIf="!valid && !name"><span translate>label.errorRequiredField</span></div>
                                </small>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col">
                                    <label for="pwd" class="mb-1 font-bold"><span translate>label.pwd</span></label>
                                    <div >
                                        <p-password [(ngModel)]="pwd" [toggleMask]="false" class="text-base text-color w-full"></p-password>
                                    </div>
                                    <small id="username-help" class=" p-error block">
                                        <div *ngIf="!valid && !pwd"><span translate>label.errorRequiredField</span></div>
                                    </small>
                                </div>
                                <div class="field col">
                                    <label for="pwd2" class="mb-1 font-bold"><span translate>label.pwd_confirm</span></label>
                                    <div >
                                        <p-password [(ngModel)]="pwd2" [toggleMask]="false" class="text-base text-color w-full"></p-password>
                                    </div>
                                    <small id="username-help" class=" p-error block">
                                        <div *ngIf="!valid &&  (!pwd2 || pwd != pwd2)"><span translate>label.errorPwdConfirmation</span></div>
                                    </small>
                                </div>

                            </div>


                            <div class="field col-12">
                                <div class="flex align-items-center justify-content-center">
                                    <button pButton pRipple icon="pi pi-arrow-times" style="width: 120px; margin: 3px;" class="p-button-danger p-mr-2 mt-3 font-medium" [label]="'button.cancel' | translate" (click)="login();" ></button>
                                    <button pButton pRipple icon="pi pi-arrow-check" style="width: 120px; margin: 3px;" loadingIcon="pi pi-spin pi-sync" [loading]="loading" class="p-button-success p-mr-2 mt-3 font-medium" [label]="'button.confirm' | translate" (click)="save();" ></button>
                                </div>
                            </div>

                        </div>

                        <div class="mt-5 mb-5" *ngIf="activeIndex == 1">
                            <div class="field">
                                <label for="code" class="mb-1 font-bold"><span translate>label.access_code</span></label>
                                <div class="flex align-items-center justify-content-center">
                                    <p-inputMask mask="9-9-9-9-9-9" autofocus class="p-inputtext-lg" [(ngModel)]="accessCode" placeholder="9-9-9-9-9-9"></p-inputMask>
                                </div>
                                <small id="username-help" class="flex align-items-center justify-content-center p-error block">
                                    <div [hidden]="valid"><span translate>validation_errors.invalid_code</span></div>
                                </small>
                            </div>

                            <div class="field col-12">
                                <div class="flex align-items-center justify-content-center">
                                    <button pButton pRipple icon="pi pi-arrow-times" style="width: 120px; margin: 3px;" class="p-button-danger p-mr-2 mt-3 font-medium" [label]="'button.cancel' | translate" (click)="login();" ></button>
                                    <button pButton pRipple icon="pi pi-send" style="width: 150px; margin: 3px;" class="p-button-warning p-mr-2 mt-3" loadingIcon="pi pi-spin pi-sync" [loading]="loading" [label]="'label.resendVerificationEmail' | translate" (click)="sendEmail()"></button>
                                    <button pButton pRipple icon="pi pi-arrow-check" style="width: 120px; margin: 3px;" class="p-button-success p-mr-2 mt-3 font-medium" loadingIcon="pi pi-spin pi-sync" [loading]="loading" [label]="'button.confirm' | translate" (click)="confirmEmail();" ></button>
                                </div>
                            </div>

                        </div>

                        <div class="mt-5 mb-5" *ngIf="activeIndex == 2">
                            <div style="margin-top: 15px;">
                                <p-message severity="success" [text]="'label.signUpEmailConfirmed' | translate" styleClass="p-mr-2" ></p-message>
                            </div>
                            <div class="field col-12">
                                <div class="mt-8 ml-8">
                                    <button pButton pRipple icon="pi pi-users" style="width: 235px; margin: 3px;" class="p-button-warning ml-7 font-medium" [label]="'button.back_login' | translate" (click)="login();" ></button>
                                </div>
                            </div>
                        </div>








                        <!-- <div class="field col-12">
                            <div class="mt-8 ml-8">
                                <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="cancel()"></button>
                                <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="save()"></button>
                                <button pButton pRipple icon="pi pi-send" style="width: 110px; margin: 3px;" class="p-button-warning p-mr-2 mt-3" [label]="'button.resendCode' | translate" (click)="sendEmail()"></button>
                                <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="confirmEmail()"></button>
                            </div>
                        </div>                     -->

                </p-card>

                <div class="p-grid" style="margin-top: 100px;">
                    <div class="p-col-12">
                        <span translate style="margin-left: 200px;">menu.choose_language</span>
                    </div>
                    <div class="col-12 lg:col-12 md:col-12">
                        <div style="margin-left: 180px;">
                            <span class="flag flag-br" style="width: 30px; height: 20px;" (click)="changeLanguage('pt-BR')"
                                style="margin-left: 5px; margin-top: 10px;"></span>
                            <span class="flag flag-gb" style="width: 30px; height: 20px" (click)="changeLanguage('en-US')"
                                style="margin-left: 5px; margin-top: 10px;"></span>
                            <span class="flag flag-pt" style="width: 30px; height: 20px" (click)="changeLanguage('pt-PT')"
                                style="margin-left: 5px; margin-top: 10px;"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>

