import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, of } from "rxjs";
import { Address, AddressCreatedResponse, AddressResponse, City, ScAddressList, ScAddressOutput, ScCityList, ScCountryList, ScStateList, UF } from "src/app/models/response/sc-address.response";
import { environment } from "src/environments/environment";






@Injectable({
    providedIn: 'root'
  })
  export class ScAddressService {

    environmentUrl = '';

    constructor(private http: HttpClient) {
      this.environmentUrl = environment.ApiCore;
     }

   async findCEP(cep: string) : Promise<Address> {

      cep = cep.replace(/\D/g, '');
      if (cep !== '') {
        const validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
          const addressResponse = await firstValueFrom(this.http.get<AddressResponse>(`${this.environmentUrl}/Address/GetAddressByPostalCode/${cep}`));
          console.log(addressResponse.data)
          return addressResponse.data;
          // return this.http.get<ViaCepAddress>(`https://viacep.com.br/ws/${cep}/json`);
        }
      }
    }




    async getAddress(id : string) : Promise<Address> {
      return (await firstValueFrom(this.http.get<AddressResponse>(`${this.environmentUrl}/Address/${id}`))).data;
    }

    getList() : Observable<ScAddressList>{
        let url = this.environmentUrl+"/Address/User/";
        return this.http.get<ScAddressList>(url);
    }


    getAllCitiesByUF(uf : String): Observable<City[]>{
      if(uf != '')
        return this.http.get<City[]>(`https://brasilapi.com.br/api/ibge/municipios/v1/${uf}?providers=dados-abertos-br,gov,wikipedia`)
    }

    getAllUF() : Observable<UF[]>{
      return this.http.get<UF[]>(`https://brasilapi.com.br/api/ibge/uf/v1`)
    }


    async saveAddress(address : Address) : Promise<AddressCreatedResponse> {
      return await firstValueFrom(this.http.post<AddressCreatedResponse>(`${this.environmentUrl}/Address`, address));
    }

    getAllCitiesByUFLocal(stateId : string): Observable<ScCityList>{
      let url = this.environmentUrl + "/City?search=" + stateId;
      return this.http.get<ScCityList>(url);
    }

    getAllUFLocal(countryId: string) : Observable<ScStateList>{
      let url = this.environmentUrl + "/City/State/Country/" + countryId + "?search=" + countryId;
      return this.http.get<ScStateList>(url);
    }

    getAllCountryLocal() : Observable<ScCountryList>{
      let url = this.environmentUrl + "/City/Country";
      return this.http.get<ScCountryList>(url);
    }
  }
