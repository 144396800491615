import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WoOrderServiceGridComponent } from "./wo-order-service/wo-order-service-grid/wo-order-service-grid.component";
import { AuthenticationGuard } from "src/app/core/guards/authentication.guard";
import { AuthorizationGuard } from "src/app/core/guards/authorization.guard";

const routes: Routes = [
    {
        path: '', component: WoOrderServiceGridComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data: {
            roles: ['SF_ADMIN', 'SF_SELLER', 'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_SERVICE']
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class WoOrderServiceRoutingModule { }