import { MoCartorioModule } from './features/mo-cartorio/mo-cartorio-home/mo-cartorio.module';
import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/basic/app.notfound.component';
import {AppErrorComponent} from './pages/basic/app.error.component';
import {AppAccessdeniedComponent} from './pages/basic/app.accessdenied.component';

import { AuthenticationGuard } from './core/guards/authentication.guard';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { DashboardHomeComponent } from './pages/dashboard/dashboard-home.component';

import { ScClaimGridComponent } from './pages/sc-claim/sc-claim/sc-claim-grid.component';
import { ScCompanyGridComponent } from './pages/sc-company/sc-company/sc-company-grid/sc-company-grid.component';
import { ScCompanyDetailComponent } from './pages/sc-company/sc-company/sc_company-detail/sc-company-detail.component';
//import { CompanyDetailResolver } from './pages/sc-company/sc-company/sc_company-detail/sc-company-detail.resolver';
import { ScCompanyStatusGridComponent} from './pages/sc-company/sc-company-status/sc-company-status-grid.component';
import { ScCompanyTypeGridComponent } from './pages/sc-company/sc-company-type/sc-company-type-grid.component';
import { ScMenuTreeComponent } from './pages/sc-menu/sc-menu/sc-menu-tree.component';
import { ScRoleGridComponent } from './pages/sc-role/sc-role/sc-role-grid.component';
import { ScUserDetailComponent } from './features/sc-user/sc-user-detail/sc-user-detail.component';


import { WoWorkFlowGridComponent} from './pages/wo-workflow/wo-workflow-grid.component';
import { WoWorkFlowDetailComponent} from './pages/wo-workflow/wo-workflow-detail/wo-workflow-detail.component';
import { WoOrderCartorioGridComponent } from './modules/cartorio/wo-order-cartorio/wo-order-cartorio-grid/wo-order-cartorio-grid.component';
import { WoOrderCartorioDetailComponent } from './modules/cartorio/wo-order-cartorio/wo-order-cartorio-detail/wo-order-cartorio-detail.component';
import { WoOrderCartorioNewComponent } from './modules/cartorio/wo-order-cartorio/wo-order-cartorio-new/wo-order-cartorio-new.component';
import { WoNotarialSealGridComponent } from './pages/wo-notarial-seal/wo-notarial-seal-grid.component';
import { ScModuleComponent } from './pages/sc-module/sc-module-grid/sc-module-grid.component';
import { ScConfigGridComponent } from './pages/sc-config/sc-config-grid/sc-config-grid.component';
import { WoOrderServiceTemplateGridComponent } from './features/mo-cartorio/wo-order-service/wo-order-service-template/wo-order-service-template-grid/wo-order-service-template-grid.component';
import { WoOrderServiceGridComponent } from './features/mo-cartorio/wo-order-service/wo-order-service/wo-order-service-grid/wo-order-service-grid.component';
import { PaPaymentHomeComponent } from './pages/pa-payment/pa-payment-home/pa-payment-home.component';
import { ScDocumentTypeListComponent } from './pages/sc-document/sc-document-type/sc-document-type-list/sc-document-type-list.component';
import { AppMiddlewareComponent } from './pages/basic/app-middleware/app-middleware.component';

import { WoOrderStatusGridComponent } from './features/wo-order-status/pages/wo-order-status-grid/wo-order-status-grid.component';
import { WoOrderCartorioDetailNewComponent } from './modules/cartorio/wo-order-cartorio/wo-order-cartorio-detail-new/wo-order-cartorio-detail-new.component';
import { ScAddressGridComponent } from './features/sc-address/sc-address-grid/sc-address-grid.component';
import { ScUserGridComponent } from './features/sc-user/sc-user-grid/sc-user-grid.component';
import { ScUserProfileComponent } from './features/sc-user/sc-user-profile/sc-user-profile.component';
import { WoOrderClientDetailComponent } from './features/wo-order/pages/wo-order-client-detail/wo-order-client-detail.component';
import { MoCartorioHomeClientComponent } from './features/mo-cartorio/mo-cartorio-home/mo-cartorio-home-client/mo-cartorio-home-client.component';
import { NotFoundComponent } from './pages/notfound/notfound.component';
import { SfcLandingComponent } from './modules/cartorio/sfc-landing/sfc-landing.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,canActivate: [AuthenticationGuard],

                children: [
  //                  { path: '', component: SfcLandingComponent, canActivate: [AuthenticationGuard] },
                    { path: 'home', component: DashboardHomeComponent, canActivate: [AuthenticationGuard] },
                    { path: 'notfound', component: NotFoundComponent, canActivate: [AuthenticationGuard] },



                    //HOME
                    {
                        path: 'home-c-client',
                        // loadChildren: () =>
                        // import('./features/mo-cartorio/mo-cartorio.routing.module').then(
                        //     (module) => module.MoCartorioRoutingModule,
                        // ),
                        component: MoCartorioHomeClientComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','ROLE_CLIENT']}
                    },

                    {
                        path: 'home-c-service',
                        // loadChildren: () =>
                        // import('./features/mo-cartorio/mo-cartorio.routing.module').then(
                        //     (module) => module.MoCartorioRoutingModule,
                        // ),
                        component: MoCartorioHomeClientComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','ROLE_SERVICE']}
                    },

                    {
                        path: 'home-c-admin',
                        // loadChildren: () =>
                        // import('./features/mo-cartorio/mo-cartorio.routing.module').then(
                        //     (module) => module.MoCartorioRoutingModule,
                        // ),
                        component: MoCartorioHomeClientComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','ROLE_ADMIN']}
                    },

                    {
                        path: 'home-payment',
                        loadChildren: () =>
                        import('./features/payment/home-payment.module').then(
                            (module) => module.HomePaymentModule,
                        ),
                    },


                    //==================================CLIENT=================================================


                    //==================================WORKFLOW===============================================

                    {
                        path: 'form',
                        loadChildren: () => import('./features/ut-form/ut-form.module').then((module) =>
                            module.UtFormModule,),
                    },


                    //security
                    {
                        path: 'claim', component: ScClaimGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    {
                        path: 'company', component: ScCompanyGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','SC_MANAGER_U', 'SC_MANAGER_R']}
                    },
                    {
                        path: 'company-detail/:id', component: ScCompanyDetailComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','SC_MANAGER_U','SC_MANAGER_R']}
                        // resolve: {
                        //     CompanyDetail: CompanyDetailResolver
                        // }
                    },
                    {
                        path: 'company-status', component: ScCompanyStatusGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    {
                        path: 'company-type', component: ScCompanyTypeGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    {
                        path: 'notarial-seal', component: WoNotarialSealGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    {
                        path: 'menu', component: ScMenuTreeComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    // {
                    //     path: 'order-service-new', component: WoOrderNewComponent,
                    //     canActivate: [AuthenticationGuard, AuthorizationGuard],
                    //     data:{roles:['SF_ADMIN','SF_SELLER','SC_MANAGER_U','SC_MANAGER_R']}
                    // },









                    {
                        path: 'role', component: ScRoleGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    {
                        path: 'user', component: ScUserGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','SC_MANAGER_U','SC_MANAGER_R']}
                    },
                    {
                        path: 'user-detail/:id', component: ScUserDetailComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','SC_MANAGER_U','SC_MANAGER_R']}
                    },
                    {
                        path: 'workflow/:type', component: WoWorkFlowGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','WO_WORKFLOW_U','WO_WORKFLOW_R']}
                    },
                    {
                        path: 'workflow/:type/:id', component: WoWorkFlowDetailComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','WO_WORKFLOW_U','WO_WORKFLOW_R']}
                    },

                    {
                        path: 'config', component: ScConfigGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },


                    //=================================================================================
                    //woOrderService
                    //=================================================================================
                    {
                        path: 'order-service', component: WoOrderServiceGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','WO_ORDER_SERVICE_R', 'WO_ORDER_SERVICE_U']}
                    },
                    // {
                    //     path: 'order-service-detail/:id', component: WoOrderServiceDetailComponent,
                    //     canActivate: [AuthenticationGuard, AuthorizationGuard],
                    //     data:{roles:['SF_ADMIN','SF_SELLER','WO_ORDER_SERVICE_R', 'WO_ORDER_SERVICE_U']}
                    // },
                    {
                        path: 'order-service-template', component: WoOrderServiceTemplateGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },

                    //CARTORIO
                    //=================================================================================
                    {
                        path: 'order-service-cartorio-new/:orderClassId', component: WoOrderCartorioNewComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_SERVICE']}
                    },
                    {
                        path: 'order-service-cartorio-new/:orderClassId/:isOnlyTicket', component: WoOrderCartorioNewComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER', 'WO_ORDER_R', 'WO_ORDER_U']}
                    },
                    {
                        path: 'order-service-cartorio-detail-new/:id', component: WoOrderCartorioDetailNewComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_SERVICE','ROLE_CLIENT']}
                    },
                    {
                        path: 'order-service-cartorio/:orderClassId', component: WoOrderCartorioGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_SERVICE']}
                    },
                    {
                        path: 'order-service-cartorio-detail/:id', component: WoOrderCartorioDetailComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','WO_ORDER_R', 'WO_ORDER_U']}
                    },

                    //MODULE
                    {
                        path: 'module', component: ScModuleComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    {
                        path: 'status', component: WoOrderStatusGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },

                    //HOME PAYMENT
                    {
                        path: 'home-payment',
                        loadChildren: () =>
                          import('./features/payment/home-payment.module').then(
                            (module) => module.HomePaymentModule,
                          ),
                    },

                    //=================================================================================
                    //PaPayment
                    //=================================================================================
                    {
                        path: 'payment', component: PaPaymentHomeComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data: {roles:['SF_ADMIN']}
                    },

                    {
                        path: 'address', component: ScAddressGridComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],

                    },

                    //=================================================================================
                    // ScAddress
                    //=================================================================================



                    //=================================================================================
                    // Scuser & ScPerson & Documents
                    //=================================================================================

                    {
                        path: 'address-list',
                        loadChildren: () => import('./features/sc-address/sc-address.module').then((module) =>
                            module.ScAddressModule,),
                    },
                    {
                        path: 'archive-type',
                        loadChildren: () => import('./features/sc-archive-type/sc-archive-type.module').then((module) =>
                            module.ScArchiveTypeModule,),
                    },
                    {
                        path: 'document-type', component: ScDocumentTypeListComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN']}
                    },
                    {
                        path: 'person',
                        loadChildren: () => import('./features/sc-person/sc-person.module').then((module) =>
                            module.SCPersonModule,),
                    },
                    {
                        path: 'user',
                        loadChildren: () => import('./features/sc-user/sc-user.module').then((module) =>
                            module.ScUserModule,),
                    },


                    {
                        path: 'user-profile',component: ScUserProfileComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','ROLE_CLIENT']}
                    },

                    {
                        path: 'person-document',
                        loadChildren: () => import('./features/sc-person-document/sc-person-document.module').then((module) =>
                        module.ScPersonDocumentModule)
                    },


                    //=================================================================================
                    // WoOrder
                    //=================================================================================


                    {
                        path: 'order-detail/:id',component: WoOrderClientDetailComponent,
                        canActivate: [AuthenticationGuard, AuthorizationGuard],
                        data:{roles:['SF_ADMIN','SF_SELLER','ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SUPERVISOR', 'ROLE_SERVICE','ROLE_CLIENT']}
                    },


                    {
                        path: 'order-client-detail',
                        loadChildren: () =>
                            import('./features/wo-order/wo-order.module').then(
                            (module) => module.WoOrderModule,
                            ),
                    },

                    {
                        path: 'order-status',
                        loadChildren: () =>
                            import('./features/wo-order-status/wo-order-status.module').then(
                            (module) => module.WoOrderStatusModule,
                            ),
                    },


                     //=================================================================================
                    // WoQuote
                    //=================================================================================


                    {
                        path: 'quote-detail',
                        loadChildren: () =>
                            import('./features/wo-quote/home-quote.module').then(
                            (module) => module.HomeQuoteModule,
                            ),
                    },

                    //=================================================================================
                    // Modules/Cartorio
                    //=================================================================================
                    {
                        path: 'order-client',
                        loadChildren: () =>
                          import('./modules/cartorio/sfc-wo-order-client/sfc-wo-order-client.module').then(
                            (module) => module.SfcWoOrderClientModule,
                          ),
                    },
                    {
                        path: 'order-select-step',
                        loadChildren: () =>
                          import('./modules/cartorio/select-step/select-step.module').then(
                            (module) => module.SelectStepModule,
                          ),
                    },
                    {
                        path: 'order-sorting',
                        loadChildren: () =>
                            import('./features/mo-cartorio/sfc-wo-order-sorting/sfc-wo-order-sorting.module').then(
                            (module) => module.SfcWoOrderSortingModule,
                            ),
                    },

                    {
                        path: 'sfc-order-service',
                        loadChildren: () =>
                            import('./features/mo-cartorio/wo-order-service/wo-order-service.module').then(
                            (module) => module.WoOrderServiceModule,
                            ),
                    },

                    //=================================================================================
                    // ScUserMessage
                    //=================================================================================
                    {
                        path: 'user-message',
                        loadChildren: () =>
                          import('./features/sc-user-message/sc-user-message.module').then(
                            (module) => module.ScUserMessageModule,
                          ),
                    },                    
                ]
            },

            { path: 'site', component: SfcLandingComponent},
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            // {path: 'notfound', component: AppNotfoundComponent},
            {path: 'middleware/:action', component: AppMiddlewareComponent},
             //{path: 'combo', component: AreaComboComponent },

             //{path: 'user', component: UsuarioGridComponent },

            {
                path: 'login',
                loadChildren: () => import('./login/login.module').then(x => x.LoginModule)
            },

            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

