import { OnInit, Component, Input, Output, EventEmitter, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { SfcReconhecimentoFirmaService } from './sfc-reconhecimento-firma.service';
import { SfcWoOrderOrderServiceOutput, SfcWoOrderOrderServicePersonOutput } from '../../wo-order-cartorio/wo-order-cartorio.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { MessageService } from 'primeng/api';
import { WoOrderCartorioService } from '../../wo-order-cartorio/wo-order-cartorio.service';
import { TranslateService } from '@ngx-translate/core';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { WoNotarialSealService } from 'src/app/pages/wo-notarial-seal/wo-notarial-seal.service';
import { WoNotarialSealOutput } from 'src/app/pages/wo-notarial-seal/wo-notarial-seal.model';
import { WoOrderServiceService } from 'src/app/pages/wo-order-service/wo-order-service/wo-order-service.service';
import { ScArchiveTypeService } from 'src/app/core/services/sc-archive-type/sc-archive-type.service';
import { ScPersonFileOutput } from 'src/app/models/response/sc-person.model';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';

@Component({
    selector: 'sfc-reconhecimento-firma',
    templateUrl: './sfc-reconhecimento-firma.component.html',
    styleUrls: ['./sfc-reconhecimento-firma.component.scss']
})

export class SfcReconhecimentoFirmaComponent implements OnInit, AfterContentChecked {

    receivedData: any;

    @Input() woOrderId: string;
    public selectedItem: string;

    @Input() id: string;
    @Output() chosedItem = new EventEmitter();

    public item: SfcWoOrderOrderServiceOutput = {};
    public isDisabled: boolean = false;
    public userClerkList: DropDown[] = [];
    public listPerson: any[] = [];

    images: any[] = [];
    responsiveOptions: any[];

    public docTypeList: DropDown[] = [];
    public taxTypeList: DropDown[] = [];

    public stampQty: number = 0;
    @Input() scPerson: string = '';
    @Input() canExecuteService: boolean = false;

    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-danger p-m-1 mr-1";
    uploadedFiles: any[] = [];
    uploadFilesModal: boolean = false;

    woOrderOrderServicePerson: SfcWoOrderOrderServicePersonOutput[] = [];
    scPersonIdSelected: string = '';
    listPersonFile: ScPersonFileOutput[] = [];
    loadFiles: boolean = false;
    fieldValidation: boolean = false;
    fileZoomVisible: boolean = false;
    fileZoomUrl: string = '';
    pHighlight: string = 'p-highlight';
    rowIndexHighlight: number = 0;

    listWoNotarialSealOutput: WoNotarialSealOutput[] = [];
    load: boolean;
            
    constructor(  private ReconhecimentoFirmaService: SfcReconhecimentoFirmaService,
                  private translateService: TranslateService,
                  private messageService: MessageService,
                  private orderCartorioService: WoOrderCartorioService,
                  private orderServiceService: WoOrderServiceService,
                  private userTokenService: UserTokenService,
                  private personService: ScPersonService,
                  private woNotarialSealService: WoNotarialSealService,
                  private scArchiveTypeService: ScArchiveTypeService,
                  private cdr: ChangeDetectorRef
    ){ }
    
    ngOnInit(): void {
        console.log("this.id");
        console.log(this.id);

        this.getData();
        this.getList();

        //this.loadImages();
        this.getDocumentType();
        this.getTaxType();
        this.getListNotarialSeal();
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }
    
    getData(){        
        this.orderCartorioService.dataChanged.subscribe((data: any) => {
            this.receivedData = data;



            if (this.receivedData.item == null || this.receivedData.item == "" || this.receivedData.item === undefined){
                this.item.name = this.receivedData.woOrderServiceName;
                this.item.woOrderId = this.receivedData.woOrderId;
                this.item.woOrderServiceId = this.receivedData.woOrderServiceId;
            }
            else {
                this.item = this.receivedData.item;
            }

            this.getPerson();
            this.load = true;
          });

    }

    messageSuccess(message: string){
        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string, consoleMsg: string){
        this.messageService.add({severity: 'warn', summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
        console.log(consoleMsg);
    }

    getList() {
        // this.scClaimService.getDropDownList().subscribe(
        //   {
        //     next: (res) => {
        //         if (res)
        //             this.list = res.data;
        //             if (this.list !== null)
        //                 this.selectedItem = this.list.find(x => x.id == this.id?.toString());
        //     },
        //     error: (err) => {
        //         console.log(`ClaimDropDown - getList : ${err}.`);
        //     }
        // });
      }

    feedback(){
        this.chosedItem.emit(this.selectedItem)
    }

    reciverFeedbackUserClerk(id: string) {
        this.item.scUserClerkId = id;
    }

    reciverFeedbackTaxType(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.woOrderServiceTaxTypeId = null;
        }
        else {
            this.item.woOrderServiceTaxTypeId = id;
        }
    }

    reciverFeedbackDocType(id: string) {
        if (id == null || id == "" || id == undefined) {
            this.item.scArchiveTypeId = null;
        }
        else {
            this.item.scArchiveTypeId = id;
        }
    }

    reciverFeedbackPerson(value: DropDown) {
        // alert(value.name.split(' - ')[0]);
        // alert(value.name.split(' - ')[1]);
        if (value.id !== undefined && value.id !== null) {
            this.loadImages(value.id, 0);
        }
        if (!this.listPerson.find(f => f.id == value.id)) {
            this.listPerson.push({id: value.id, cpf: value.name.split(' - ')[0],  name: value.name.split(' - ')[1]});
            this.listPerson = this.listPerson.sort((a, b) => a.name.localeCompare(b.name));
            if (this.item.woOrderOrderServicePerson === undefined) {
                this.item.woOrderOrderServicePerson = [];
            }
            this.item.woOrderOrderServicePerson.push({ woOrderOrderServiceId: this.item.id, scPersonId: value.id });
        }
    }

    saveItem(){

        if (this.item.woNotorialSealId == null) {
            this.messageService.add({severity: 'warn', summary: 'Aviso', detail: 'Não existe selo disponível.'});
            return;
        }
        
        if (this.item.id == "0" || this.item.id == "0" || this.item.id === undefined) {

            this.orderCartorioService
                .addItem(this.item)
                .subscribe(
                {
                next: (result) => {
                    this.item.id = result.data.id;
                    this.messageSuccess(this.translateService.instant('message.record_inserted'));
                    this.feedbackCancel("save");
                    this.savePics();
                    this.getPerson();
                },
                error: (err) =>
                {
                    this.messageError('api_response.ERROR', `SfcReconhecimentoFirmaComponent - saveItem : ${err}.`);
                    console.log(err)
                }
            });


        } else {

            this.orderCartorioService
            .updItem(this.item)
            .subscribe(
                {
                next: () => {
                    this.messageSuccess(this.translateService.instant('message.record_updated'));
                    this.feedbackCancel("save");
                    //this.savePics();
                    this.getPerson();
                },
                error: (err) =>
                {
                    this.messageError('api_response.ERROR', `SfcReconhecimentoFirmaComponent - saveItem : ${err}.`);
                    console.log(err);
                }
            });
        }

    }

    feedbackCancel(action: string){
        this.item.action = action;
        this.orderCartorioService.reciverFeedback(this.item);
        this.getPerson();
    }

    loadImages(scPersonId: string, index: number) {
        // if (index >= 0) {
        //     this.pHighlight = '';
        // }

        this.rowIndexHighlight = index;

        this.loadFiles = false;
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];

        this.getPersonFileList(scPersonId, this.item.scArchiveTypeId, this.item.id);
    }

    getDocumentType(){
        this.scArchiveTypeService.getDropDownList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.docTypeList = res.data;
                },
                error: (err) => {
                    this.messageError('api_response.ERROR', `SfcAutenticacaoComponent - getDocumentType : ${err}.`);
                }
            });
    }

    getTaxType(){
        this.orderServiceService.getTaxTypeDropDownList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.taxTypeList = res.data;
                },
                error: (err) => {
                    this.messageError('api_response.ERROR', `SfcAutenticacaoComponent - getTaxType : ${err}.`);
                }
            });
    }

    updItem(item: DropDown){

    }

    delItemPerson(item: DropDown){
        if (this.listPerson.find(f => f.id ==  item.id)) {
            this.listPerson = this.listPerson.filter(f => f.id != item.id);
            this.item.woOrderOrderServicePerson = this.item.woOrderOrderServicePerson.filter(f => f.scPersonId != item.id);
            this.loadFiles = false;
        }
    }

    onUpload(event) {
        this.uploadedFiles = event.files;
        this.uploadFilesModal = false;

        if (!(this.item.id == "0" || this.item.id == "0" || this.item.id === undefined)) {
            this.savePics();        
        }
    }

    uploadFiles(item: DropDown) {
        // if (this.item.scPersonDocumentTypeId === undefined || this.item.scPersonDocumentTypeId == null) {
        //     this.messageService.add({severity: 'warn', summary: 'Validação', detail: `Selecione o Tipo Documento.`});
        // } else {
            this.scPersonIdSelected = item.id;
            this.uploadFilesModal = true;
        //}
    }

    getPerson() {        
        this.listPerson = [];
        if (this.item.woOrderOrderServicePerson !== undefined) {
            this.item.woOrderOrderServicePerson.forEach(f => {
                this.listPerson.push({id: f.scPersonId, cpf: f.cpf, name: f.name});
            });

            if (this.listPerson.length > 0) {
                this.pHighlight = 'p-highlight';
                this.loadImages(this.listPerson[0].id, 0);
            }
        }
    }

    savePics() {
        this.personService
        .uploadFile(this.uploadedFiles, this.scPersonIdSelected, this.item.scArchiveTypeId,  this.item.id)
        .subscribe(
            {
            next: () => {
                this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: 'Upload realizado com sucesso.'});
                this.uploadFilesModal = false;
                this.pHighlight = 'p-highlight';
                this.loadImages(this.scPersonIdSelected, 0);
            },
            error: (err) =>
            {
                this.messageService.add({severity: 'error', summary: 'File Uploaded', detail: `SfcReconhecimentoFirmaComponent - onUpload : ${err}.`});
                console.log(err);
            }
        });
    }

    getPersonFileList(scPersonId: string, scArchiveTypeId: string, woOrderOrderServiceId: string) {
        this.personService.getListFile(scPersonId, scArchiveTypeId, woOrderOrderServiceId).subscribe(
          {
            next: (res) => {
                if (res)
                    this.listPersonFile = res.data.sort((a, b) => (Number(b.inTheService) - Number(a.inTheService)) || (a.archiveType.localeCompare(b.archiveType)));

                // this.images = [];
                // this.listPersonFile.forEach(f => {
                //     this.images.push({id: f.id, itemImageSrc: f.pictureUri, thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria1s.jpg'});
                // });

                console.log(this.listPersonFile);
                this.loadFiles = true;
            },
            error: (err) => {
                console.log(`SfcReconhecimentoFirmaComponent - getPersonFileList : ${err}.`);
            }
        });
    }

    getFiledValidation(value: boolean) {
        this.fieldValidation = value;
        return this.fieldValidation;
    }

    openFileZoom(url: string) {
        this.fileZoomVisible = true;
        this.fileZoomUrl = url;
    }

    getListNotarialSeal() {
        this.woNotarialSealService.getList().subscribe(
            {
              next: (res) => {
                  if (res) {
                      this.listWoNotarialSealOutput = res.data;

                    if (this.listWoNotarialSealOutput.length > 0) {
                        this.stampQty = this.listWoNotarialSealOutput.length;
                        this.reserveNotarialSeal(this.listWoNotarialSealOutput.shift());
                    }
                  }

                  //console.log(this.listWoNotarialSealOutput);
              },
              error: (err) => {
                  console.log(`SfcReconhecimentoFirmaComponent - getListNotarialSeal : ${err}.`);
              }
          });
    }

    reserveNotarialSeal(woNotarialSealOutput: WoNotarialSealOutput) {
        if (woNotarialSealOutput != null) {
            this.item.stamp = woNotarialSealOutput.code;            
            this.item.woNotorialSealId = woNotarialSealOutput.id;            
        } else {
            console.log(`SfcReconhecimentoFirmaComponent - reserveNotarialSeal : Not Found`);
        }            
    }

    updOrderOrderServiceFile(scPersonId: string, scPersonFileId: string, inTheService: boolean) {
        if (inTheService == null || inTheService == false) {
            this.loadFiles = false;
            this.orderCartorioService.addItemWoOrderOrderServiceScPersonFile(this.item.id, scPersonFileId).subscribe(
                {
                  next: (res) => {
                    this.getPersonFileList(scPersonId, this.item.scArchiveTypeId, this.item.id);
                    this.loadFiles = true;                      
                  },
                  error: (err) => {
                      console.log(`SfcReconhecimentoFirmaComponent - updOrderOrderServiceFile : ${err}.`);
                  }
              });
        } else {
            this.loadFiles = false;
            this.orderCartorioService.delItemWoOrderOrderServiceScPersonFile(this.item.id, scPersonFileId).subscribe(
                {
                  next: (res) => {
                    this.getPersonFileList(scPersonId, this.item.scArchiveTypeId, this.item.id);
                    this.loadFiles = true;                      
                  },
                  error: (err) => {
                      console.log(`SfcReconhecimentoFirmaComponent - updOrderOrderServiceFile : ${err}.`);
                  }
              });
        }
    }
}
