import { DropDownMeta } from 'src/app/shared/model/base-model';
import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject, firstValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import {ScCompanyInfoInput, ScCompanyInfoList, ScCompanyItem, ScCompanyList, ScCompanyOutput } from './sc-company.model';




@Injectable({
    providedIn: 'any'
})

export class ScCompanyService {

    private environmentUrl: string = '';
    public dialogCompanyAdd = new EventEmitter();
    public dialogCompanyAddNext = new EventEmitter();
    public dialogCompanyAddBack = new EventEmitter();

    public dialogSelectStepAdd = new EventEmitter();
    public dialogSelectStepNext = new EventEmitter();
    public dialogSelectStepBack = new EventEmitter();
    public dialogSelectStepRestart = new EventEmitter();

    public addSteps: ScCompanyInfoInput = {
        code: '',
        name: '',
        fantasyName: '',
        ein: '',
        logoUri:'',
        scCompanyTypeId:'',
        paPlanId:'',
        address:{},
        modules:[],
        configs:[],
        userName:'',
        userEmail:'',
        phone:'',
        scRoleId:''
    };

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiCore + '/Company/';
    }

    getItem(id: string): Observable<ScCompanyItem> {
        let url = this.environmentUrl + id;
        return this.httpClient.get<ScCompanyItem>(url);
    }

    getList(): Observable<ScCompanyList> {
        let url = this.environmentUrl;
        return this.httpClient.get<ScCompanyList>(url);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "Dropdown/";
        return this.httpClient.get<DropDownMeta>(url);
    }

    addItem(model: ScCompanyInfoInput) {
        return this.httpClient.post(this.environmentUrl, model);
    }

    updItem(id: string, model: ScCompanyOutput) {
        let url = this.environmentUrl + id;
        console.log(model);
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        return this.httpClient.delete(this.environmentUrl + id);
    }


//==============================AddSteps==========================================


    private paymentComplete = new Subject<any>();

    paymentComplete$ = this.paymentComplete.asObservable();

    getAddSteps() {
        return this.addSteps;
    }

    setAddSteps(steps) {
        this.addSteps = steps;
    }

    complete() {
        this.addItem(this.addSteps).subscribe();
        this.paymentComplete.next(this.addSteps);
    }



//==============================CompanyType=======================================


    // getComboList(): Observable<ComboBase[]> {
    //     let url = this.environmentUrl + "comboList/";
    //     return this.httpClient.get<ComboBase[]>(url);
    // }

    getListStatusDropDown(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "status/DropDown/";
        return this.httpClient.get<DropDownMeta>(url);
    }

    getListTypeDropDown(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "type/DropDown/";
        return this.httpClient.get<DropDownMeta>(url);
    }

    canChangeLogin(id: string) {
        //TODO canLoginChange
        if (id == "S")
            return true;
        return false;
    }

    async hasAccess(id: string): Promise<boolean> {
        let url = this.environmentUrl + "hasAccess/" + id;
        return await firstValueFrom(this.httpClient.get<boolean>(url));
    }

    //Lista de cartórios
    getListPartners(companyTypeCode: string): Observable<ScCompanyInfoList> {
        let url = this.environmentUrl + "Partners/" + companyTypeCode;
        return this.httpClient.get<ScCompanyInfoList>(url);
    }    
}
