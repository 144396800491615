<div class="login-body">
    <div class="card login-panel p-fluid">
        <div class="login-panel-content">
            <div class="p-grid">


                <div class="col-12 login-icon-container flex align-items-center justify-content-center">
                    <img src="assets/layout/images/sf-logoTabellio.png" style="margin: auto; width: 128px;" alt="TabellioLogo" />
                </div>
                <div class="col-12 login-icon-container flex align-items-center justify-content-center">
                    <span class="fontLoginTabellio">CartorioFacilOnline</span>
                </div>
                <div class="col-12 login-icon-container flex align-items-center justify-content-center">
                    <span class="fontLoginTabellioSlogan">Conectando você ao cartório com tecnologia, inteligência e inovação</span>
                </div>



                <div class="col-12 lg:col-12 md:col-12 logo-container mt-5 font-semibold">
                    <form class="form-login nao-validar" [formGroup]="loginForm" (submit)="login()">

                        <div class="field">
                            <label for="username" class="block"><span translate>label.user_email</span></label>
                            <input #userNameInput id="username" type="username" formControlName="userName" pInputText />
                            <small id="username-help" class="p-error block">
                                <div [hidden]="valid"><span translate>validation_errors.required_user_email</span></div>
                            </small>
                        </div>
                        <div class="field">
                            <label for="pwd" class="block"><span translate>label.pwd</span></label>
                            <input #pwdInput id="pwd" type="password" formControlName="password" aria-describedby="pwd-help"
                                pInputText />

                            <small id="pwd-help" class="p-error block">
                                <div [hidden]="valid"><span translate>validation_errors.required_pwd</span></div>
                            </small>
                        </div>

                        <div class="col-12 lg:col-12 md:col-12 chkbox-container">
                            <div class="remember-me">
                                <input type="checkbox" formControlName="remember" class="p-chkbox p-chkbox-box">
                                <span translate>label.remember_me</span>
                                <div style="float:right" class="link-a">
                                    <a [routerLink]="['forgot-step']"><span translate>label.forgot_pwd</span>?</a>
                                </div>
                            </div>
                        </div>

                        <button pButton type="submit" class="btn btn-primary button-login" loadingIcon="pi pi-spin pi-sync" [loading]="loading" loadingIcon="pi pi-spin pi-sync"
                        ><span class="text-login" translate>button.login</span></button>

                        <div class="col-12 login-icon-container flex align-items-center justify-content-center">
                            <div class="flex flex-row flex-wrap card-container blue-container" style="margin: 20px;">
                                <div class="label-acesso">
                                    <span translate>label.still_have_no_access</span>&nbsp;
                                </div>
                                <div class="link-a">
                                    <a [routerLink]="['signup']"> <span translate>label.sign_up_now</span>!!!</a>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-12 login-icon-container flex align-items-center justify-content-center mt-3 font-medium">
            <span translate >menu.choose_language</span>
        </div>
        <div class="col-12 login-icon-container flex align-items-center justify-content-center">
            <!-- <span class="flag flag-br" style="width: 30px; height: 20px;" (click)="changeLanguage('pt-BR')"
                style="margin-left: 5px; margin-top: 10px;"></span>
            <span class="flag flag-gb" style="width: 30px; height: 20px" (click)="changeLanguage('en-US')"
                style="margin-left: 5px; margin-top: 10px;"></span>
            <span class="flag flag-pt" style="width: 30px; height: 20px" (click)="changeLanguage('pt-PT')"
                style="margin-left: 5px; margin-top: 10px;"></span> -->


                <i class="pi pi-apple ml-5" style="color: rgb(6, 6, 6); font-size: 2.0rem"></i>

                <i class="pi pi-google ml-5" style="color: rgb(194, 6, 6); font-size: 2.0rem"></i>

                <i class="pi pi-microsoft ml-5" style="color: rgb(66, 16, 191); font-size: 2.0rem"></i>

        </div>

        <div class="col-12 login-icon-container flex align-items-center justify-content-center font-medium">
            <span>v1.01</span>
        </div>
        <div class="col-12 login-icon-container flex align-items-center justify-content-center  font-semibold">
            <a href="https://www.serviceflow.com.br">
                <span   span class="fontMenuFooterBlack">powered by</span>
                <span class="fontMenuFooterOrange">serviceflow.com.br</span>
            </a>
        </div>



    </div>
</div>
