<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-col-12" style="margin-top: -5px;"></div>
    <div class="formgrid grid">

        <div class="field col-12 md:col-9 lg:col-9 xl:col-9">
            <div class="card">
                <h3 class="text-center"><span class="font-bold">Atendimento #</span> {{ item.code }}   <span  style="float: right;"> <span [class]="'status-badge-small' " [style]="{'background-color':''+item.stageColor+'', 'color': '#FFFFFF'}">{{item.stageName}} - {{item.statusName}}</span></span>  </h3>

                <div class="formgrid grid text-lg">

                    <div class="field col-12 md:col-2">
                        <label for="firstname2" class="font-bold">Cliente - Documento</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-id-card"></i>
                            </span>
                            <input pInputText placeholder="Cliente - Nome" [value]="item.clientDoc" />
                        </div>
                    </div>

                    <div class="field col">
                        <label for="firstname2" class="font-bold">Cliente - Nome<p-rating style="margin-left: 30px;"  [ngModel]="item.clientRating" [readonly]="true" [cancel]="false"></p-rating></label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-user"></i>
                            </span>
                            <input pInputText placeholder="Cliente - Nome" [value]="item.clientDoc" />
                        </div>
                    </div>

                    <div class="field col">
                        <label for="firstname2" class="font-bold">Atendente Responsável</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-users"></i>
                            </span>
                            <input pInputText placeholder="Responsável Atendimento" [value]="item.userName" />
                        </div>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="firstname2" class="font-bold">Data Abertura</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-calendar"></i>
                            </span>
                            <input pInputText placeholder="Username" [value]="item.createdDate | date: 'dd/MM/yyyy HH:mm'" />
                        </div>
                    </div>
                </div>

                <div class="formgrid grid text-lg">

                    <div class="field col">
                        <label for="firstname2" class="font-bold">Observações</label>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-comment"></i>
                            </span>
                            <input pInputText placeholder="Cliente - Nome" [value]="item.clientDoc" /><div  style="margin-left: 10px; margin-top: -13px;"><button pButton pRipple icon="pi pi-search" [class]="classBtnUpd" (click)="save();"></button></div>
                        </div>
                    </div>
                </div>

             </div>  <!--Main Card -->


            <div class="card">
                <div class="p-col-12 p-md-12">
                    <p-tabView styleClass="tabview-custom">
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">view_list</span>
                                <span class="ml-2">Relação de Serviços</span>
                            </ng-template>
                            <p>
                                <button (click)="loadLazyComponent()">Load Lazy Component</button>
                                <ng-container *ngIf="lazyComponent">
                                  <ng-container *ngComponentOutlet="lazyComponent"></ng-container>
                                </ng-container>
                            </p>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">account_box</span>
                                <span class="ml-2">Detalhes do Cliente</span>
                            </ng-template>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">folder_shared</span>
                                <span class="ml-2"> Documentos Associados</span>
                            </ng-template>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <span class="material-icons">description</span>
                                <!-- <i class="pi pi-list"></i> -->
                                <span class="ml-2">Detalhes do Atendimento</span>
                            </ng-template>
                            <p>

                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </p-tabPanel>

                    </p-tabView>
                </div>
            </div>
    </div>
    <div class="col-12 md:col-3 lg:col-3 xl:col-3 p-order-3 p-order-lg-2">






            <div class="card">
                <h5 class="text-center font-bold">Pagamento </h5>
                <div class="formgrid grid">
                    <div class="field col">
                        <span>Recompe</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled">
                        </div>
                    </div>

                    <div class="field col">
                        <span>Emolumentos</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled">
                        </div>
                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <span>Taxa Fiscalização</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled">
                        </div>
                    </div>

                    <div class="field col">
                        <span>ISS</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled">
                        </div>
                    </div>
                </div>

                <div class="formgrid grid">
                    <div class="field col">
                        <span>Quantidade Serviços</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-list"></i>
                            </span>
                            <input type="text" pInputText [disabled]="isDisabled">
                        </div>
                    </div>

                    <div class="field col">
                        <span>Valor Total</span>
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">R$</span>
                            <input type="text" pInputText [disabled]="isDisabled">
                        </div>
                    </div>
                </div>


                <div class="formgrid grid">
                    <div class="field col">

                        <label for="firstname2">Forma de Pagamento</label>
                        <!-- <p-dropdown [options]="formasPagamento" [(ngModel)]="formaPagamentoSelecionada"
                            optionLabel="nome" placeholder="Selecione uma Forma de Pagamento"
                            (onChange)="onDropDownPagamentoChange($event)"></p-dropdown> -->
                    </div>
                </div>





            </div>

            <div class="card">
                <div class="p-field p-col-12">
                    <div class="card">
                        <h5 class="text-center font-bold">Histórico do Atendimento </h5>
                        <p-timeline [value]="events">
                            <ng-template pTemplate="content" let-event>
                                <small class="p-text-secondary">{{ event.date }}</small>
                            </ng-template>
                            <ng-template pTemplate="opposite" let-event>
                                {{ event.status }}
                            </ng-template>
                        </p-timeline>

                    </div>

                </div>
            </div>









    </div>
    <div class="field col-12 md:col-12 lg:col-12 xl:col-12">
        <div class="card">
            <h5 class="text-center font-bold">Pagamento</h5>
            <div class="formgrid grid">
                <div class="field col">
                    <span>Recompe</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled">
                    </div>
                </div>

                <div class="field col">
                    <span>Emolumentos</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled">
                    </div>
                </div>
                <div class="field col">
                    <span>Taxa Fiscalização</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled">
                    </div>
                </div>
                <div class="field col">
                    <span>ISS</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled">
                    </div>
                </div>
                <div class="field col">
                    <span>Quantidade Serviços</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-list"></i>
                        </span>
                        <input type="text" pInputText [disabled]="isDisabled">
                    </div>
                </div>

                <div class="field col">
                    <span>Valor Total</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled">
                    </div>
                </div>
                <div class="field col">

                    <label for="firstname2">Forma de Pagamento</label>
                    <!-- <p-dropdown [options]="formasPagamento" [(ngModel)]="formaPagamentoSelecionada"
                        optionLabel="nome" placeholder="Selecione uma Forma de Pagamento"
                        (onChange)="onDropDownPagamentoChange($event)"></p-dropdown> -->
                </div>
            </div>

        </div>
    </div>


</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>






  <!-- <span class="p-buttonset" >
            <div *ngIf="showBtn">
             <div *ngFor="let task of listWorkflowTask"  style="margin-bottom:5px; margin-right:5px; display: inline-block;">

                    <button pButton pRipple label='{{task.name}}' icon="{{task.statusIcon}}" (click)="updStatus(task.woOrderStatusNextId);" style="width: 200px;"></button>

            </div>
        </div>
            <div   style="margin-bottom:5px; margin-right:5px; display: inline-block;">
                <button pButton pRipple label="Imprimir Atendimento" icon="pi pi-print" class="p-button-success p-mr-2" (click)="save()"  style="width: 200px;"></button>
            </div>
            <div   style="margin-bottom:5px; margin-right:5px; display: inline-block;">
            <button pButton pRipple icon="pi pi-times" style="width: 200px; " class="p-button-danger p-mr-2 mt-3 font-medium" label="Fechar e Voltar" (click)="closeCancel();"></button>
        </div>

        </span>

        <p-scrollPanel [style]="{width: '100%', height: '700px'}" styleClass="customized-timeline">
            <p-card [header]="'label.user_history' | translate">
                <div style="height: 500px;  overflow-y: scroll;">
                    <p-timeline [value]="listOrderStatusHist" align="alternate" styleClass="customized-timeline">
                        <ng-template pTemplate="marker" let-event>
                            <span class="custom-marker shadow-2" [style.backgroundColor]="event.entityColor">
                                <i [ngClass]="event.entityIcon"></i>
                            </span>
                        </ng-template>
                        <ng-template pTemplate="content" let-event>
                            <p-card [header]="event.entityDescription" [class]="card">
                                <div class="grid">
                                    <div class="col p-text-secondary"><label class="font-bold"><span translate>label.updatedBy</span></label> : {{event.userLoginUpdated}}</div>
                                </div>
                                <div class="grid mt-1">
                                    <div  class="col p-text-secondary"><label class="font-bold"><span translate>label.date</span></label> : {{event.updatedAt | date: 'dd/MM/yyyy'}}</div>
                                </div>
                            </p-card>
                        </ng-template>
                    </p-timeline>
                </div>
            </p-card>
        </p-scrollPanel> -->
