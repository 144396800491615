import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { BehaviorSubject } from 'rxjs';
import { UserToken } from '../auth.model';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';


@Injectable({providedIn:'root'})

export class UserTokenService{
    private userName = '';
    public roles = [];
    private id: string = "";
    private userSubject = new BehaviorSubject<UserToken>(null);



    constructor(private tokenService: TokenService, private router: Router){
       this.tokenService.hasToken() && this.decodeAndNotify();
    }

    setToken(token: string ){
        this.tokenService.setToken(token);
        this.decodeAndNotify();
    }

    getUser(){
        return this.userSubject.asObservable();
    }

    getUserId(){
        return this.userSubject.getValue().nameid;
    }


    getPersonId(){

        return this.userSubject.getValue().PersonId;
    }

    getUserSignatureId(){
        return this.userSubject.getValue().ScUserSignatureId;
    }

    getCompanyId(){
        return this.userSubject.getValue().CompanyId;
    }

    getCompanyName(){
        return this.userSubject.getValue().ScCompanyName;
    }

    getCompanyConfigList(){
        return this.userSubject.getValue().ScCompanyConfig;
    }

    getCompanyConfig(value: string){
        return this.userSubject.getValue().ScCompanyConfig.includes(value+";");
    }

    getLogoUri(){
        return this.userSubject.getValue().LogoUri;
    }

    getPitureUri(){
        return this.userSubject.getValue().PictureUri;
    }

    getProfile(){
        return this.userSubject.getValue().profile;
    }

    private decodeAndNotify(){


        const token = this.tokenService.getToken();

        if (token == null || token == "undefined"){
            this.logout();
        }


        const user = jwtDecode(token) as UserToken;
        this.userName = user.unique_name;
        this.id = user.nameid;
        this.roles = [];

        //if(user.roles !=undefined)
        if (Array.isArray(user.role))
            this.roles = user.role;
        else
            this.roles.push(user.role);
        this.userSubject.next(user);
    }

    logout(){
        this.tokenService.removeToken();
        this.userSubject.next(null);
        this.router.navigate(['/login'])
    }

    isLogged(){
        return this.tokenService.hasToken();
    }

    getUserName(){
        return this.userName;
    }

    getRoles(){
        return this.roles;
    }

    public hasPermission(role:string){
            return this.roles.includes(role);
    }

    hasPermissionArray(role:string[]){
        let access = false;
        if (Array.isArray(role))
        {
            if (this.roles == null)
            {
                this.decodeAndNotify();
            }

            this.roles.some(function (value) {
                if (role.indexOf(value) > -1)
                {
                    access = true;
                    return true;
                }
            });
        }

        return access;
    }

    hasAnyProfile(profiles:string[]){
        let access = false;
        if (Array.isArray(profiles))
        {
            if (this.userSubject.getValue().profile == null)
            {
                this.decodeAndNotify();
            }


            let profile = this.userSubject.getValue().profile

            profiles.some(function (value) {
                    //console.log(role.indexOf(value));
                    if (profile == value)
                    {
                        access = true;
                        return true;
                    }
                });
        }

        return access;
    }

}
