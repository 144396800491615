<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="formgrid grid">
    <div class="field col-12 md:col-12 lg:col-12 xl:col-12">

        <div class="col-12">
            <div class="formgrid grid nested-grid">
                <div class="col-10 formgrid grid ">
                    <div class="field col-12 md:col-2">
                        <label for="code" class="mb1 font-bold"><span translate>label.code</span></label>
                        <input id="code" type="text" [(ngModel)]="item.code" [disabled]="!canUpdate" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-12 md:col-7">
                        <label for="name" class="mb1 font-bold"><span translate>label.name</span></label>
                        <input id="name" type="text" [(ngModel)]="item.name" [disabled]="!canUpdate" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="icon" class="mb1 font-bold"><span translate>label.icon</span></label>
                        <div *ngIf="canUpdate">
                            <sf-dropdown-icon [icon]="item.icon" (chosedIcon)="reciverFeedbackIcon($event)" [placeHolderLanguage]="'label.select_icon' | translate" class="text-base text-color appearance-none outline-none w-full"> </sf-dropdown-icon>
                        </div>
                        <div *ngIf="!canUpdate">
                            <i class={{item.icon}}></i> &nbsp; {{item.icon}}
                        </div>
                    </div>
                    <div class="field col-12 md:col-1">
                        <label for="color" class="mb1 font-bold"><span translate>label.color</span></label>
                        <div *ngIf="canUpdate">
                            <sf-dropdown-color [color]="item.color" [isInLine]="false" (chosedColor)="reciverFeedbackColor($event)"></sf-dropdown-color>
                        </div>
                        <div *ngIf="!canUpdate">
                            <span [class]="'status-badge'" [style]="{'background-color':''+item.color+'', 'color': '#FFFFFF'}">{{item.color}}</span>
                        </div>
                    </div>


                    <div class="field col-12 md:col-2">
                        <label for="name" class="mb1 font-bold"><span translate>label.workflow</span></label>
                        <label class="text-base text-color  appearance-none outline-none  w-full">{{labelWorkFlowType}}</label>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="name" class="mb1 font-bold"><span translate>label.createdBy</span></label>
                        <label class="text-base text-color appearance-none outline-none  w-full">{{item.userCreated}}</label>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="name" class="mb1 font-bold"><span translate>label.createdAt</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <label class="text-base text-color appearance-none outline-none  w-full">{{item.createdAt | date: 'dd/MM/yyyy HH:mm:ss'}}</label>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="name" class="mb1 font-bold"><span translate>label.updatedBy</span></label>
                        <label class="text-base text-color appearance-none outline-none  w-full">{{item.userCreated}}</label>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="name" class="mb1 font-bold"><span translate>label.updatedAt</span></label>
                        <label class="text-base text-color  appearance-none outline-none  w-full">{{item.updatedAt | date: 'dd/MM/yyyy HH:mm:ss'}}</label>
                    </div>
                    <div class="field col-12 md:col-2">
                        <div class="field-checkbox">
                            <p-checkbox [(ngModel)]="item.isActive" binary="true" id="flAtivo" name="isActive" ngDefaultControl class="p-d-inline mt-3" [disabled]="!canUpdate"> </p-checkbox>
                            <label for="active" class="mb1 font-bold  mt-3"><span translate>label.active</span></label>
                        </div>
                    </div>
                </div>

                <div class="field col-2 ">
                    <button pButton pRipple icon="pi pi-times" style="width: 160px; margin: 3px;" class="p-button-danger p-mr-2 mt-3 font-medium" [label]="'button.cancel_close' | translate" (click)="cancel();"  *ngIf="canUpdate"></button>
                    <button pButton pRipple icon="pi pi-check" style="width: 160px; margin: 3px;" class="p-button-success p-mr-2 mt-3 font-medium" [label]="'button.confirm' | translate" (click)="saveItem();"  *ngIf="canUpdate" ></button>
                    <!-- <button pButton pRipple icon="pi pi-arrow-left" style="width: 160px; margin: 3px;" class="p-button-warning p-mr-2 mt-3 font-medium" [label]="'button.close_back' | translate" (click)="close();"></button> -->
                </div>


            </div>
        </div>
    </div>

    <!-- ======================= DADOS DA TASK  =============================== -->



</div>
<div  class="mt-3">
    <p-card [header]="'label.workflowTaskService' | translate">
        <!-- <div style="height: 470px;  overflow-y: scroll;"> -->
            <div class="p-grid">
                <div class="p-col-12">
                    <p-table #dt [value]="listWorkFlowTask" dataKey="id" [paginator]="true" [rows]="15"
                    [showCurrentPageReport]="true"
                    [currentPageReportTemplate]="pageMessage"
                    [rowsPerPageOptions]="[100,200,500]"
                    [globalFilterFields]="['code','name','statusStageName','statusFromName','statusNextName','taskFormTemplate']"
                    styleClass="p-datatable-responsive-demo p-datatable-striped"  [showCurrentPageReport]="true">

                    <ng-template pTemplate="caption">
                        <div class="flex">
                            <button pButton pRipple icon="pi pi-plus" [label]="'button.TaskNew' | translate" class="p-button-primary p-mr-2" style="width: 150px;"
                            (click)="addTask()" ></button>

                            <span class="p-input-icon-left ml-auto">
                                <i class="pi pi-search"></i>
                                <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.workflow' | translate" />
                                <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                            </span>
                            <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header">
                        <tr>
                            <th  style="width: 180px; text-align: center;" pSortableColumn="statusStageName"><span translate>label.stage</span> <p-sortIcon field="statusStageName"></p-sortIcon></th>
                            <th pSortableColumn="statusFromName"><span translate>label.statusFrom</span><p-sortIcon field="statusFromName"></p-sortIcon></th>
                            <th  pSortableColumn="code"><span translate>label.task</span><p-sortIcon field="code"></p-sortIcon></th>
                            <th pSortableColumn="statusNextName"><span translate>label.statusTo</span><p-sortIcon field="statusNextName"></p-sortIcon></th>
                            <th style="width: 400px; text-align: center;"><span translate>label.allowedRoles</span></th>
                            <th><span translate>label.order</span></th>
                            <!-- <th pSortableColumn="taskFormTemplate"><span translate>label.formTemplate</span><p-sortIcon field="taskFormTemplate"></p-sortIcon></th> -->
                            <th style="width: 105px; text-align: center;"><span translate>label.actions</span></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>

                            <td><span [class]="'status-badge'" [style]="{'background-color':''+item.statusStageColor+'', 'color': '#FFFFFF'}">{{item.statusStageName}}</span> </td>
                            <td><span class="font-bold" [style]="{'color':''+item.statusFromColor+''}">{{item.statusFromName}}</span></td>
                            <td>{{item.code}}</td>
                            <td><span class="font-bold" [style]="{'color':''+item.statusNextColor+''}">{{item.statusNextName}}</span></td>
                            <td>
                                <div class="card flex align-items-center gap-2 flex-wrap">
                                    <div *ngFor="let roleItem of item.roles"><p-chip [label]="roleItem.name"></p-chip></div>
                                </div>
                            </td>
                            <!-- <td>{{item.taskFormTemplate}}</td> -->
                            <td>{{item.order}}</td>
                            <td>
                                <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updTask(item)"  [disabled]="!canUpdate"></p-button>
                                <p-button icon="pi pi-trash" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delTask(item)" [disabled]="!canUpdate"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>

                </div>
            </div>
       <!-- </div> -->
    </p-card>
</div>

<p-dialog [(visible)]="displayDialog" [style]="{width: '800px', heigth: '600px'}" [modal]="true" styleClass="p-fluid" [header]="dialogHeader">
    <ng-template pTemplate="content">
        <div class="mt-2 formgrid grid">
            <div class="col-12 md:col-6 lg:col-6 xl:col-6">

                <div class="p-field mt-2">
                    <label for="statusFrom"  class="font-bold label-detail text-lg mt-2"><span translate>label.statusFrom</span></label>
                    <sf-dropdown-generic InputId="statusFrom" [list]="listStatusFrom" [id]="itemTask.woOrderStatusFromId" (selectedItemOutput)="reciverFeedbackStatusFrom($event)" ></sf-dropdown-generic>
                </div>

                <div class="p-field mt-2">
                    <label for="name" class="font-bold label-detail text-lg mt-2"><span translate>label.task</span></label>
                    <input type="text" pInputText id="code" [(ngModel)]="itemTask.code" required />
                    <small class="p-invalid" *ngIf="submitted && !itemTask.code"><span translate>label.field_required</span></small>
                </div>

                <div class="p-field mt-2">
                    <label for="statusNext"  class="font-bold label-detail text-lg mt-2"><span translate>label.statusTo</span></label>
                    <sf-dropdown-generic InputId="statusNext" [list]="listStatusNext" [id]="itemTask.woOrderStatusNextId" (selectedItemOutput)="reciverFeedbackStatusNext($event)" ></sf-dropdown-generic>
                </div>

                <div class="p-field mt-2">
                    <label for="name" class="font-bold label-detail text-lg mt-2"><span translate>label.description</span></label>
                    <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" [(ngModel)]="itemTask.name"></textarea>
                </div>

                <div class="p-field mt-2">
                    <label for="name" class="font-bold label-detail text-lg mt-2"><span translate>label.order</span></label>
                    <input type="text" pInputText id="order" [(ngModel)]="itemTask.order" required />
                    <small class="p-invalid" *ngIf="submitted && !itemTask.order"><span translate>label.field_required</span></small>
                </div>



            </div>
            <div class="col-12 md:col-6 lg:col-6 xl:col-6">
                <div class="p-field mt-2">
                    <label for="claim" class="font-bold label-detail text-lg mt-2"><span translate>label.allowedRoles</span></label>
                    <!-- <sf-sc-role-list-multi InputId="role" [selectedItems]="selectedRoles" [placeHolderLanguage]="'label.select_role' | translate" (chosedItem)="reciverFeedbackRole($event)"></sf-sc-role-list-multi> -->
                    <p-listbox [options]="listRole" [(ngModel)]="this.itemTask.roles" [selectAll]="selectAll" optionLabel="name" [style]="{ width: '100%' }" [filter]="true"
                        [multiple]="true" [checkbox]="true" [metaKeySelection]="false" [showToggleAll]="true" [listStyle]="{ 'min-height': '250px', 'max-height': '350px' }"></p-listbox>

                </div>

            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div style="float: right;" >
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveTask()"  [disabled]="!canUpdate"></button>
        </div>
    </ng-template>
</p-dialog>







