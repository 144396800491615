<section class="c-item-registration">
    <div class="c-item-registration__header">
        <span class="c-item-registration__header--title">Upload Documentos - {{scPersonName}}</span>
    </div>
    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-12 lg:col-8">

            <div *ngIf="listPersonFile.length > 0" class="card">
                <p-galleria *ngIf="loadFiles" [(visible)]="loadFiles" #galleria [(value)]="listPersonFile"
                    [(activeIndex)]="activeIndex" [numVisible]="5" [showThumbnails]="showThumbnails"
                    [showItemNavigators]="true" [showItemNavigatorsOnHover]="true" [circular]="true" [autoPlay]="true"
                    [transitionInterval]="3000" [containerStyle]="{'width':'100%'}" [containerClass]="galleriaClass()">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.pictureUri" style="width: 100%; max-height: 55vh; display: block;" />
                    </ng-template>
                    <ng-template pTemplate="thumbnail" let-item>
                        <div class="grid grid-nogutter justify-content-center">
                            <img height="50" width="50" [src]="item.pictureUri" style="display: block;" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="footer" let-item>
                        <div class="custom-galleria-footer">
                            <button type="button" pButton icon="pi pi-list" (click)="onThumbnailButtonClick()"></button>
                            <span *ngIf="listPersonFile" class="title-container">
                                <span>{{ activeIndex + 1 }}/{{ listPersonFile.length }}</span>
                                <span class="title">{{ listPersonFile[activeIndex].createdAt | date : 'dd/MM/yyyy
                                    HH:mm:ss'
                                    }} - {{listPersonFile[activeIndex].archiveType}}</span>
                                <span>{{ listPersonFile[activeIndex].userName }}</span>
                            </span>
                            <button type="button" pButton [icon]="fullScreenIcon()" (click)="toggleFullScreen()"
                                class="fullscreen-button"></button>
                        </div>
                    </ng-template>
                </p-galleria>
            </div>


            <label *ngIf="listPersonFile.length == 0" translate>label.empt</label>
        </div>

        <div class="field col-12 sm:col-12 md:col-12 lg:col-4">
            <div class="col-12 sm:col-12 md:col-12 lg:col-12">
                <div class="card flex justify-content-center">
                    <p-fileUpload #fileUpload [disabled]="fieldValidation" name="files[]"
                        (uploadHandler)="onUpload($event)" [multiple]="true" accept="image/*" maxFileSize="1000000"
                        chooseLabel="Adicionar" cancelLabel="Cancelar" chooseStyleClass="p-button-info"
                        uploadStyleClass="p-button-info" cancelStyleClass="p-button-info" customUpload="true">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                </div>
            </div>
            <div class="field col-12 sm:col-12 md:col-12 lg:col-12">
                <label class="mb1 font-bold">Tipo Documento</label>
                <sf-dropdown-generic [list]="docTypeList" (selectedItemOutput)="reciverFeedbackDocType($event)"
                    [id]="docTypeId"></sf-dropdown-generic>
                <small class="p-invalid" *ngIf="getFiledValidation(!scArchiveTypeId)"
                    translate>validation_errors.required_field</small>
            </div>
            <div class="field col-12 sm:col-12 md:col-12 lg:col-12">
                
                    <p-button icon="pi pi-arrow-left" [style]="{'width': '100%'}" styleClass="p-button-info" label="Dados Pessoais - {{scPersonName}}"
                        (click)="onGoBack()"></p-button>
                
            </div>
        </div>    
    </div>
    
    <div *ngIf="fileZoomVisible" class="overlay">
        <p-dialog header="Zoom" [(visible)]="fileZoomVisible" [style]="{width: '80vw', height: '80vh'}">
            <div class="card flex justify-content-center">
                <p-image [src]="this.fileZoomUrl" [preview]="true" alt="Image" width="250">
                    <ng-template pTemplate="indicator">
                        <i class="pi pi-check"></i>
                    </ng-template>
                </p-image>
            </div>
        </p-dialog>
    </div>
</section>