<p-dropdown 
    [options]="list" 
    [(ngModel)]="selectedItem" 
    optionLabel="name" 
    [filter]="true"
    filterBy="name" 
    [showClear]="true" 
    [placeholder]="placeHolderLanguage" 
    (onChange)="feedback()"
    [style]="{'width':'100%', 'height': '40px'}">
</p-dropdown>
