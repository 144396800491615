import { Component, Input } from '@angular/core';
import { BreadCrumb } from './sf-breadcrumb.model';
import { BreadcrumbModule } from 'primeng/breadcrumb';

@Component({
  selector: 'sf-breadcrumb',
  templateUrl: './sf-breadcrumb.component.html',
  styleUrls: ['./sf-breadcrumb.component.scss'],
})
export class SfBreadCrumbComponent {
  @Input() model: Array<BreadCrumb> = [];
}
