import { Component, OnInit, EventEmitter, ComponentFactoryResolver, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService, ConfirmationService } from "primeng/api";


import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';


import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { WoWorkFlowService } from 'src/app/pages/wo-workflow/wo-workflow.service';
import { WoOrderOutput, WoOrderStatusHist } from 'src/app/pages/wo-order/wo-order.model';
import { WoOrderService } from 'src/app/pages/wo-order/wo-order.service';
import { WoWorkFlowTaskOutput } from 'src/app/pages/wo-workflow/wo-workflow.model';


import { DropDown } from 'src/app/shared/model/base-model';
import { SfcServiceAutenticacaoComponent } from '../../component/sfc-service-autenticacao/sfc-service-autenticacao.component';
import { SfcReconhecimentoFirmaComponent } from '../../component/sfc-reconhecimento-firma/sfc-reconhecimento-firma.component';
import { SfcWoOrderOrderServiceValue } from '../wo-order-cartorio.model';
import { WoOrderStatusService } from 'src/app/core/services/wo-order-status/wo-order-status.service';





@Component({
    selector: 'wo-order-cartorio-detail',
    templateUrl: './wo-order-cartorio-detail.component.html',
    styleUrls: ['./wo-order-cartorio-detail.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class WoOrderCartorioDetailComponent implements OnInit {

    public listWorkflowTask: WoWorkFlowTaskOutput[] = [];
    public listOrderStatusHist: WoOrderStatusHist[] = [];
    public totalValue: SfcWoOrderOrderServiceValue = { id: "", code: "", codeExternal: "", name: "", valueA: 0, valueB: 0, valueTaxA: 0, valueTaxB: 0, quantity: 0, total: 0 };


    public orderType: string;
    public language: string = "pt-BR";

    public item: WoOrderOutput = {};
    public id: string;
    public idPaymentStatus: string;

    public activeIndex: number = 0;


    public flUpdate: boolean = true;
    public isDisabled: boolean = true;
    public showBtn: boolean = false;
    public canUpdatePaymentStatus: boolean = false;

    public message: string = "";
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-mr-2 mt-3 font-medium";

    public lazyComponent: any;
    componentMap = {
        'SfcServiceAutenticacaoComponent': SfcServiceAutenticacaoComponent,
        'SfcReconhecimentoFirmaComponent': SfcReconhecimentoFirmaComponent
      };

    formPaymentModal = false;
    paymentId = '6b3264a7-d1a2-4628-a3e8-d55a7739871c';


    constructor(private orderService: WoOrderService,
        private orderStatusService: WoOrderStatusService,
        private workFlowService: WoWorkFlowService,
        private userTokenService: UserTokenService,
        private activateRoute: ActivatedRoute,
        private notificationsService: NotificationsService,
        private confirmationService: ConfirmationService,
        private translateService: TranslateService,
        private translateConfigService: TranslateConfigService,
        private breadcrumbService: BreadcrumbService,
        private injector: Injector,
        private router: Router) { }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();
        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'WO_ORDER_U']);

        if (this.flUpdate == false) {
            this.classBtnUpd = "p-button p-button-secondary p-mr-2 mt-3 font-medium";
        }

        //this.item = this.activateRoute.snapshot.data['ScCompany'].company;

        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('id')) {
                this.id = params.get('id');
                this.getItem();
            }
            else
                this.translate();
        });



    }

    translate() {
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
            await sleep(100);
            const labOrder = this.translateService.instant('label.order') + " ";
            const labService = this.translateService.instant('label.order_service') + " ";
            const labServiceDetail = " " + this.translateService.instant('label.order_service_detail');
            this.breadcrumbService.setItems([{ label: labOrder }, { label: labService, routerLink: ['order-service'] }, { label: labServiceDetail, routerLink: ['order-service-detail/' + this.item.id] }])
        })();
    }

    loadComponent(component: any) {
      //  this.container.clear();
      //  this.container.createComponent(component, { injector: this.injector });
      }

    async loadLazyComponent(id: number) {
        if (id == 1) {
            const module = await import('../../component/sfc-service-autenticacao/sfc-service-autenticacao.component');
            this.lazyComponent = module.SfcServiceAutenticacaoComponent;
        }
        else if (id == 2) {
            const module = await import('../../component/sfc-reconhecimento-firma/sfc-reconhecimento-firma.component');
            this.lazyComponent = module.SfcReconhecimentoFirmaComponent;
        }


    }

    getItem() {
        this.orderService.getItem(this.id).subscribe(
            {
                next: (res) => {
                    if (res) {
                        this.item = res.data;
                        this.getStatusNext();
                        // this.getListOrderStatusHist();
                        console.log(this.item.woPaymentId);
                    }
                },
                error: (err) => {
                    console.log(`WoOrderDetailComponent - getServiceItem : ${err}.`);
                }
            });
    }

    // getListOrderStatusHist() {
    //     this.orderStatusService.getStatusHist(this.id, this.language).subscribe(
    //         {
    //             next: (res) => {
    //                 if (res)
    //                     this.listOrderStatusHist = res.data;

    //             },
    //             error: (err) => {
    //                 console.log(`WoOrderDetailComponent - getStatusHist : ${err}.`);
    //             }
    //         });
    // }


    getStatusNext() {
        this.workFlowService.getTaskStatusList(this.item.woWorkFlowId, this.item.woOrderStatusId, this.language).subscribe(
            {
                next: (res) => {
                    if (res) {
                        if (res.data != null) {
                            this.listWorkflowTask = res.data.items;
                            this.showBtn = this.listWorkflowTask.length > 0;
                        }
                        else
                            this.showBtn = false;
                    }

                },
                error: (err) => {
                    console.log(`WoOrderDetailComponent - getServiceStatusTimeline : ${err}.`);
                }
            });
    }

    updStatus(idStatus: string) {


        if (idStatus === this.paymentId && !this.canUpdatePaymentStatus) {
            this.idPaymentStatus = idStatus;
            this.showFormPaymentModal(true);
        } else {
            this.orderService
                .updStatus(this.item.id, idStatus, "")
                .subscribe(
                    {
                        next: () => {
                            this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                            this.getItem();

                        },
                        error: (err) => {
                            console.log(`WorkFlowDetail - saveItem - Upd : ${err}.`)
                            this.message = this.translateService.instant('api_response.' + err.error);

                            if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                                this.message = this.translateService.instant('api_response.ERROR');

                            this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                        }
                    });
        }
        this.canUpdatePaymentStatus = false;
    }

    showFormPaymentModal(value: boolean) {
        this.formPaymentModal = value;
    }

    newService(type: string) {
        this.orderType = type;
    }



    closeCancel() {

        // this.confirmationService.confirm({
        //     message: this.translateService.instant('message.confirm_cancel'),
        //     header: this.translateService.instant('title.confirmation'),
        //     acceptLabel: this.translateService.instant('button.yes'),
        //     rejectLabel: this.translateService.instant('button.no'),
        //     acceptButtonStyleClass: 'p-button-success',
        //     rejectButtonStyleClass: 'p-button-danger',
        //     icon: 'pi pi-exclamation-triangle',
        //     accept: () => {
                this.router.navigate(['order-service-cartorio/ded01c2b-5021-4716-9c14-f38701f48271']);
        //     }
        // });
    }

    saveObs(value: string) {
        this.orderService
            .updItem(this.item)
            .subscribe(
                {
                    next: () => {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                    },
                    error: (err) => {
                        console.log(`WorkFlowDetail - saveObs - Upd : ${err}.`)
                        this.message = this.translateService.instant('api_response.' + err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                    }
                });

    }

    getClient() {

    }

    reciverFeedbackPayment(event)
    {
        this.canUpdatePaymentStatus = event;
        if (this.canUpdatePaymentStatus){
            this.updStatus(this.idPaymentStatus);
        }
        this.showFormPaymentModal(false);
    }

    reciverFeedbackItem(value: SfcWoOrderOrderServiceValue) {
        this.totalValue = value;
    }

    // reciverFeedbackService() {
    //     this.orderCartorioService.reciverFeedbackValueChanged.subscribe((data: any) => {
    //         this.totalValue = data;
    //       });
    // }


    reciverFeedbackPerson(value: DropDown) {
        this.item.scClientId = value.id;
        this.item.clientDoc = value.name.split(' - ')[0];
        this.item.clientName = value.name.split(' - ')[1];
        //alert(JSON.stringify(value));
    }

}

