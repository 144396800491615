import { WoOrderStatusHist } from './../../wo-order/wo-order.model';

import { WoOrderService } from './../../wo-order/wo-order.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService } from "primeng/api";
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { WoWorkFlowOutput, WoWorkFlowTask, WoWorkFlowTaskOutput} from '../wo-workflow.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { WoWorkFlowService } from '../wo-workflow.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { ScRoleService } from '../../sc-role/sc-role.service';
import { Table } from 'primeng/table';
import { WoOrderStatusService } from 'src/app/core/services/wo-order-status/wo-order-status.service';






@Component({
    selector: 'wo-workflow-detail',
    templateUrl: './wo-workflow-detail.component.html',
    styleUrls: ['./wo-workflow-detail.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class WoWorkFlowDetailComponent implements OnInit {

    public workFlowType: string = "service";
    public labelWorkFlowType: string = "";


    public userId: string = "";
    public id: string = "";
    public language: string = "pt-BR";
    public item: WoWorkFlowOutput = {};
    public itemBase: string = "";

    public canUpdate: boolean = true;
    public isSaved: boolean = false;
    public displayDialog: boolean = false;


    public message: string = "";
    public pageMessage: string = "";
    public errorMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-danger p-m-1 mr-1";

    public listWorkFlowTask: WoWorkFlowTaskOutput[] = [];
    public itemTask: WoWorkFlowTaskOutput;
    public listWorkFlowTaskBase: string = "";
    public listStatusFrom: DropDown[] = [];
    public listStatusNext: DropDown[] = [];
    public dialogHeader: string = "";
    public listRole: DropDown[] = [];
    public selectedRoles: DropDown[] = [];


    constructor(private userTokenService: UserTokenService,
                private workflowService: WoWorkFlowService,
                private scRoleService: ScRoleService,
                private orderStatusService: WoOrderStatusService,
                private confirmationService: ConfirmationService,
                private activateRoute: ActivatedRoute,
                private notificationsService: NotificationsService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private router: Router) { }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();
        this.canUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'WO_WORKFLOW_U']);

        this.getOrderStatusDropDown();
        this.getRoleDropDown();

        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('id')) {
                this.id = params.get('id');
                if (this.id != "new"){
                    this.getItem();
                }
                else{
                    this.item.isActive = true;
                }
            }

            if (params.has('type')) {
                this.workFlowType = params.get('type');
            }
        });

        if (this.canUpdate == false)
        {
            this.classBtnDel = this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
        }


    }

    translate(){

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        this.translateConfigService.changeLanguage(this.language);
        (async () => {
            await sleep(100);
            this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
            this.labelWorkFlowType = " "+this.translateService.instant('label.workflow'+this.workFlowType)+" ";
            this.dialogHeader = this.translateService.instant('title.wo_workflow_taks_edit');
            this.breadcrumbService.setItems([
                {label: this.translateService.instant('label.workflow')+" "},
                {label: this.labelWorkFlowType, routerLink: ['workflow/'+this.workFlowType]},
                {label: " "+this.translateService.instant('label.workflowDetail'), routerLink: ['workflow/'+this.workFlowType+'/'+this.id]}
            ]);
        })();
    }

    clear(table: Table) {
        table.clear();
    }

    getItem() {
        this.workflowService.getItem(this.id).subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.item = res.data;
                this.itemBase = JSON.stringify(this.item);
                this.isSaved = true;
                this.translate();
            }
            this.getWorkFlowTaskList();

        },
        error: (err) => console.log(`WorkFlowDetail - getItem : ${err}.`)
        });
    }

    getWorkFlowTaskList()
    {
        this.workflowService.getTaskList(this.id).subscribe(
            {
            next: (res) => {
                if (res){
                    this.listWorkFlowTask = res.data;
                    console.log(this.listWorkFlowTask);
                    this.listWorkFlowTaskBase =JSON.stringify(this.listWorkFlowTask);
                }
            },
            error: (err) => {
                console.log(`WoWorkFlowDetail - getWorkFlowTaskList : ${err}.`);
            }
        });
    }

    getOrderStatusDropDown() {
        this.orderStatusService.getDropDownList().subscribe(
        {
        next: (res) => {
            if (res)
            {
                this.listStatusFrom = res.data;
                this.listStatusNext = res.data;
            }
        },
        error: (err) => console.log(`WorkFlowDetail - getOrderStatus : ${err}.`)
        });
    }

    getRoleDropDown() {
        this.scRoleService.getDropDownList(this.language).subscribe(
            {
              next: (res) => {
                  if (res)
                      this.listRole = res.data;

              },
              error: (err) => {
                  console.log(`RoleListMulti - getList : ${err}.`);
              }
          });
    }




    saveItem() {
        if (this.item.id) {
            this.workflowService
            .updItem(this.item, this.userId)
            .subscribe(
                {
                    next: () => {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                      //  this.isSaved = true;
                        this.itemBase = JSON.stringify(this.item);
                    },
                    error: (err) => {
                        console.log(`WorkFlowDetail - saveItem - Upd : ${err}.`)
                        this.message = this.translateService.instant('api_response.'+err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                    }
                });
        }
        else {
            this.workflowService
            .addItem(this.item, this.userId)
            .subscribe({
                next: (res) => {
                    this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                    if (res != null && res !== undefined) {
                        this.item = res;
                        this.itemBase = JSON.stringify(this.item);
                    }
                },
                error: (err) => {
                    console.log(`WorkFlowDetail - saveItem - Add : ${err}.`)
                    this.message = this.translateService.instant('api_response.'+err.error);

                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                }
            });
        }
    }

    hideDialog(){
        this.displayDialog = false;
    }

    setWorkFlowTask()
    {
        //this.workFlowTaskForm.o = "";
    }

    checkSaved()
    {
        // this.isSaved = false;
        // if (JSON.stringify(this.item) === this.itemBase && JSON.stringify(this.listWorkFlowTask) === this.listWorkFlowTaskBase)
        // {
        //     this.isSaved = true;
        // }
    }

    cancel()
    {
        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_cancel'),
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.router.navigate(['workflow/'+this.workFlowType]);
            }
        });
    }


    addTask() {
        this.itemTask = {};
        this.displayDialog = true;
    }

    updTask(itemTask: WoWorkFlowTask) {
        this.itemTask = {...itemTask};

        if (this.itemTask.roles == undefined || this.itemTask.roles == null || this.itemTask.roles.length == 0)
            this.selectedRoles = [];
        else
            this.selectedRoles = this.itemTask.roles;

        this.displayDialog = true;
    }

    delTask(itemTask: WoWorkFlowTask) {

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + itemTask.code + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.workflowService
                .delTaskItem(this.item.id, itemTask.id)
                .subscribe(
                {
                    next: () =>
                    {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_deleted'));
                        this.getWorkFlowTaskList();
                    },
                    error: (err) => {
                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.error'), this.translateService.instant('api_response.ERROR'));
                        console.log(`ScRoleGrid - delItem : ${err}.`);
                    }
                });
            }
      });
    }

    saveTask() {

        this.itemTask.woWorkFlowId = this.id;
        if (this.itemTask.id) {
            this.workflowService
            .updTaskItem(this.itemTask)
            .subscribe(
                {
                    next: () => {
                        this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                      //  this.isSaved = true;
                       // this.itemBase = JSON.stringify(this.item);
                       this.getWorkFlowTaskList();
                       this.displayDialog = false;
                    },
                    error: (err) => {
                        console.log(`WorkFlowDetail - saveItem - Upd : ${err}.`)
                        this.message = this.translateService.instant('api_response.'+err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                    }
                });
        }
        else {
            this.workflowService
            .addTaskItem(this.itemTask)
            .subscribe({
                next: (res) => {
                    this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                    if (res != null && res !== undefined) {
                        //this.item = res;
                        this.getWorkFlowTaskList();
                        this.displayDialog = false;
                        //this.itemBase = JSON.stringify(this.item);
                    }
                },
                error: (err) => {
                    console.log(`WorkFlowDetail - saveItem - Add : ${err}.`)
                    this.message = this.translateService.instant('api_response.'+err.error);

                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                }
            });
        }
    }

    reciverFeedbackStatusFrom(event)
    {
        if (event != null && event !== undefined) {
            this.itemTask.woOrderStatusFromId = event;
        }

    }


    reciverFeedbackStatusNext(event) {
        if (event != null && event !== undefined) {
            this.itemTask.woOrderStatusNextId = event;
        }
    }

    reciverFeedbackRole(select: DropDown[])
    {
        this.selectedRoles = select;
      //  this.itemTask.roles = this.selectedRoles;
    }

}
