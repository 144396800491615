import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DropDownMeta, GenericItem, GenericListMeta } from 'src/app/shared/model/base-model';
import { ScPersonDocumentType, ScPersonDocumentTypeOutput } from 'src/app/features/sc-person/pages/sc-person-document-type/sc-person-document-type.model';
import { ScAddressScPerson, ScPerson, ScPersonFileList, ScPersonItem, ScPersonList, ScPersonOutput, UpdateScPersonClientInput } from 'src/app/models/response/sc-person.model';

@Injectable({
    providedIn: 'any'
})

export class ScPersonService {

    private environmentUrl: string = '';

    scPersonSearchDialogEmitter = new EventEmitter();
    scPersonOutputEmitter = new EventEmitter();
    scPersonBasicDisplayDialogEmitter = new EventEmitter();
    scPersonBasicConfirmEmitter = new EventEmitter();

    public dataChanged = new EventEmitter<any>();
    public actionChanged = new EventEmitter<any>();




    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore + '/Person/';
    }

    sendData(data: any) {
        this.dataChanged.emit(data)
    }

    sendAction(action: string) {
        console.log(action);
        this.actionChanged.emit(action)
    }

    getListPerson(language: string, cpf: string, name: string, flagTypePerson?: string): Observable<ScPersonList> {
        let url = this.environmentUrl + "?language=" + language + "&flagTypePerson=" + flagTypePerson;

        let headers = new HttpHeaders()
            .set('cpf', cpf)
            .set('name', name);

        return this.httpClient.get<ScPersonList>(url, { headers: headers });
    }

    getById(id: string): Observable<ScPersonItem> {
        let url = this.environmentUrl + id;
         return this.httpClient.get<ScPersonItem>(url);
    }

    getPersonById(language: string, id: string): Observable<ScPersonOutput> {
        let url = this.environmentUrl + id + "?lang=" + language;
         return this.httpClient.get<ScPersonOutput>(url);
    }

    getDropDownListPerson(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

    addItemPerson(model: any): Observable<ScPersonOutput> {
        return this.httpClient.post<ScPerson>(this.environmentUrl, model);
    }

    updItemPerson(model: ScPersonOutput): Observable<ScPersonOutput> {
        let url = this.environmentUrl + model.id;
        let input = this.scpersonAdpatOutputInput(model);

        return this.httpClient.put<ScPersonOutput>(url, input);
    }

    updItemPersonClient(model: ScPersonOutput): Observable<ScPersonOutput> {
        let url = this.environmentUrl+"Client/" + model.id;
        let input = this.scpersonAdpatOutputInput(model);
        console.log(model);
        console.log(input);
        console.log(url);
        return this.httpClient.put<ScPersonOutput>(url, input);
    }
    updItemPersonalData(model: ScPerson): Observable<ScPersonOutput> {
        let url = this.environmentUrl+"PersonalData/";
        //let input = this.scpersonAdpatOutputInput(model);
        return this.httpClient.patch<ScPersonOutput>(url, model);
    }

    delItemPerson(id: string) {
        return this.httpClient.delete(this.environmentUrl+id);
    }

    uploadFile(files: File[], scPersonId: string, scArchiveTypeId: string, woOrderOrderServiceId: string){
        if (files != null && files.length > 0)
        {
            const formData = new FormData();
            files.forEach((file: File) => {
                formData.append('file', file, file.name);
            });

            let url = this.environmentUrl+ "UploadFile/" + scPersonId + "/" + scArchiveTypeId + (woOrderOrderServiceId == null ? "" : "?woOrderOrderServiceId=" + woOrderOrderServiceId);
            return this.httpClient.post(url, formData);
        }
    }

    getListFile(scPersonId: string, woOrderOrderServiceId?: string) : Observable<ScPersonFileList> {
        let url = this.environmentUrl+ "File/" + scPersonId + (woOrderOrderServiceId == null ? "" : "?woOrderOrderServiceId=" + woOrderOrderServiceId);
        return this.httpClient.get<ScPersonFileList>(url);
    }

    deleteFile(id: string, scCompanyId: string, scPersonId: string){
        let url = this.environmentUrl+ "File/" + id + "/" + scCompanyId + "/" + scPersonId;
        return this.httpClient.delete(url);
    }

    addItemAddress(model: ScAddressScPerson): Observable<ScAddressScPerson> {
        return this.httpClient.post<ScAddressScPerson>(this.environmentUrl + 'Address', model);
    }

    getListAddress(scPersonId: string): Observable<any> {
        return this.httpClient.get<any>(this.environmentUrl + 'Address?scPersonId=' + scPersonId);
    }

    delItemAddress(scAddressId: string, scPersonId: string): Observable<any> {
        return this.httpClient.delete(this.environmentUrl + 'Address/' + scAddressId + '/' + scPersonId);
    }

    scpersonAdpatOutputInput(model: ScPersonOutput): UpdateScPersonClientInput {

        const person: UpdateScPersonClientInput = {} as UpdateScPersonClientInput;

        person.id = model.id;
        person.cpf = model.cpf;
        person.name = model.name;
        person.email = model.email;
        person.telephone = model.telephone;
        person.mobile = model.mobile;
        person.motherName = model.motherName;
        person.fatherName = model.fatherName;
        person.profession = model.profession;
        person.observation = model.observation;
        person.dateOfBirth = model.dateOfBirth;
        person.scCompanyId = model.scCompanyId;
        person.scUserId = model.scUserId;
        person.scPersonMaritalStatusId = model.scPersonMaritalStatusId;
        person.scPersonTypeNationalityId = model.scPersonTypeNationalityId;
        person.scPersonMatrimonialPropertyId = model.scPersonMatrimonialPropertyId;
        person.scPersonGenderId = model.scPersonGenderId;
        person.scPersonSpouseId = model.spouseId;
        person.scCountryId = model.scCountryId;
        person.scStateId = model.scStateId;
        person.scCityId = model.scCityId;
        person.addressId = model.addressId;
        person.scPersonSpouse = model.ScPersonSpouse;

        return person;

    }

    //=========================================================
    //================== DocumentType ==========================
    //=========================================================
    getDocumentTypeItem(id: string): Observable<ScPersonDocumentTypeOutput> {
        let url = this.environmentUrl + "DocumentType/" + id;
        return this.httpClient.get<ScPersonDocumentTypeOutput>(url);
    }

    getDocumentTypeList() : Observable<ScPersonDocumentTypeOutput[]>{
        let url = this.environmentUrl + "DocumentType/";
        return this.httpClient.get<ScPersonDocumentTypeOutput[]>(url);
    }

    addDocumentTypeItem(model: ScPersonDocumentType){
        let url = this.environmentUrl + "DocumentType/";
        return this.httpClient.post(url, model);
    }

    updDocumentTypeItem(model: ScPersonDocumentType){
        let url = this.environmentUrl + "DocumentType/" + model.id;
        return this.httpClient.put(url, model);
    }

    delDocumentTypeItem(id: string) {
        let url = this.environmentUrl + "DocumentType/" + id;
        return this.httpClient.delete(url);
    }

    getDocumentTypeDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DocumentType/DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

    //=========================================================
    //====================== Gender ===========================
    //=========================================================

    getItemGender(id: string): Observable<GenericItem> {
        let url = this.environmentUrl + 'Gender/' + id;
        return this.httpClient.get<GenericItem>(url);
    }

    getListGender(): Observable<GenericListMeta> {
        let url = this.environmentUrl + 'Gender';
        return this.httpClient.get<GenericListMeta>(url);
    }

    addItemGender(model: GenericItem) {
        return this.httpClient.post(this.environmentUrl + 'Gender', model);
    }

    updItemGender(id: string, model: GenericItem) {
        let url = this.environmentUrl + 'Gender/' + id;
        return this.httpClient.put(url, model);
    }

    delItemGender(id: string) {
        return this.httpClient.delete(this.environmentUrl + 'Gender/' + id);
    }

    getDropDownGender(): Observable<DropDownMeta> {
        let url = this.environmentUrl + 'Gender/DropDown/';
        return this.httpClient.get<DropDownMeta>(url);
    }

    //=========================================================
    //=================== MaritalStatus =======================
    //=========================================================
    getItemMaritalStatus(id: string): Observable<GenericItem> {
        let url = this.environmentUrl + 'MaritalStatus/' + id;
        return this.httpClient.get<GenericItem>(url);
    }

    getListMaritalStatus(): Observable<GenericListMeta> {
        let url = this.environmentUrl + 'MaritalStatus';
        return this.httpClient.get<GenericListMeta>(url);
    }

    addItemMaritalStatus(model: GenericItem) {
        return this.httpClient.post(this.environmentUrl + 'MaritalStatus', model);
    }

    updItemMaritalStatus(id: string, model: GenericItem) {
        let url = this.environmentUrl + 'MaritalStatus/' + id;
        return this.httpClient.put(url, model);
    }

    delItemMaritalStatus(id: string) {
        return this.httpClient.delete(this.environmentUrl + 'MaritalStatus/' + id);
    }

    getDropDownMaritalStatus(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "MaritalStatus/DropDown/";
        return this.httpClient.get<DropDownMeta>(url);
    }

    //=========================================================
    //================ MatrimonialProperty ====================
    //=========================================================
    getItemMatrimonialProperty(id: string): Observable<GenericItem> {
        let url = this.environmentUrl + 'MatrimonialProperty/' + id;
        return this.httpClient.get<GenericItem>(url);
    }

    getListMatrimonialProperty(): Observable<GenericListMeta> {
        let url = this.environmentUrl + 'MatrimonialProperty';
        return this.httpClient.get<GenericListMeta>(url);
    }

    addItemMatrimonialProperty(model: GenericItem) {
        return this.httpClient.post(this.environmentUrl + 'MatrimonialProperty', model);
    }

    updItemMatrimonialProperty(id: string, model: GenericItem) {
        let url = this.environmentUrl + 'MatrimonialProperty/' + id;
        return this.httpClient.put(url, model);
    }

    delItemMatrimonialProperty(id: string) {
        return this.httpClient.delete(this.environmentUrl + 'MatrimonialProperty/' + id);
    }

    getDropDownMatrimonialProperty(): Observable<DropDownMeta> {
        let url = this.environmentUrl + 'MatrimonialProperty/DropDown';
        return this.httpClient.get<DropDownMeta>(url);
    }

    //=========================================================
    //================== TypeNationality ======================
    //=========================================================
    getItemTypeNationality(id: string): Observable<GenericItem> {
        let url = this.environmentUrl + 'TypeNationality/' + id;
        return this.httpClient.get<GenericItem>(url);
    }

    getListTypeNationality(): Observable<GenericListMeta> {
        let url = this.environmentUrl + 'TypeNationality';
        return this.httpClient.get<GenericListMeta>(url);
    }

    addItemTypeNationality(model: GenericItem) {
        return this.httpClient.post(this.environmentUrl + 'TypeNationality', model);
    }

    updItemTypeNationality(id: string, model: GenericItem) {
        let url = this.environmentUrl + 'TypeNationality/' + id;
        return this.httpClient.put(url, model);
    }

    delItemTypeNationality(id: string) {
        return this.httpClient.delete(this.environmentUrl + 'TypeNationality/' + id);
    }

    getDropDownTypeNationality(): Observable<DropDownMeta> {
        let url = this.environmentUrl + 'TypeNationality/DropDown/';
        return this.httpClient.get<DropDownMeta>(url);
    }
}
