import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { ScArchiveTypeService } from 'src/app/core/services/sc-archive-type/sc-archive-type.service';
import { DropDown } from 'src/app/shared/model/base-model';

@Component({
    selector: 'sf-sc-archive-type-list-multi',
    templateUrl: './sc-archive-type-list-multi.component.html',
    styleUrls: ['./sc-archive-type-list-multi.component.scss']
})

export class ScArchiveTypeListMultiComponent implements OnInit{

    public list : DropDown[];
    public item: DropDown;
    public selectedList: DropDown[];

    @Input() placeHolderLanguage: string;
    @Input() selectedItems: DropDown[] = [];
    @Input() language: string = window.localStorage.getItem("_LANG");
    @Output() chosedItem = new EventEmitter();

    constructor(  private scArchiveTypeService: ScArchiveTypeService){ }

    ngOnInit(): void {
        this.getList();
    }

    ngOnChanges(): void {
        this.selectedList = this.selectedItems;
    }

    getList() {
        this.scArchiveTypeService.getDropDownList().subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
                this.selectedList = this.selectedItems;

            },
            error: (err) => {
                console.log(`ArchiveTypeListMulti - getList : ${err}.`);
            }
        });
      }

    feedback(event){
        this.chosedItem.emit(this.selectedList);
    }
}
