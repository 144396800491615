<sf-loader></sf-loader>
<sf-notification></sf-notification>
<div class="p-grid">
    <div class="p-col-12" style="margin-top: -22px;">
        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="15"
                 [showCurrentPageReport]="true"
                 [currentPageReportTemplate]="pageMessage"
                 [rowsPerPageOptions]="[15,50,100]"
                 [globalFilterFields]="['code','name']"
                 styleClass="p-datatable-responsive-demo p-datatable-striped"  [showCurrentPageReport]="true">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="btnNewLabel" class="p-button-success p-mr-2" style="width: 180px;"
                            (click)="addItem()" *ngIf="flUpdate"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"  [placeholder]="'search.filter' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2" icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 105px; text-align: center;"><span translate>label.actions</span></th>
                    <th pSortableColumn="code"><span translate>label.code</span> <p-sortIcon field="code"></p-sortIcon></th>
                    <th pSortableColumn="name"><span translate>label.name</span><p-sortIcon field="name"></p-sortIcon></th>
                    <th><span translate>label.icon</span></th>
                    <th style="width: 50px;"><span translate>label.color</span></th>
                    <th><span translate>label.type</span></th>
                    <th><span translate>label.active</span></th>
                    <th><span translate>label.isDocument</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>
                        <p-button icon="pi pi-pencil" [styleClass]="classBtnUpd" [pTooltip]="'button.edit' | translate" (click)="updItem(item)"  [disabled]="!flUpdate"></p-button>
                        <p-button icon="pi pi-times" [styleClass]="classBtnDel" [pTooltip]="'button.delete' | translate"   (click)="delItem(item)" [disabled]="!flUpdate"></p-button>
                    </td>
                    <td>{{item.code}}</td>
                    <td>{{item.name}}</td>
                    <td><i class={{item.icon}}></i> &nbsp; {{item.icon}}</td>
                    <td><span [class]="'status-badge'" [style]="{'background-color':''+item.color+'', 'color': '#FFFFFF'}">{{item.color}}</span> </td>
                    <td>{{item.type}}</td>
                    <td><input type="checkbox" readonly [(ngModel)]="item.isActive" onclick="return false;"/></td>
                    <td><input type="checkbox" readonly [(ngModel)]="item.isDocument" onclick="return false;"/></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [(visible)]="displayDialog" [style]="{width: '500px'}" [modal]="true" styleClass="p-fluid" [header]="dialogHeader">
    <ng-template pTemplate="content">
        <div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
            <div class="p-md-12  p-lg-6 p-xl-6 p-order-1 p-order-lg-1">

                <div class="p-field">
                    <label for="code"><span translate>label.code</span></label>
                    <input type="text" pInputText id="code" [(ngModel)]="item.code" required />
                    <small class="p-invalid" *ngIf="getFiledValidation(!item.code) && submitted && !item.code"><span translate>label.field_code_required</span></small>
                </div>

                <div class="p-field">
                    <label for="name"><span translate>label.name</span></label>
                    <input type="text" pInputText id="name" [(ngModel)]="item.name" required />
                    <small class="p-invalid" *ngIf="getFiledValidation(!item.name) && submitted && !item.name"><span translate>label.field_name_required</span></small>
                </div>

                <div class="p-field">
                    <label><span translate>label.icon</span></label>
                    <sf-dropdown-icon [icon]="item.icon" (chosedIcon)="reciverFeedbackIcon($event)" [placeHolderLanguage]="'label.select_icon' | translate"> </sf-dropdown-icon>
                </div>

                <div class="p-field">
                    <label for="color"><span translate>label.color</span></label>
                    <input type="text" pInputText id="color" [(ngModel)]="item.color"/>
                    <div class="block mt-2">
                        <sf-dropdown-color [color]="item.color" (chosedColor)="reciverFeedbackColor($event)"></sf-dropdown-color>
                    </div>
                </div>

                <div class="p-field">
                    <input type="checkbox"  id="isActive" [(ngModel)]="item.isActive">
                    <label for="isActive"><span translate>label.active</span></label>
                </div>

                <div class="p-field">
                    <input type="checkbox"  id="isDocument" [(ngModel)]="item.isDocument">
                    <label for="isDocument"><span translate>label.isDocument</span></label>
                </div>

            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div style="float: right;">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate" (click)="saveItem()" [disabled]="fieldValidation"></button>
        </div>
    </ng-template>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>