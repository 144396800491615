import { Injectable } from '@angular/core';





@Injectable({providedIn:'root'})
export class StringFunctionsService{

    getFirstWord(inputString: string): string {
        if (typeof inputString !== 'string' || !inputString) {
            return '';
          }

          const words = inputString.trim().split(' ');
        return words[0];
      }

}
