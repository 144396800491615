import { OnInit, Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { WoOrderStatusService } from 'src/app/core/services/wo-order-status/wo-order-status.service';
import { WoOrderStatusHist } from 'src/app/models/response/wo-order-status.model';



@Component({
    selector: 'sf-wo-order-status-timeline',
    templateUrl: './wo-order-status-timeline.component.html',
    styleUrls: ['./wo-order-status-timeline.component.scss']
})

export class WoOrderStatusTimelineComponent implements OnInit, OnChanges{

    public list : WoOrderStatusHist[];
    @Input() woOrderId: string;
    @Input() languageCode: string;


    constructor(  private woOrderStatusService: WoOrderStatusService){ }

    ngOnInit(): void {
        this.getList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getList();
    }

    getList() {
        this.woOrderStatusService.getStatusHist(this.woOrderId, this.languageCode).subscribe(
          {
            next: (res) => {
                if (res)
                    this.list = res.data;
            },
            error: (err) => {
                console.log(`WoOrderStatusTimeline - getList : ${err}.`);
            }
        });
      }

    getStatusDetail(item: WoOrderStatusHist)
    {

    }
}
