import { Component, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDown } from 'src/app/shared/model/base-model';
import { ScAddressService } from 'src/app/core/services/sc-address/sc-address.service';
import { Address } from 'src/app/models/response/sc-address.response';

@Component({
  selector: 'sf-address-detail',
  templateUrl: './sc-address-detail.component.html',
  styleUrls: ['./sc-address-detail.component.scss']
})
export class ScAddressDetailComponent {

  @Input() public addressId: string = "";
  public item: Address;
  public cepWasSubmitted: boolean = false;
  @Output() newAddressEmitter = new EventEmitter<string>();
  @Output() formattedAddressEmitter = new EventEmitter<string>();
  public addressForm: FormGroup;
  public zipCodeMask: string = "";
  public UfList: DropDown[] = [];
  public CitiesList: DropDown[] = [];

  constructor(  private addressService: ScAddressService,
                private translateService: TranslateService,
                private formBuilder: FormBuilder
            ) {}



  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      postalCode: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      neighborhood: ['', Validators.required],
      streetNumber: ['', Validators.required],
      streetComplement: [''],
      route: ['', Validators.required],
      sublocality: [''],
      adimistrativeAreaLevel2: [''],
      adimistrativeAreaLevel1: [''],
      country: [''],
      formattedAddress: [''],
      lat: [''],
      lng: ['']
    });

    this.getAllUFs()

    if (this.addressId != null && this.addressId != "") {
      console.log(this.addressId)
      this.addressService.getAddress(this.addressId).then((res) => {
        this.consultaUF(res.state)
        this.item = res
        this.formattedAddressEmitter.emit(res.formattedAddress)
        this.setForm()
      })
    }
    const language = this.translateService.currentLang
    console.log(language)
    if (language == "pt-BR") {
      this.zipCodeMask = "99999-999"
    }
    else if (language == "en-US") {
      this.zipCodeMask = "99999"
    }
  }


  private setForm() {
    this.addressForm.setValue({
      ...this.addressForm.value,
      state: this.item.state,
      city: this.item.city.toLocaleUpperCase(),
      neighborhood: this.item.neighborhood,
      route: this.item.route,
      streetNumber: this.item.streetNumber,
      streetComplement: this.item.streetComplement
    })
  }

  reciverFeedbackUf(id: string) {
    if (id == null) {
      this.item = { ...this.item, state: null, city: null }
      this.addressForm.setValue({ ...this.addressForm.value, state: null })
    } else {
      this.item = { ...this.item, state: id, city: null }
      this.addressForm.setValue({ ...this.addressForm.value, state: id, city: null })
      this.consultaUF(id)
    }
  }
  reciverFeedbackCity(id: string) {
    if (id == null) {
      this.item = { ...this.item, city: null }
      this.addressForm.setValue({ ...this.addressForm.value, city: null })
    }
    else {
      this.item = { ...this.item, city: id }
      this.addressForm.setValue({ ...this.addressForm.value, city: id })
    }
    this.item = { ...this.item, city: id }
  }

  public changeZipCode(event: string) {
    if (event != this.item.postalCode) {
      this.cepWasSubmitted = false;
    }
    this.item = { ...this.item, postalCode: event }

  }

  async consultaCEP(cep) {
    // Nova variável "cep" somente com dígitos.
    console.log(cep)
    cep = cep.replace(/\D/g, '');

    if (cep != null && cep !== '') {
      try {
        const value = await this.addressService.findCEP(cep)
        console.log(value)
        this.consultaUF(value.state)
        this.item = {
          ...this.item, postalCode: value.postalCode, state: value.state, city: value.city.toLocaleUpperCase(), neighborhood: value.neighborhood, reference: value.reference, streetNumber: value.streetNumber, route: value.route, sublocality: value.sublocality, adimistrativeAreaLevel2: value.adimistrativeAreaLevel2, adimistrativeAreaLevel1: value.adimistrativeAreaLevel1, country: value.country, formattedAddress: value.formattedAddress, lat: value.lat, lng: value.lng
        }

        console.log(this.item)
        this.addressForm.setValue({
          ...this.addressForm.value,
          state: value.state,
          city: value.city.toLocaleUpperCase(),
          neighborhood: value.neighborhood,
          route: value.route,
          formattedAddress: value.formattedAddress,
        })

        console.log(this.addressForm.value)
        this.cepWasSubmitted = true;
        if (value.city == null) {
          this.item = {}
          this.CitiesList = []
          this.cepWasSubmitted = false;
        }
      } catch (error) {
        this.item = {}
        this.CitiesList = []
        this.cepWasSubmitted = false;
      }


    }
  }

  getAllUFs() {
    this.addressService.getAllUF().subscribe(
      {
        next: (res) => {
          if (res)
            this.UfList = res.sort((a, b) => a.nome.localeCompare(b.nome)).map(uf => <DropDown>{
              id: uf.sigla,
              name: uf.nome,
            })
        },
        error: (err) => {
          console.log(`ScCompanyDetailComponent - getCompanyTypeList : ${err}.`);
        }
      });
  }

  consultaUF(uf: string) {
    this.addressService.getAllCitiesByUF(uf).subscribe(
      {
        next: (res) => {
          if (res)
            this.CitiesList = res.map(city => <DropDown>{
              id: city.nome,
              name: city.nome,
            })
        },
        error: (err) => {
          console.log(`ScCompanyDetailComponent - getCompanyTypeList : ${err}.`);
        }
      });

  }



  save() {

    const address : Address = {
      ...this.item,
      postalCode: this.addressForm.value.postalCode,
      state: this.addressForm.value.state,
      city: this.addressForm.value.city,
      neighborhood: this.addressForm.value.neighborhood,
      streetNumber: this.addressForm.value.streetNumber,
      streetComplement: this.addressForm.value.streetComplement,
      route: this.addressForm.value.route,
      sublocality: this.addressForm.value.sublocality,
      adimistrativeAreaLevel2: this.addressForm.value.adimistrativeAreaLevel2,
      adimistrativeAreaLevel1: this.addressForm.value.adimistrativeAreaLevel1,
      country: this.addressForm.value.country,
      lat: this.addressForm.value.lat,
      lng: this.addressForm.value.lng
    }



    this.addressService.saveAddress(address).then((res) => {
      this.newAddressEmitter.emit(res.data.id)
      this.formattedAddressEmitter.emit(res.data.formattedAddress)
    })

  }

  isFieldValid(field: string) {
    return !this.addressForm.get(field).valid && this.addressForm.get(field).touched;
  }

}
