import { Table } from 'primeng/table';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { WoOrderInput } from 'src/app/pages/wo-order/wo-order.model';
import { WoOrderService } from 'src/app/pages/wo-order/wo-order.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { WoOrderTypeService } from 'src/app/pages/wo-order-type/wo-order-type.service';
import { WoOrderTypeOutput } from 'src/app/pages/wo-order-type/wo-order-type.model';
import { Subscription, interval } from 'rxjs';


@Component({
    selector: 'wo-order-cartorio-new',
    templateUrl: './wo-order-cartorio-new.component.html',
    styleUrls: ['./wo-order-cartorio-new.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})
export class WoOrderCartorioNewComponent implements OnInit {

    public userId: string;
    public language: string = "pt-BR";
    public list: WoOrderTypeOutput[] = [];
    public item: any;
    public itemInput: WoOrderInput = {};
    public flUpdate: boolean = true;
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";

    public woOrderClassId: string = '';
    public isOnlyTicket: boolean = false;
    public isTotem: boolean = false;
    public showDialog: boolean = false;
    public progressValue: number = 0;
    public  subscription: Subscription;


    constructor(private orderService: WoOrderService,
                private orderTypeService: WoOrderTypeService,
                private router: Router,
                private notificationsService: NotificationsService,
                private activateRoute: ActivatedRoute,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private userTokenService: UserTokenService) { }

    ngOnInit(): void {


        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('orderClassId')) {
                this.woOrderClassId = params.get('orderClassId');
            }
            if (params.has('isOnlyTicket')) {
                if (params.get('isOnlyTicket') == "false")
                    this.isOnlyTicket = false;
            }
            else{
                this.isTotem = true;
            }

        });



        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();

        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
        }

        this.getList();

        this.translate();

    }

     translate(){
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        (async () => {
            await sleep(100);

            const labOrder = this.translateService.instant('label.order')+" ";
            if (this.isOnlyTicket)
                this.breadcrumbService.setItems([{label: 'Senha de Atendimento', routerLink: ['order-service-cartorio-new', this.woOrderClassId]} ]);
            else
                this.breadcrumbService.setItems([{label: labOrder},{label: 'Atendimento', routerLink: ['order-service-cartorio']},{label: 'Senha de Atendimento', routerLink: ['order-service-cartorio-new', this.woOrderClassId, this.isOnlyTicket]} ]);
        })();
    }

    newOrder(item: WoOrderTypeOutput){

        this.itemInput.ticket = item.code;
        this.itemInput.woOrderTypeId = item.id;
        this.itemInput.name = item.name;


        this.orderService.addItemTicket(this.itemInput).subscribe(
        {
            next: (res) => {
                if (res){
                    this.item = res;
                    if (this.isTotem){

                        this.showDialog = true;

                        this.subscription = interval(1000).subscribe(() => {
                            this.progressValue = (this.progressValue + 10) % 101; // Keep progress between 0 and 100
                          });

                        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

                        (async () => {
                            await sleep(10000);
                            this.showDialog = false;
                            this.subscription.unsubscribe();

                        })();

                    }
                    else{
                        this.router.navigate(['order-service-cartorio-detail/', this.item.data.id]);
                    }
                }
            },
            error: (err) => {
                this.notificationsService.toastNotify('warn', this.translateService.instant('title.WoOrderType'), this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderCartorioNew - getTypeList : ${err}.`);
            }
        });
    }




    getList() {
        this.orderTypeService.getListByUser(this.woOrderClassId).subscribe(
        {
            next: (res) => {
                if (res)
                    this.list = res.data;
                console.log(this.list);
            },
            error: (err) => {
                this.notificationsService.toastNotify('warn', this.translateService.instant('title.WoOrderType'), this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderCartorioNew - getTypeList : ${err}.`);
            }
        });
    }



  close() {
    this.router.navigate(['/']);
  }

  closeDialog(){
    this.subscription.unsubscribe();
    this.showDialog = false;
  }

}


