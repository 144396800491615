import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';


import { TableModule } from 'primeng/table';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TimelineModule } from 'primeng/timeline';
import { GalleriaModule } from 'primeng/galleria';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthorizationGuard } from 'src/app/core/guards/authorization.guard';

import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';


import { WoOrderCartorioGridComponent } from './wo-order-cartorio-grid/wo-order-cartorio-grid.component';
import { WoOrderCartorioDetailComponent } from './wo-order-cartorio-detail/wo-order-cartorio-detail.component';
import { WoOrderService } from 'src/app/pages/wo-order/wo-order.service';
import { WoOrderCartorioService } from './wo-order-cartorio.service';
import { SfcServiceGridModule } from '../component/sfc-service-grid/sfc-service-grid.module';
import { WoOrderTypeService } from 'src/app/pages/wo-order-type/wo-order-type.service';
import { WoOrderCartorioNewComponent } from './wo-order-cartorio-new/wo-order-cartorio-new.component';

import { WoOrderModule } from 'src/app/pages/wo-order/wo-order.module';
import { WoOrderPaymentModule } from 'src/app/pages/wo-order/wo-order/wo-order-payment/wo-order-payment.module';
import { BadgeModule } from 'primeng/badge';
import { SCPersonModule } from 'src/app/features/sc-person/sc-person.module';
import { WoOrderStatusModule } from 'src/app/features/wo-order-status/wo-order-status.module';
import { WoOrderCartorioDetailNewComponent } from './wo-order-cartorio-detail-new/wo-order-cartorio-detail-new.component';
import { SharedModule } from 'src/app/shared/shared.module';

registerLocaleData(localePT, 'pt', localeExtraPT);

@NgModule({
    declarations: [WoOrderCartorioGridComponent, WoOrderCartorioDetailComponent, WoOrderCartorioNewComponent, WoOrderCartorioDetailNewComponent],
    exports: [WoOrderCartorioGridComponent, WoOrderCartorioDetailComponent, WoOrderCartorioNewComponent, WoOrderCartorioDetailNewComponent],
    imports: [FormsModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        InputTextModule,
        TranslateModule,
        MessageModule,
        MessagesModule,
        TableModule,
        TooltipModule,
        ConfirmDialogModule,
        LoaderModule,
        TabViewModule,
        CardModule,
        DialogModule,
        NotificationsModule,
        DropdownModule,
        InputTextModule,
        RatingModule,
        ScrollPanelModule,
        TimelineModule,
        GalleriaModule,
        SfcServiceGridModule,
        ProgressBarModule ,
        ToastModule,
        WoOrderStatusModule,
        SCPersonModule,
        WoOrderModule,
        WoOrderPaymentModule,
        BadgeModule,
        SharedModule
        ],
    providers: [NotificationsService, AuthService, AuthorizationGuard, WoOrderService, WoOrderTypeService, WoOrderCartorioService, {provide: LOCALE_ID, useValue: 'pt'}]
})
export class WoOrderCartorioModule { }


