<div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
            <div class="p-field m-2">
                <label for="code"><span translate>label.code</span></label>
                <input type="text" pInputText id="code" [(ngModel)]="item.code" required [disabled]="true"
                    tabindex="1" />
                <small class="p-invalid" *ngIf="submitted && !item.code"><span
                        translate>label.field_code_required</span></small>
            </div>
        </div>        
    
        <div class="field col-12 sm:col-12 md:col-8 lg:col-8 xl:col-8">
            <label for="name"><span translate>label.name</span></label>
            <input type="text" pInputText id="name" [(ngModel)]="item.name" required [disabled]="true"
                tabindex="2" />
            <small class="p-invalid" *ngIf="submitted && !item.name"><span
                    translate>label.field_name_required</span></small>
        </div>
        
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">

            <label for="description"><span translate>label.description</span></label>
            <textarea rows="3" cols="30" pInputTextarea id="description" [(ngModel)]="item.description"
                [disabled]="true" tabindex="1"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
        </div>        
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="quantity"><span translate>label.quantity</span></label> 
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"></span>
                <p-inputNumber type="text" pInputText id="quantity" [(ngModel)]="item.quantity"
                    [disabled]="!canUpdate" [minFractionDigits]="0" tabindex="4" />
            </div>
        </div>
        <div class="field col-12 sm:col-12 md:col-3 lg:col-3 xl:col-3 text-center" style="font-size: 18px;">
            <label><span>Valor Unitário</span></label>
            <div>                
                {{((item.unitValueA ?? 0) + (item.unitValueB ?? 0) + (item.unitValueTaxA ?? 0) + (item.unitValueTaxB ?? 0)) | currency : 'BRL'}}
            </div>
        </div>
        <div class="field col-12 sm:col-12 md:col-3 lg:col-3 xl:col-3 text-center" style="font-size: 18px;">
            <label><span>Custo Operacional</span></label>
            <div>                
                {{((item.quantity ?? 0) * ((item.unitValueTaxA ?? 0) + (item.unitValueTaxB ?? 0))) | currency : 'BRL'}}
            </div>
        </div>
    </div>
    
    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showTaxA">
            <label for="otherValue"><span>Outros Custos</span></label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="otherValue" [(ngModel)]="item.otherValue"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="8" (onInput)="discountCalcule('other', (item.quantity ?? 0) * ((item.unitValueA ?? 0) + (item.unitValueB ?? 0) + (item.unitValueTaxA ?? 0) + (item.unitValueTaxB ?? 0)))" />
            </div>
        </div>    
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="discountValue"><span translate>Valor Desconto</span></label> 
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="discountValue" [(ngModel)]="item.discountValue"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="5" (onInput)="discountCalcule('valor', (item.quantity ?? 0) * ((item.unitValueA ?? 0) + (item.unitValueB ?? 0) + (item.unitValueTaxA ?? 0) + (item.unitValueTaxB ?? 0)))" />
            </div>
        </div>
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 justify-content-end" style="font-size: 25px;align-content: end;flex-wrap: wrap;display: grid;">
            <label class="text-right"><span>VALOR TOTAL</span></label>
            <div class="font-bold text-right">
                {{item.totalValue | currency : 'BRL'}}
            </div>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="discountPorc"><span translate>% Desconto</span></label> 
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>%</span></span>
                <p-inputNumber type="text" pInputText id="discountPorc" [(ngModel)]="item.discountPercent"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="6" (onInput)="discountCalcule('porc', (item.quantity ?? 0) * ((item.unitValueA ?? 0) + (item.unitValueB ?? 0) + (item.unitValueTaxA ?? 0) + (item.unitValueTaxB ?? 0)))" />
            </div>
        </div>
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 justify-content-end" style="font-size: 25px;align-content: end;flex-wrap: wrap;display: grid;">
            <label class="text-right" style="color: red;"><span>VALOR FINAL</span></label>
            <div class="font-bold text-right" style="color: red;">
                {{item.finalValue | currency : 'BRL'}}
            </div>
        </div>
    </div>
        
</div>

<div style="float: right; display: flex; align-items: flex-end; gap: 8px;">
    <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3"
        [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
    <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3"
        [label]="'button.confirm' | translate" (click)="saveItem()"></button>
</div>