<!--
<p-breadcrumb [model]="model">
  <ng-template let-item pTemplate="item">
    <span [ngClass]="{ 'p-breadcrumb-selected': item.selected }" >
        {{item.label}}
    </span>
  </ng-template>
  <ng-template let-item pTemplate="separator"> > </ng-template>
</p-breadcrumb> -->



<div class="breadcrumb" style="float: right !important;">
    <ng-template ngFor let-item let-last="last" [ngForOf]="model">
        <a [routerLink]="item.routerLink" *ngIf="item.routerLink">
            <span [ngClass]="{ 'font-bold': item.selected }" class="text-2xl" >
                {{ item.label }}
            </span>
        </a>
    <ng-container *ngIf="!item.routerLink">{{ item.label }}</ng-container>
    <span *ngIf="!last"> > </span>
  </ng-template>
</div>
