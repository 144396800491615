import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ScArchiveTypeService } from 'src/app/core/services/sc-archive-type/sc-archive-type.service';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { ScPersonFileOutput } from 'src/app/models/response/sc-person.model';
import { DropDown } from '../../model/base-model';
import { TranslateService } from '@ngx-translate/core';
import { FileUpload } from 'primeng/fileupload';
import { Galleria } from 'primeng/galleria';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';

@Component({
  selector: 'sf-sc-person-file',
  templateUrl: './sc-person-file.component.html',
  styleUrls: ['./sc-person-file.component.scss']
})
export class ScPersonFileComponent implements OnInit, AfterContentChecked {

  @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
  @Input() scPersonId: string;
  @Input() scPersonName: string;
  //@Input() scArchiveTypeId: string = '00000000-0000-0000-0000-000000000000';
  @Input() scArchiveTypeId: string = null;
  @Input() woOrderOrderServiceId: string;
  @Input() goBackVisible: boolean = false;
  docTypeList: DropDown[] = [];
  listPersonFile: ScPersonFileOutput[] = [];
  uploadedFiles: any[] = [];
  loadFiles: boolean = false;
  rowIndexHighlight: number = 0;
  responsiveOptions: any[];
  fileZoomVisible: boolean = false;
  fileZoomUrl: string = '';
  fieldValidation: boolean = false;
  docTypeId!: string;

  showThumbnails: boolean;
  fullscreen: boolean = false;
  activeIndex: number = 0;
  onFullScreenListener: any;
  @ViewChild('galleria') galleria: Galleria;
  @Input() fullScreen: boolean = false;
  canDelete: boolean = false;
  buttonAddNewDocument: boolean = true;
    
  constructor(private scArchiveTypeService: ScArchiveTypeService,
    private personService: ScPersonService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private userTokenService: UserTokenService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {    
    this.getDocumentType();

    this.activatedRoute.params.subscribe(params => {

      const id = params.id;
      const name = params.name;

      // if (id) {
      //   this.scPersonId = id;
      //   this.scPersonName = name;
      //   this.loadImages(id, this.scArchiveTypeId, 0);
      // } else {
      //  this.loadImages(this.scPersonId, this.scArchiveTypeId, 0);
      //}
      let scArchiveTypeId = this.scArchiveTypeId ?? '00000000-0000-0000-0000-000000000000';

      if (this.scPersonId != null && this.scPersonId !== undefined) {        
        this.loadImages(this.scPersonId, scArchiveTypeId, 0);
      } else {        
        this.scPersonId = this.userTokenService.getPersonId();
        this.scPersonName = this.userTokenService.getUserName();        
        this.loadImages(this.scPersonId, scArchiveTypeId, 0);
      }
    });
    this.translate();
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  translate() {
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));
    (async () => {
      await sleep(100);
      const labAccessControl = this.translateService.instant('label.accessControl') + ' ';
      const labPagePerson = ' ' + this.translateService.instant('label.person');
      const labPage = ' ' + this.translateService.instant('label.document_inserted');
      this.breadcrumbService.setItems([{ label: labAccessControl }, {label: labPagePerson, routerLink: ['/person/full/edit/' + this.scPersonId]}, { label: labPage, routerLink: ['/person/documents/' + this.scPersonId] }]);
    })();
  }

  messageSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000 });
  }

  messageError(message: string, consoleMsg: string) {
    this.messageService.add({ severity: 'warn', summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000 });
    console.log(consoleMsg);
  }

  getDocumentType() {
    this.scArchiveTypeService.getDropDownList().subscribe(
      {
        next: (res) => {
          if (res){
            this.docTypeList = res.data;
            this.docTypeList.unshift({id: '', name: this.translateService.instant('components.dropdown_select')});
          }
        },
        error: (err) => {
          this.messageError('api_response.ERROR', `ScPersonFileComponent - getDocumentType : ${err}.`);
        }
      });
  }

  reciverFeedbackDocType(id: string) {
    if (id == null || id == "" || id == undefined) {
      this.scArchiveTypeId = null;
    }
    else {
      this.scArchiveTypeId = id;      
    }
  }

  onUpload(event) {    
    this.uploadedFiles = event.files;
    //this.savePics();
  }

  savePics() {    
    if (this.scArchiveTypeId == null || this.scArchiveTypeId == "" || this.scArchiveTypeId == undefined || this.scArchiveTypeId == '00000000-0000-0000-0000-000000000000') {
      this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Selecione o Tipo do Documento.' });
      return;
    } else if (this.uploadedFiles.length == 0) {
      this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Clique no botão Adicionar, para selecionar pelo menos um arquivo.' });
      return;
    }

    this.personService
      .uploadFile(this.uploadedFiles, this.scPersonId, this.scArchiveTypeId, null)
      .subscribe(
        {
          next: () => {
            this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: 'Upload realizado com sucesso.' });
            this.uploadedFiles = [];
            this.fileUpload.clear();
            this.loadImages(this.scPersonId, this.scPersonId, 0);
            this.docTypeId = null;
            this.scArchiveTypeId = null;
          },
          error: (err) => {
            this.messageService.add({ severity: 'error', summary: 'File Uploaded', detail: `ScPersonFileComponent - onUpload : ${err}.` });
            console.log(err);
          }
        });
  }

  loadImages(scPersonId: string, scArchiveTypeId: string, index: number) {
    this.rowIndexHighlight = index;

    this.loadFiles = false;
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];

    this.getPersonFileList(scPersonId, null);
  }

  getPersonFileList(scPersonId: string, woOrderOrderServiceId: string) {

    this.personService.getListFile(scPersonId, woOrderOrderServiceId).subscribe(
      {
        next: (res) => {
          if (res) {
            this.listPersonFile = res.data.sort((a, b) => Number(b.inTheService) - Number(a.inTheService) || (a.archiveType.localeCompare(b.archiveType)));
            if (this.listPersonFile.length > 0) {
              let scPersonId = this.listPersonFile.find(f =>f.scPersonId !== undefined)?.scPersonId;
              if (scPersonId === this.userTokenService.getPersonId()) {
                this.canDelete = true;
              }
            }
          }

          this.loadFiles = true;
        },
        error: (err) => {
          console.log(`ScPersonFileComponent - getPersonFileList : ${err}.`);
        }
      });
  }

  deleteFile(id: string, scCompanyId: string, scPersonId: string, archiveType: string) {

    this.confirmationService.confirm({
      key: 'message.confirm_delete.file',
      message: this.translateService.instant('message.confirm_delete') + ' ' + archiveType + '?',
      header: this.translateService.instant('title.confirmation'),
      acceptLabel: this.translateService.instant('button.yes'),
      rejectLabel: this.translateService.instant('button.no'),
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-danger',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.personService
          .deleteFile(id, scCompanyId, scPersonId)
          .subscribe(
            {
              next: () => {
                this.messageService.add({ severity: 'info', summary: 'File Delete', detail: 'Arquivo removido com sucesso.' });
                this.loadImages(this.scPersonId, this.scPersonId, 0);
              },
              error: (err) => {
                this.messageService.add({ severity: 'error', summary: 'File Delete', detail: `ScPersonFileComponent - deleteFile : ${err}.` });
                console.log(err);
              }
            });
      }
    });
  }

  openFileZoom(url: string) {
    this.fileZoomVisible = true;
    this.fileZoomUrl = url;
  }

  getFiledValidation(value: boolean) {
    this.fieldValidation = value;
    return this.fieldValidation;
  }

  onThumbnailButtonClick() {
    this.showThumbnails = !this.showThumbnails;
  }

  toggleFullScreen() {
    if (this.fullscreen) {
      this.closePreviewFullScreen();
    } else {
      this.openPreviewFullScreen();
    }

    this.cdr.detach();
  }

  openPreviewFullScreen() {
    let elem = this.galleria.element.nativeElement.querySelector('.p-galleria');
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem['mozRequestFullScreen']) {
      /* Firefox */
      elem['mozRequestFullScreen']();
    } else if (elem['webkitRequestFullscreen']) {
      /* Chrome, Safari & Opera */
      elem['webkitRequestFullscreen']();
    } else if (elem['msRequestFullscreen']) {
      /* IE/Edge */
      elem['msRequestFullscreen']();
    }
  }

  onFullScreenChange() {
    this.fullscreen = !this.fullscreen;
    this.cdr.detectChanges();
    this.cdr.reattach();
  }

  closePreviewFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document['mozCancelFullScreen']) {
      document['mozCancelFullScreen']();
    } else if (document['webkitExitFullscreen']) {
      document['webkitExitFullscreen']();
    } else if (document['msExitFullscreen']) {
      document['msExitFullscreen']();
    }
  }

  bindDocumentListeners() {
    this.onFullScreenListener = this.onFullScreenChange.bind(this);
    document.addEventListener('fullscreenchange', this.onFullScreenListener);
    document.addEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.addEventListener('msfullscreenchange', this.onFullScreenListener);
  }

  unbindDocumentListeners() {
    document.removeEventListener('fullscreenchange', this.onFullScreenListener);
    document.removeEventListener('mozfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('webkitfullscreenchange', this.onFullScreenListener);
    document.removeEventListener('msfullscreenchange', this.onFullScreenListener);
    this.onFullScreenListener = null;
  }

  ngOnDestroy() {
    this.unbindDocumentListeners();
  }

  galleriaClass() {
    return `custom-galleria ${this.fullscreen ? 'fullscreen' : ''}`;
  }

  fullScreenIcon() {
    return `pi ${this.fullscreen ? 'pi-window-minimize' : 'pi-window-maximize'}`;
  }

  onGoBack(): void {
    this.router.navigate(['/person/full/edit/' + this.scPersonId]);
  }

  save() {
    this.savePics();
  }

  cancel() {
    this.uploadedFiles = [];
    this.fileUpload.clear();
    this.loadImages(this.scPersonId, this.scPersonId, 0);
    this.docTypeId = null;
    this.scArchiveTypeId = null;
    this.buttonAddNewDocument = true;
  }
}