import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WoOrderOrderServiceOutput, WoOrderOrderServiceValue } from 'src/app/features/mo-cartorio/wo-order-service/wo-order-service/wo-order-service.model';
import { WoOrderServiceService } from 'src/app/features/mo-cartorio/wo-order-service/wo-order-service/wo-order-service.service';







@Component({
    selector: 'sf-wo-order-payment-summary',
    templateUrl: './wo-order-payment-summary.component.html',
    styleUrls: ['./wo-order-payment-summary.component.scss']
})

export class WoOrderPaymentSummaryComponent implements OnInit{

    public list : WoOrderOrderServiceOutput[];
    public listGroup : WoOrderOrderServiceOutput[];
    public totalValue: WoOrderOrderServiceValue = {id:"", code:"", codeExternal:"", name:"", valueA: 0, valueB:0, valueTaxA: 0,valueTaxB: 0, quantity:0, total: 0};    public lazyComponent: any;


    @Input() WoOrderId: string;

    @Output() totalOutput = new EventEmitter();

    public moneyType: string = "BRL";
    public language: string = "pt-BR";



    constructor(private woOrderServiceService: WoOrderServiceService,
                private translateService: TranslateService
    ){ }

    ngOnInit(): void {
        this.getList();
    }


    getList() {
        this.woOrderServiceService.getOrderOrderServiceSummaryList(this.WoOrderId).subscribe(
          {
            next: (res) => {
                if (res)
                {
                    this.list = res.data;

                    this.list.forEach((item: WoOrderOrderServiceOutput) => {

                        this.totalValue.id = item.id;
                        this.totalValue.code = item.code;
                        this.totalValue.codeExternal = item.codeExternal;
                        this.totalValue.quantity = item.quantity;
                        this.totalValue.valueA = item.unitValueA + item.unitValueB + item.unitValueTaxA +item.unitValueTaxB;
                        this.totalValue.total = (item.unitValueA + item.unitValueB + item.unitValueTaxA + item.unitValueTaxB) * item.quantity;


                    });

                    this.feedback();
                }
            },
            error: (err) => {
                console.log(`WoOrderPaymentSummaryComponent - getList : ${err}.`);
            }
        });
      }

      feedback(){
        this.totalOutput.emit(this.totalValue.total);
      }



}

    // example usage

    // const pets = [
    //     {type:"Dog", name:"Spot"},
    //     {type:"Cat", name:"Tiger"},
    //     {type:"Dog", name:"Rover"},
    //     {type:"Cat", name:"Leo"}
    // ];

    // const grouped = groupBy(pets, pet => pet.type);

    // console.log(grouped.get("Dog")); // -> [{type:"Dog", name:"Spot"}, {type:"Dog", name:"Rover"}]
    // console.log(grouped.get("Cat")); // -> [{type:"Cat", name:"Tiger"}, {type:"Cat", name:"Leo"}]

    // const odd = Symbol();
    // const even = Symbol();
    // const numbers = [1,2,3,4,5,6,7];

    // const oddEven = groupBy(numbers, x => (x % 2 === 1 ? odd : even));

    // console.log(oddEven.get(odd)); // -> [1,3,5,7]
    // console.log(oddEven.get(even)); // -> [2,4,6]






