
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService, ConfirmationService, Message, MenuItem } from "primeng/api";
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';


import { UserTokenService } from 'src/app/core/services/user-token.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { WoOrderOutput, WoOrderStatusHist } from '../../wo-order.model';
import { WoOrderService } from '../../wo-order.service';
import { WoWorkFlowService } from 'src/app/pages/wo-workflow/wo-workflow.service';
import { WoOrderStatusService } from 'src/app/core/services/wo-order-status/wo-order-status.service';




@Component({
    selector: 'wo-order-detail',
    templateUrl: './wo-order-detail.component.html',
    styleUrls: ['./wo-order-detail.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class WoOrderDetailComponent implements OnInit {

    public listWorkflowTask: any [] = [];
    public listOrderStatusHist: WoOrderStatusHist[] = [];

    public orderType: string;
    public language: string = "pt-BR";

    public item: WoOrderOutput = {};
    public id: string;




    public flUpdate: boolean = true;
    public isDisabled: boolean = true;
    public showBtn: boolean = false;

    public message: string = "";
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-mr-2 mt-3 font-medium";

    public lazyComponent: any;



    public events: any[] = [
        { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    ];


//exemplo

    displayCustom: boolean | undefined;

    activeIndex: number = 0;




    responsiveOptions: any[] = [
        {
            breakpoint: '1500px',
            numVisible: 5
        },
        {
            breakpoint: '1024px',
            numVisible: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];




    constructor(private orderService: WoOrderService,
                private orderStatusService: WoOrderStatusService,
                private workFlowService: WoWorkFlowService,
                private userTokenService: UserTokenService,
                private activateRoute: ActivatedRoute,
                private notificationsService: NotificationsService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private router: Router) { }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();
        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN','SF_SELLER', 'WO_ORDER_U']);

        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-mr-2 mt-3 font-medium";
        }

        //this.item = this.activateRoute.snapshot.data['ScCompany'].company;

        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('id')) {
                this.id = params.get('id');
                this.getItem();
            }
            else
                this.translate();
        });



    }

     translate(){
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
            await sleep(100);
            const labOrder = this.translateService.instant('label.order')+" ";
            const labService = this.translateService.instant('label.order_service')+" ";
            const labServiceDetail = " "+this.translateService.instant('label.order_service_detail');
            this.breadcrumbService.setItems([{label: labOrder},{label: labService, routerLink: ['order-service']}, { label: labServiceDetail, routerLink: ['order-service-detail/' + this.item.id] }])
        })();
    }

    async loadLazyComponent() {
        const module = await import('../../../../modules/cartorio/component/sfc-service-autenticacao/sfc-service-autenticacao.component');
        this.lazyComponent = module.SfcServiceAutenticacaoComponent;
      }

    getItem() {
        this.orderService.getItem(this.id).subscribe(
            {
                next: (res) => {
                    if (res) {
                        this.item = res.data;
                        this.getStatusNext();
                       // this.getListOrderStatusHist();
                    }
                },
                error: (err) => {
                    console.log(`WoOrderDetailComponent - getServiceItem : ${err}.`);
                }
            });
    }

    // getListOrderStatusHist() {
    //     this.orderStatusService.getStatusHist(this.id, this.language).subscribe(
    //         {
    //             next: (res) => {
    //                 if (res)
    //                     this.listOrderStatusHist = res.data;

    //             },
    //             error: (err) => {
    //                 console.log(`WoOrderDetailComponent - getServiceStatusTimeline : ${err}.`);
    //             }
    //         });
    // }


    getStatusNext() {
        this.workFlowService.getTaskStatusByOrder(this.item.woWorkFlowId, this.item.id, this.language).subscribe(
            {
                next: (res) => {
                    if (res){
                        if (res.data != null)
                        {
                            this.listWorkflowTask = res.data.items;
                            this.showBtn = this.listWorkflowTask.length > 0;
                        }
                        else
                            this.showBtn = false;
                    }

                },
                error: (err) => {
                    console.log(`WoOrderDetailComponent - getTaskStatusByOrder : ${err.error}.`);
                    console.log(err);
                }
            });
    }



    updStatus(idStatus: string)
    {
        this.orderService
        .updStatus(this.item.id, idStatus, "")
        .subscribe(
            {
                next: () => {
                    this.notificationsService.toastNotify('success', this.translateService.instant('title.success'), this.translateService.instant('message.record_updated'));
                  //  this.isSaved = true;
                   // this.itemBase = JSON.stringify(this.item);
                   this.getItem();
                  // this.getListOrderStatusHist();
                   this.getStatusNext();
                },
                error: (err) => {
                    console.log(`WorkFlowDetail - saveItem - Upd : ${err}.`)
                    this.message = this.translateService.instant('api_response.'+err.error);

                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.notificationsService.toastNotify('warn', this.translateService.instant('title.warning'), this.message);
                }
            });
    }


    newService(type: string){
        this.orderType = type;
    }



    closeCancel(){

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_cancel'),
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.router.navigate(['order-serice']);
            }
        });
    }

    save(){

    }






    //edemplo

    imageClick(index: number) {
        this.activeIndex = index;
        this.displayCustom = true;
    }

}

