
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthorizationGuard } from "src/app/core/guards/authorization.guard";
import { AuthenticationGuard } from "src/app/core/guards/authentication.guard";
import { ScAddressGridComponent } from "./sc-address-grid/sc-address-grid.component";




  const routes: Routes = [
    {
        path: '', component: ScAddressGridComponent,
        canActivate: [AuthenticationGuard],
    }
  ];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class ScAddressRoutingModule { }
