import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SfSubHeaderComponent } from './sub-header.component';


@NgModule({

    declarations: [SfSubHeaderComponent],
    exports: [SfSubHeaderComponent],
    imports: [  CommonModule,
                FormsModule,
        ]


})

export class SfSubHeaderModule {

}
