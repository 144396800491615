import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class SfMessageService {



  constructor(private translateService: TranslateService,
              private messageService: MessageService
  ) { }

  messageSuccess(message: string){
    console.log('message', message);
    console.log(this.translateService.instant(message));
    this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 4000});
  }

  messageError(message: string){
    this.messageService.add({severity: 'error', summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 4000});

  }

  messageWarning(message: string){
    this.messageService.add({severity: 'warn', summary: this.translateService.instant('title.warning'), detail: this.translateService.instant(message), life: 4000});
  }

  messageFileUploaded(message: string){
    this.messageService.add({severity: 'info', summary: this.translateService.instant('label.fileUploaded'), detail: this.translateService.instant(message), life: 4000});
  }
}

