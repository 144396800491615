import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

import { DropDownMeta } from 'src/app/shared/model/base-model';
import { WoOrderStatus, WoOrderStatusHistList, WoOrderStatusItem, WoOrderStatusList } from 'src/app/models/response/wo-order-status.model';




@Injectable({
    providedIn: 'root'
  })

export class WoOrderStatusService{

    private environmentUrl: string = '';


    constructor(private httpClient: HttpClient)
    {
        this.environmentUrl = environment.ApiCore+'/OrderStatus/';
    }

    getItem(id: string): Observable<WoOrderStatusItem> {
        let url = this.environmentUrl+ id;
        return this.httpClient.get<WoOrderStatusItem>(url);
    }

    getList() : Observable<WoOrderStatusList>{
        let url = this.environmentUrl;
        return this.httpClient.get<WoOrderStatusList>(url);
    }

    addItem(model: WoOrderStatus){
        let url = this.environmentUrl;
        return this.httpClient.post(url, model);
    }

    updItem(model: WoOrderStatus){
        let url = this.environmentUrl+ model.id;
        return this.httpClient.put(url, model);
    }

    delItem(id: string) {
        let url = this.environmentUrl+id;
        return this.httpClient.delete(url);
    }

    getDropDownList(): Observable<DropDownMeta> {
        let url = this.environmentUrl + "DropDown";
        return this.httpClient.get<DropDownMeta>(url);
    }

    getStatusHist(woOrderId: string, language: string): Observable<WoOrderStatusHistList>{
        let url = this.environmentUrl+'Hist?language='+ language+'&search='+ woOrderId;
        return this.httpClient.get<WoOrderStatusHistList>(url);
    }
}


