import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ScAddressService } from 'src/app/core/services/sc-address/sc-address.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScAddressList, ScAddressOutput } from 'src/app/models/response/sc-address.response';
import { ScCompanyInfoInput } from 'src/app/pages/sc-company/sc-company.model';
import { ScCompanyService } from 'src/app/pages/sc-company/sc-company.service';

import { BreadCrumb } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.model';



@Component({
    selector: 'sc-address-grid',
    templateUrl: './sc-address-grid.component.html',
    styleUrls: ['./sc-address-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScAddressGridComponent implements OnInit {

    public language: string = "pt-BR";
    public flUpdate: boolean = true;
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";


    public loading: boolean = false;
    list!: ScAddressOutput[];
    public item: ScAddressOutput;

    selectedSort: any = null;
    filterSort: any[] = [
      { name: 'Nome', key: 'name' },
      { name: 'Nome Fantasia', key: 'fantasyName' },
      { name: 'Código', key: 'code' }
    ];
    sortOrder!: number;
    sortField!: string;

    public BREAD_CRUMB_LIST: Array<BreadCrumb> = [];

    constructor(private messageService: MessageService,
        private companyService: ScCompanyService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private addressService: ScAddressService,
                private router: Router)
    { }

    ngOnInit(): void {
        this.getList();
        this.translate();
    }


    getList() {
        this.addressService.getList().subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                    console.log(this.list);
                },
                error: (err) => {
                    console.log(`ScAddressGridComponent - getList : ${err}.`);
                }
            });
    }
    // getList() {
    //     this.companyService.getListPartners('CARTORIO').subscribe(
    //       {
    //         next: (res) => {
    //           if (res) {
    //             this.list = res.data;


    //           }
    //           this.loading = false;
    //         },
    //         error: (err) => {
    //           console.log(`SelectStepRegistryOfficeComponent - getList : ${err}.`);
    //         }
    //       });
    //   }


    edit(id: string) {
        this.router.navigate(['/order/order-Client', id]);
    }



    messageSuccess(message: string){
        this.messageService.add({severity: 'success', summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string){
        this.messageService.add({severity: 'warn', summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
    }

    translate()
    {
        this.language = this.translateConfigService.getLanguage();

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(50);
             const lab0 = this.translateService.instant('label.home') + ' ';
             const lab1 = ' ' + this.translateService.instant('label.addresses');

            this.BREAD_CRUMB_LIST = [
                {label: lab0,routerLink: '/',selected: false},
                {label: lab1,routerLink: '/address-list',selected: true}
            ]


         })();




    }


}
