import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserToken } from 'src/app/core/auth.model';
//import { UserTokenService } from 'src/app/core/services/user-token.service';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { ScUserService } from 'src/app/core/services/sc/sc-user.service';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ScUserMessageOutput } from 'src/app/models/response/sc-user.model';
import { OverlayPanel } from 'primeng/overlaypanel';

interface TableRowSelectEvent {
  originalEvent?: Event;
  data?: any;
  type?: string;
  index?: number;
}

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

  user$: Observable<UserToken>;
  public logo: String = "";
  public hasLogo: boolean = false;
  public idUsuario: number;
  public companyName: String = "";
  currentUser!: UserToken;
  userAcronym!: string;
  readonly AVATAR_COLOR = '#ba40ef';

  isDesktop$!: Observable<boolean>;

  public listMessage: ScUserMessageOutput[] = [];
  selectedMessage: ScUserMessageOutput | undefined;
  message_confirm_reading!: string;

  constructor(public app: AppComponent,
    public appMain: AppMainComponent,
    private router: Router,
    private userTokenService: UserTokenService,
    private scUserService: ScUserService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private userService: ScUserService) { }

  ngOnInit(): void {
    //TODO - Company Name and Logo - where to show?
    this.user$ = this.userTokenService.getUser();
    this.companyName = this.userTokenService.getCompanyName();
    //this.isDesktop$ = this.uiService.onDetectDevice(900);

    this.logo = this.userTokenService.getLogoUri();
    if (this.logo != "" || this.logo.length > 1)
      this.hasLogo = true;

    this.router.events.subscribe(event => {
      //console.log(event)
      if (event instanceof NavigationStart) {
        //console.log('Mudou a rota!', event);
        this.scUserService.getListUserMessageByUser()
          .subscribe(
            {
              next: (data) => {
                data.data.forEach(element => {
                  this.messageService.add({ key: 'messages', severity: 'info', summary: this.translateService.instant('message.new_message'), detail: element.message, life: 10000 });
                  element.isView = true;
                  this.scUserService.updUserMessage(element)
                    .subscribe(
                      {
                        next: (data) => {
                        },
                        error: (err) => {
                          console.log(`AppComponent - updUserMessage: ${err}.`);
                        }
                      });
                });
              },
              error: (err) => {
                console.log(`AppComponent - router.events.subscribe: ${err}.`);
              }
            });
      }
    });

    this.message_confirm_reading = this.translateService.instant('label.message_confirm_reading');    

    this.getListUserMessage();

  }

  onGetUser(): void {
    this.userTokenService.getUser().subscribe({
      next: (response) => {
        if (response) {
          this.currentUser = response;

          const fullName = response.Given_name.split(' ')

          const firstName = fullName[0];
          const lastName = fullName[fullName.length - 1];

          const firstletter = firstName[0].toLocaleUpperCase();
          const lastletter = lastName[0].toLocaleUpperCase();

          this.userAcronym = `${firstletter}${lastletter}`;
        }
      },
      error: () => null
    });

  }

  getListUserMessage() {
    this.userService.getListUserMessage(`TargetUserId_SourceUserId = ${this.userTokenService.getUserId()}`).subscribe(
      {
        next: (res) => {
          if (res)
            this.listMessage = res.data.filter(f => f.isRead !== true);
        },
        error: (err) => console.log(`AppTopBarComponent - getListUserMessage : ${err}.`)
      });
  }

  onRowSelect(event: any, op: OverlayPanel) {
    //this.messageService.add({ key:"messages", severity: 'info', summary: 'Messsage Selected', detail: event.data.name });
    //op.hide();
    event.data.isRead = true;
    this.scUserService.updUserMessage(event.data)
      .subscribe(
        {
          next: (data) => {
            this.getListUserMessage();
          },
          error: (err) => {
            console.log(`AppComponent - onRowSelect - updUserMessage: ${err}.`);
          }
        });
  }


}
