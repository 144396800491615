<sf-loader></sf-loader>
<div class="p-col-12" style="margin-top: -5px;"></div>
<div class="formgrid grid">

    <div class="field col-12 md:col-9 lg:col-9 xl:col-9">
        <div class="card">
            <div class="grid">
                <div class="col">
                    <h3 class=""><span class="font-bold">Atendimento #</span> {{ item.code }} </h3>
                </div>
                <div class="col font-bold">Senha: <span class="text-orange-600"> {{item.ticket}}</span></div>

                <div class="col text-right"> <span [class]="'status-badge-small' "
                        [style]="{'background-color':''+item.stageColor+'', 'color': '#FFFFFF'}">{{item.stageName}} -
                        {{item.statusName}}</span> </div>
            </div>

            <div class="formgrid grid text-lg">
                <div class="field col-12 sm:12 md:col-8 xl:col-8 lg:col-8">
                    <div class="p-inputgroup">
                        <div class="col-5">
                            <label for="atendenteResponsavel" class="font-bold">Atendente Responsável:</label>
                        </div>
                        <div class="col-7">
                            <span id="atendenteResponsavel"> {{item.userName}}</span>
                        </div>
                    </div>
                </div>
                <div class="field col-12 sm:12 md:col-4 xl:col-4 lg:col-4">
                    <div class="p-inputgroup">
                        <div class="col-5">
                            <label for="dataAbertura" class="font-bold">Data Abertura:</label>
                        </div>
                        <div class="col-7">
                            <span id="dataAbertura"> {{item.createdDate | date: 'dd/MM/yy HH:mm'}}</span>
                        </div>
                    </div>
                </div>

                <!-- <div class="field col-12 md:col-5">
                    <label class="font-bold mr-2">Cliente</label><p-rating [ngModel]="item.clientRating"
                        [readonly]="true" [cancel]="false" [iconOnStyle]="{'color':'#4743fb'}"></p-rating>
                    <sf-sc-person-dropdown id="person" componentType="basic" [id]="item.scClientId"
                        (chosedItem)="reciverFeedbackPerson($event)" [placeHolderLanguage]="'label.client' | translate"
                        [isDisabled]="!item.canChangeClient"></sf-sc-person-dropdown>
                </div> -->
            </div>

            <div class="col text-center">
                <h5 class=""><span class="font-bold">Dados do Cliente</span></h5>
            </div>

            <div class="formgrid grid text-lg">
                <div class="field col-12 sm:12 md:col-8 xl:col-8 lg:col-8 text-lg">
                    <div class="p-inputgroup">
                        <div class="col-2">
                            <label for="clientName" class="font-bold">Nome:</label>
                        </div>
                        <div class="col-10">
                            <span id="clientName"> {{item.clientName}}</span>
                        </div>
                    </div>
                </div>
                <div class="field col-12 sm:12 md:col-4 xl:col-4 lg:col-4 text-lg">
                    <div class="p-inputgroup">
                        <div class="col-2">
                            <label for="clientDoc" class="font-bold">Doc: </label>
                        </div>
                        <div class="col-10">
                            <span id="clientDoc">{{item.clientDoc}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="formgrid grid text-lg">
                <div class="field col-12 sm:12 md:col-8 xl:col-8 lg:col-8 text-lg">
                    <div class="p-inputgroup">
                        <div class="col-2">
                            <label for="clientPhone" class="font-bold">Telefone:</label>
                        </div>
                        <div class="col-10">
                            <span id="clientPhone"></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col text-center">
                <h5 class=""><span class="font-bold">Serviços solicitados</span></h5>
            </div>

            <div class="formgrid grid text-lg">
                <div class="field col-12 sm:12 md:col-12 xl:col-12 lg:col-12 text-lg">
                    <div class="p-inputgroup">
                        <label for="serviceName" class="font-bold">Nome:</label>
                        <span id="clientName"></span>
                    </div>
                </div>
            </div>

            <div class="formgrid grid text-lg">
                <div class="field col">
                    <label for="observacao" class="font-bold">Observações</label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-comment"></i>
                        </span>
                        <input id="observacao" pInputText placeholder="Adicione as observações"
                            [(ngModel)]="item.obs" />
                        <div style="margin-left: 10px; margin-top: -13px;"><button pButton pRipple icon="pi pi-save"
                                [class]="classBtnUpd" (click)="saveObs(item.obs);"></button></div>
                    </div>
                </div>
            </div>
        </div> <!--Main Card -->

        <!-- <div class="card">
            <div class="formgrid grid">
                <div class="field col">
                    <span class="font-bold"># Total de Itens</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-list"></i>
                        </span>
                        <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.quantity">
                    </div>
                </div>
                <div class="field col">
                    <span class="font-bold">Recompe</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueA | number:'1.2-2'">
                    </div>
                </div>

                <div class="field col">
                    <span class="font-bold">Emolumentos</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueB | number:'1.2-2'">
                    </div>
                </div>
                <div class="field col">
                    <span class="font-bold">Taxa Fiscalização</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueTaxA | number:'1.2-2'">
                    </div>
                </div>
                <div class="field col">
                    <span class="font-bold">ISS</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.valueTaxB | number:'1.2-2'">
                    </div>
                </div>


                <div class="field col">
                    <span class="font-bold">Valor Total</span>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">R$</span>
                        <input type="text" pInputText [disabled]="isDisabled" [value]="totalValue.total | number:'1.2-2'">
                    </div>
                </div>

                <div class="field col mt-4" *ngIf="item.woPaymentId != '' && item.woPaymentId != undefined">
                    <span [class]="'status-badge-small' " [style]="{'background-color':'#13a103', 'color': '#FFFFFF'}">Pagamento
                        Realizado</span>
                </div>


            </div>

        </div> -->
        <!-- card total values -->


        <div class="card">
            <div class="p-col-12 p-md-12">
                <p-tabView [(activeIndex)]="activeIndex">
                    <!-- <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span class="material-icons">view_list</span>
                            <span class="ml-2">Relação de Serviços</span>
                        </ng-template>
                        <div style="min-height: 360px;" class="mt-2">
                            <sfc-service-grid [woOrderId]="id" [canExecuteService]="item.statusCanExecuteService"
                                (chosedItem)="reciverFeedbackItem($event)" [woOrderTypeId]="item.woOrderTypeId"
                                [scPerson]="item.clientName"> </sfc-service-grid>
                        </div>
                    </p-tabPanel> -->
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span class="material-icons">account_box</span>
                            <span class="ml-2">Dados do Cliente</span>
                        </ng-template>
                        <!-- <p>
                            <button (click)="loadLazyComponent(1)">Load Lazy Component</button>
                            <ng-container *ngIf="lazyComponent">
                                <ng-container *ngComponentOutlet="lazyComponent"></ng-container>
                            </ng-container>
                        </p> -->

                        <div class="card">

                            <div class="col text-center">
                                <h5 class=""><span class="font-bold">Dados do Cliente</span></h5>
                            </div>

                            <div class="formgrid grid text-lg">
                                <div class="field col-12 sm:12 md:col-8 xl:col-8 lg:col-8 text-lg">
                                    <div class="p-inputgroup">
                                        <div class="col-2">
                                            <label for="clientName" class="font-bold">Nome:</label>
                                        </div>
                                        <div class="col-10">
                                            <span id="clientName"> {{item.clientName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 sm:12 md:col-4 xl:col-4 lg:col-4 text-lg">
                                    <div class="p-inputgroup">
                                        <div class="col-2">
                                            <label for="clientDoc" class="font-bold">Doc: </label>
                                        </div>
                                        <div class="col-10">
                                            <span id="clientDoc">{{item.clientDoc}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid text-lg">
                                <div class="field col-12 sm:12 md:col-8 xl:col-8 lg:col-8 text-lg">
                                    <div class="p-inputgroup">
                                        <div class="col-2">
                                            <label for="clientPhone" class="font-bold">Telefone:</label>
                                        </div>
                                        <div class="col-10">
                                            <span id="clientPhone"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span class="material-icons">folder_shared</span>
                            <span class="ml-2"> Documentos Cliente</span>
                        </ng-template>
                        <!-- <p>
                            <button (click)="loadLazyComponent(2)">Load Lazy Component</button>
                            <ng-container *ngIf="lazyComponent">
                                <ng-container *ngComponentOutlet="lazyComponent"></ng-container>
                            </ng-container>
                        </p> -->

                        <div class="card">
                            <sf-sc-person-file [scPersonId]="'AA3A1748-2980-4C14-99B6-1BC3A727922C'"></sf-sc-person-file>
                        </div>

                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span class="material-icons">description</span>
                            <!-- <i class="pi pi-list"></i> -->
                            <span class="ml-2">Histórico de Mensagens</span>
                        </ng-template>

                        <p-card [header]="'Histórico de Mensagens'">
                            <ng-template pTemplate="content">
                                <div style="height: 500px;  overflow-y: scroll;">
                                    <div *ngFor="let item of messagensStatusHist; let i = index;" class="p-message-custom mb-2">
                                        <div class="text-left">
                                            <span class="font-bold">Usuário: </span>{{item.userLogin}}
                                        </div>
                                        <div class="text-left">
                                            <span class="font-bold">Mensagem: </span>{{item.message}}
                                        </div>
                                        <div class="text-left">
                                            <span class="font-bold">Data: </span>{{item.updatedAt | date: 'dd/MM/yyyy HH:mm'}}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </p-card>

                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span class="material-icons">description</span>
                            <!-- <i class="pi pi-list"></i> -->
                            <span class="ml-2">Orçamento</span>
                        </ng-template>
                        <p>
                        </p>
                    </p-tabPanel>

                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span class="material-icons">description</span>
                            <!-- <i class="pi pi-list"></i> -->
                            <span class="ml-2">Pagamento</span>
                        </ng-template>
                        <p>
                        </p>
                    </p-tabPanel>

                </p-tabView>
            </div>
        </div>


    </div>
    <div class="col-12 md:col-3 lg:col-3 xl:col-3 p-order-3 p-order-lg-2">
        <div class="card">
            <h5 class="text-center font-bold">Ações </h5>
            <span class="p-buttonset">
                <div *ngIf="showBtn" class="text-center">
                    <div *ngFor="let task of listWorkflowTask">
                        <button pButton pRipple label='{{task.code}}' icon="{{task.icon}}"
                            (click)="updStatus(task.woOrderStatusNextId);"
                            [style]="{'border-color':''+task.statusStageColor+'','background-color':''+task.statusStageColor+''}"
                            class="btn-action"></button>
                    </div>
                </div>
                <div class="text-center">
                    <button pButton pRipple icon="pi pi-times"
                        class="p-button-danger p-mr-2 mt-3 font-medium btn-action" label="Fechar e Voltar"
                        (click)="closeCancel();"></button>
                </div>

            </span>
        </div>

        <div class="card">
            <div class="p-field p-col-12">
                <div class="card">
                    <h5 class="text-center font-bold">Histórico do Atendimento </h5>
                    <p-scrollPanel [style]="{width: '100%', height: '475px'}" styleClass="customized-timeline">
                        <sf-wo-order-status-timeline [woOrderId]="id"
                            [languageCode]="language"></sf-wo-order-status-timeline>
                    </p-scrollPanel>
                </div>
            </div>
        </div>
    </div>
    <div class="field col-12 md:col-9 lg:col-9 xl:col-9">

    </div>
</div>

<div *ngIf="formPaymentModal" class="overlay">
    <p-dialog header="Pagamento" [visible]="formPaymentModal" [style]="{width: '700px;', height: '600px;'}">

        <div style="margin-top: 10px;">
            <sf-wo-order-payment [order]="item" (closePopup)="reciverFeedbackPayment($event)"></sf-wo-order-payment>
        </div>

    </p-dialog>
</div>
