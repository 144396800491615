<div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <div class="p-field m-2">
                <label for="code"><span translate>label.code</span></label>
                <input type="text" pInputText id="code" [(ngModel)]="item.code" required [disabled]="!canUpdate"
                    tabindex="1" />
                <small class="p-invalid" *ngIf="submitted && !item.code"><span
                        translate>label.field_code_required</span></small>
            </div>
        </div>

        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="code"><span translate>label.externalCode</span></label>
            <input type="text" pInputText id="externalCode" [(ngModel)]="item.codeExternal" [disabled]="!canUpdate"
                tabindex="2" />
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="name"><span translate>label.name</span></label>
            <input type="text" pInputText id="name" [(ngModel)]="item.name" required [disabled]="!canUpdate"
                tabindex="3" />
            <small class="p-invalid" *ngIf="submitted && !item.name"><span
                    translate>label.field_name_required</span></small>
        </div>

        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="module"><span translate>label.component</span></label>
            <sf-dropdown-generic appendTo="body" [list]="OrderServiceTemplateList" [id]="item.woOrderServiceTemplateId"
                tabindex="5" (selectedItemOutput)="reciverFeedbackTemplate($event)"
                [disabled]="!canUpdate"></sf-dropdown-generic>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">

            <label for="description"><span translate>label.description</span></label>
            <textarea rows="4" cols="30" pInputTextarea id="description" [(ngModel)]="item.description"
                [disabled]="!canUpdate" tabindex="4"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
        </div>
        <!-- <div class="p-field mt-4">
            <label for="code"><span translate>label.productivityFactor</span></label>
            <input type="text" pInputText id="code" [(ngModel)]="item.productivityFactor" required [disabled]="!canUpdate" />
        </div> -->
    </div>



    <!-- <div class="p-field m-2">
                <label for="code"><span translate>label.executionTime</span></label>
                <p-calendar [timeOnly]="true"  id="executionTime" [(ngModel)]="item.executionTime"  [disabled]="!canUpdate" />
            </div> -->

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="unitValueA"><span>{{labelUnitValueA}}</span></label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="unitValueA" [(ngModel)]="item.unitValueA"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="6" />
            </div>
        </div>
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showTaxA">
            <label for="unitValueTaxA"><span>{{labelUnitValueTaxA}}</span></label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="unitValueTaxA" [(ngModel)]="item.unitValueTaxA"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="7" />
            </div>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showValueB">
            <label for="unitValueB"><span>{{labelUnitValueB}}</span></label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="unitValueB" [(ngModel)]="item.unitValueB"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="8" />
            </div>
        </div>
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showTaxB">
            <label for="unitValueTaxB"><span>{{labelUnitValueTaxB}}</span></label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="unitValueTaxB" [(ngModel)]="item.unitValueTaxB"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="9" />
            </div>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showRange">
            <label for="totalRangeValueFrom"><span translate>label.totalRangeValueFrom</span></label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="totalRangeValueFrom" [(ngModel)]="item.totalRangeValueFrom"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="10" />

            </div>
        </div>
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showRange">
            <label for="totalRangeValueTo"><span translate>label.totalRangeValueTo</span></label>
            <div class="p-inputgroup">
                <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                <p-inputNumber type="text" pInputText id="totalRangeValueTo" [(ngModel)]="item.totalRangeValueTo"
                    [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="11" />
            </div>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <input type="checkbox" id="isActive" [(ngModel)]="item.isActive">
            <label for="isActive"><span translate>label.active</span></label>
        </div>
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="icon"><span translate>label.icon</span></label>
            <sf-dropdown-generic appendTo="body" [list]="icons" tabindex="5" [id]="item.icon"
                (selectedItemOutput)="reciverFeedbackIcon($event)" [disabled]="!canUpdate"></sf-dropdown-generic>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
            <label for="icon"><span translate>Tipos de Arquivo</span></label>
            <sf-sc-archive-type-list-multi [selectedItems]="item.archiveTypeList"
                (chosedItem)="feedbackArchiveType($event)"></sf-sc-archive-type-list-multi>
        </div>
    </div>
</div>

<div style="float: right; display: flex; align-items: flex-end; gap: 8px;">
    <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;" class="p-button-danger p-mr-2 mt-3"
        [label]="'button.cancel' | translate" (click)="hideDialog()"></button>
    <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;" class="p-button-success p-mr-2 mt-3"
        [label]="'button.confirm' | translate" (click)="saveItem()"></button>
</div>