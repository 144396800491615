<sf-loader></sf-loader>
<sf-notification></sf-notification>

<div class="formgrid grid">
    <div class="col-12 sm:col-12 md:col-12 lg:col-6">
        <sf-sub-header [title]="'label.services' | translate" [description]="'label.serviceClient' | translate">
        </sf-sub-header>
    </div>
    <div class="col-12 sm:col-12 md:col-12 lg:col-6">
        <sf-breadcrumb [model]="BREAD_CRUMB_LIST"></sf-breadcrumb>
    </div>
</div>

<p-divider></p-divider>
<div class="p-grid">
    <div class="p-col-12" style="margin-top: -18px;">
        <div class="flex align-items-center justify-content-center z-2" style="margin-top: 50px;" *ngIf="isLoading">
            <p-progressSpinner styleClass="w-4rem h-4rem margin-top: 25px;" strokeWidth="3" />
        </div>

        <p-table #dt [value]="list" dataKey="id" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
            [currentPageReportTemplate]="pageMessage" [rowsPerPageOptions]="[25,50,100]"
            [globalFilterFields]="['code','name','description','component']"
            styleClass="p-datatable-responsive-demo p-datatable-striped" responsiveLayout="stack" [breakpoint]="'450px'"
            [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}">

            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton pRipple icon="pi pi-plus" [label]="'button.serviceNew' | translate"
                        class="p-button-primary mr-2 " style="width: 170px;" (click)="addItem()"></button>
                    <button pButton pRipple icon="pi pi-file-excel" [label]="'button.exportService' | translate"
                        class="p-button-success mr-2" style="width: 170px;" (click)="exportItem()"></button>
                    <button pButton pRipple icon="pi pi-file-import" [label]="'button.importService' | translate"
                        class="p-button-warning mr-2" style="width: 170px;" (click)="importItem()"></button>

                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            [placeholder]="'search.service' | translate" />
                        <button pButton [label]="'button.clear' | translate" class="p-button-warning mr-2 ml-2"
                            icon="pi pi-filter-slash" (click)="clear(dt, filter); filter.value = '';"></button>
                    </span>
                    <p-button icon="pi pi-refresh" (click)="getList()"></p-button>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: center;"><span translate>label.code</span> </th>
                    <th style="text-align: center;"><span translate>label.externalCode</span></th>
                    <th style="text-align: center;" pSortableColumn="name"><span translate>label.name</span><p-sortIcon
                            field="name"></p-sortIcon></th>
                    <th style="text-align: center;" pSortableColumn="description"><span
                            translate>label.description</span><p-sortIcon field="description"></p-sortIcon></th>
                    <!-- <th style="text-align: center;"><span translate>label.executionTime</span></th>                     -->
                    <!-- <th style="text-align: center;"><span translate>label.unitValue</span></th>
                    <th style="text-align: center;" *ngIf="showValueB"><span>labelUnitValueB</span></th>
                    <th style="text-align: center;" *ngIf="showTaxA"><span>labelUnitValueTaxA</span></th>
                    <th style="text-align: center;" *ngIf="showTaxB"><span>labelUnitValueTaxB</span></th>
                    <th style="text-align: center;" *ngIf="showRange"><span translate>label.TotalRangeValueFrom</span></th>
                    <th style="text-align: center;" *ngIf="showRange"><span translate>label.TotalRangeValueTo</span></th> -->
                    <th style="text-align: center;"><span translate>label.component</span></th>
                    <th style="text-align: center;"><span translate>label.active</span></th>
                    <th style="width: 150px; text-align: center;"><span translate>label.actions</span></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="text-align: center;">{{item.code}}</td>
                    <td style="text-align: center;">{{item.externalCode}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.description}}</td>
                    <!-- <td style="text-align: center;">{{item.excutionTime}}</td> -->
                    <!-- <td style="text-align: center;">{{item.unitValueA | number:'1.2-2'}}</td>
                    <td style="text-align: center;" *ngIf="showValueB">{{item.unitValueB | number:'1.2-2'}}</td>
                    <td style="text-align: center;" *ngIf="showTaxA">{{item.unitValueTaxA | number:'1.2-2'}}</td>
                    <td style="text-align: center;" *ngIf="showTaxB">{{item.unitValueTaxB | number:'1.2-2'}}</td>
                    <td style="text-align: center;" *ngIf="showRange">{{item.TotalRangeValueFrom | number:'1.2-2'}}</td>
                    <td style="text-align: center;" *ngIf="showRange">{{item.TotalRangeValueTo | number:'1.2-2'}}</td>                     -->
                    <td>{{item.orderServiceTemplateName}}</td>
                    <td>{{item.isActive == true ? 'Sim' : 'Não'}}</td>
                    <td style="text-align: center;">
                        <p-button icon="pi pi-pencil" styleClass="p-button p-button-success p-m-1 mr-1"
                            [pTooltip]="'button.edit' | translate" (click)="updItem(item)"></p-button>
                        <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-m-1 mr-1"
                            [pTooltip]="'button.delete' | translate" (click)="delItem(item)"
                            [disabled]="item.isActive || !canUpdate ? true : false"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>


<p-dialog [(visible)]="displayDialog" [style]="{width: '800px'}" [modal]="true" styleClass="p-fluid"
    [header]="dialogHeader">
    <ng-template pTemplate="content">
        <div style="margin-top:1px;" class="p-grid p-d-flex dialog-page">
            <div class="formgrid grid">
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <div class="p-field m-2">
                        <label for="code"><span translate>label.code</span></label>
                        <input type="text" pInputText id="code" [(ngModel)]="item.code" required [disabled]="!canUpdate"
                            tabindex="1" />
                        <small class="p-invalid" *ngIf="submitted && !item.code"><span
                                translate>label.field_code_required</span></small>
                    </div>
                </div>

                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <label for="code"><span translate>label.externalCode</span></label>
                    <input type="text" pInputText id="externalCode" [(ngModel)]="item.codeExternal"
                        [disabled]="!canUpdate" tabindex="2" />
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <label for="name"><span translate>label.name</span></label>
                    <input type="text" pInputText id="name" [(ngModel)]="item.name" required [disabled]="!canUpdate"
                        tabindex="3" />
                    <small class="p-invalid" *ngIf="submitted && !item.name"><span
                            translate>label.field_name_required</span></small>
                </div>

                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <label for="module"><span translate>label.component</span></label>
                    <sf-dropdown-generic appendTo="body" [list]="OrderServiceTemplateList"
                        [id]="item.woOrderServiceTemplateId" tabindex="5"
                        (selectedItemOutput)="reciverFeedbackTemplate($event)"
                        [disabled]="!canUpdate"></sf-dropdown-generic>
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">

                    <label for="description"><span translate>label.description</span></label>
                    <textarea rows="4" cols="30" pInputTextarea id="description" [(ngModel)]="item.description"
                        [disabled]="!canUpdate" tabindex="4"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
                </div>
                <!-- <div class="p-field mt-4">
                    <label for="code"><span translate>label.productivityFactor</span></label>
                    <input type="text" pInputText id="code" [(ngModel)]="item.productivityFactor" required [disabled]="!canUpdate" />
                </div> -->
            </div>



            <!-- <div class="p-field m-2">
                        <label for="code"><span translate>label.executionTime</span></label>
                        <p-calendar [timeOnly]="true"  id="executionTime" [(ngModel)]="item.executionTime"  [disabled]="!canUpdate" />
                    </div> -->

            <div class="formgrid grid">
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <label for="unitValueA"><span>{{labelUnitValueA}}</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                        <p-inputNumber type="text" pInputText id="unitValueA" [(ngModel)]="item.unitValueA"
                            [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="6" />
                    </div>
                </div>
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showTaxA">
                    <label for="unitValueTaxA"><span>{{labelUnitValueTaxA}}</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                        <p-inputNumber type="text" pInputText id="unitValueTaxA" [(ngModel)]="item.unitValueTaxA"
                            [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="7" />
                    </div>
                </div>                
            </div>

            <div class="formgrid grid">
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showValueB">
                    <label for="unitValueB"><span>{{labelUnitValueB}}</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                        <p-inputNumber type="text" pInputText id="unitValueB" [(ngModel)]="item.unitValueB"
                            [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="8" />
                    </div>
                </div>
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showTaxB">
                    <label for="unitValueTaxB"><span>{{labelUnitValueTaxB}}</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                        <p-inputNumber type="text" pInputText id="unitValueTaxB" [(ngModel)]="item.unitValueTaxB"
                            [disabled]="!canUpdate" [minFractionDigits]="2" tabindex="9" />
                    </div>
                </div>                
            </div>

            <div class="formgrid grid">
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showRange">
                    <label for="totalRangeValueFrom"><span translate>label.totalRangeValueFrom</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                        <p-inputNumber type="text" pInputText id="totalRangeValueFrom"
                            [(ngModel)]="item.totalRangeValueFrom" [disabled]="!canUpdate" [minFractionDigits]="2"
                            tabindex="10" />

                    </div>
                </div>
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6" *ngIf="showRange">
                    <label for="totalRangeValueTo"><span translate>label.totalRangeValueTo</span></label>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"><span translate>label.moneySymbol</span></span>
                        <p-inputNumber type="text" pInputText id="totalRangeValueTo"
                            [(ngModel)]="item.totalRangeValueTo" [disabled]="!canUpdate" [minFractionDigits]="2"
                            tabindex="11" />
                    </div>
                </div>
            </div>

            <div class="formgrid grid">
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <input type="checkbox" id="isActive" [(ngModel)]="item.isActive">
                    <label for="isActive"><span translate>label.active</span></label>
                </div>
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <label for="icon"><span translate>label.icon</span></label>
                    <sf-dropdown-generic appendTo="body" [list]="icons" tabindex="5" [id]="item.icon"
                        (selectedItemOutput)="reciverFeedbackIcon($event)"
                        [disabled]="!canUpdate"></sf-dropdown-generic>
                </div>                
            </div>

            <div class="formgrid grid">                
                <div class="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                    <label for="icon"><span translate>Tipos de Arquivo</span></label>
                    <sf-sc-archive-type-list-multi [selectedItems]="item.archiveTypeList" (chosedItem)="feedbackArchiveType($event)"></sf-sc-archive-type-list-multi>
                </div>
            </div>            
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <div style="float: right; display: flex; align-items: flex-end; gap: 8px;">
            <button pButton pRipple icon="pi pi-times" style="width: 110px; margin: 3px;"
                class="p-button-danger p-mr-2 mt-3" [label]="'button.cancel' | translate"
                (click)="hideDialog()"></button>
            <button pButton pRipple icon="pi pi-check" style="width: 110px; margin: 3px;"
                class="p-button-success p-mr-2 mt-3" [label]="'button.confirm' | translate"
                (click)="saveItem()"></button>

        </div>
    </ng-template>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>