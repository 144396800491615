import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';

import { SfcLandingHeroComponent } from './components/sfc-landing-hero.component';
import { SfcLandingFeaturesComponent } from './components/sfc-landing-features.component';
import { SfcLandingHighlightComponent } from './components/sfc-landing-highlight.component';
import { SfcLandingPriceComponent } from './components/sfc-landing-price.component';
import { SfcLandingFooterComponent } from './components/sfc-landing-footer.component';
import { SfcLandingTopbarComponent } from './components/sfc-landing-topbar.component';

@Component({
    selector: 'sfc-landing',
    standalone: true,
    imports: [RouterModule,
                SfcLandingTopbarComponent,
                SfcLandingHeroComponent,
                SfcLandingFeaturesComponent,
                SfcLandingHighlightComponent,
                SfcLandingPriceComponent,
                SfcLandingFooterComponent,
                RippleModule,
                StyleClassModule,
                ButtonModule,
                DividerModule],
    template: `
        <div class="bg-surface-0 dark:bg-surface-900">
            <div id="home" class="landing-wrapper overflow-hidden">
                <sfc-landing-topbar class="py-6 px-6 mx-0 md:mx-12 lg:mx-20 lg:px-20 flex items-center justify-between relative lg:static" />
                <sfc-landing-hero />
                <sfc-landing-features />
                <sfc-landing-highlight />
                <sfc-landing-price />
                <sfc-landing-footer />
            </div>
        </div>
    `
})
export class SfcLandingComponent{}
