import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService, MessageService } from 'primeng/api';

import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { DropDown, GenericItem } from 'src/app/shared/model/base-model';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { Table } from 'primeng/table';
import { ScArchiveTypeService } from 'src/app/core/services/sc-archive-type/sc-archive-type.service';
import { ScArchiveTypeOutput } from 'src/app/models/response/sc-archive-type.response';

@Component({
    selector: 'sc-config-grid',
    templateUrl: './sc-archive-type-grid.component.html',
    styleUrls: ['./sc-archive-type-grid.component.scss'],
    providers: [ConfirmationService, MessageService],
    encapsulation: ViewEncapsulation.None
})

export class ScArchiveTypeGridComponent implements OnInit {

    public list: ScArchiveTypeOutput[] = [];
    public item: ScArchiveTypeOutput;

    public logComponent: string = "ScArchiveTypeGrid";
    public language: string = "pt-BR";
    public flUpdate: boolean = false;
    public dialogHeader: string = "";
    public btnNewLabel: string = "";
    public type: string = "";

    public submitted: boolean;
    public displayMsg: boolean = false;
    public displayDialog: boolean;

    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
    public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";

    fieldValidation: boolean = false;

    constructor(  private service: ScArchiveTypeService,
                  private userTokenService: UserTokenService,
                  private constantsService: ConstantsService,
                  private messageService: MessageService,
                  private translateService: TranslateService,
                  private translateConfigService: TranslateConfigService,
                  private breadcrumbService: BreadcrumbService,
                  private confirmationService: ConfirmationService
                  )
    { }

    ngOnInit(): void {

        this.language = this.translateConfigService.getLanguage();
        this.flUpdate = this.userTokenService.hasPermissionArray([this.constantsService.ROLE_SF_ADMIN]);
        this.getList();
        this.translate();
    }

    translate()
    {
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));
        (async () => {
             await sleep(100);
             const labAccessControl = this.translateService.instant('label.accessControl') + ' ';
             const labPage = ' ' + this.translateService.instant('label.archiveType');
             this.breadcrumbService.setItems([{label: labAccessControl},{label: labPage, routerLink: ['archive-type']} ]);
             this.dialogHeader = this.translateService.instant('label.archiveType');
             this.btnNewLabel = this.translateService.instant('label.new_ArchiveType');
         })();
    }

    getList() {
        this.service.getList("", this.language).subscribe(
            {
                next: (res) => {
                    if (res)
                        this.list = res.data;
                    console.log(res);
                },
                error: (err) => {
                    console.log(`${this.logComponent} - getList : ${err}.`);
                }
            });
    }

    delete() {
        this.service
            .delItem(this.item.id)
            .subscribe(
                {
                    next: () =>
                    {
                        this.messageSuccess('message.record_deleted');
                        this.getList();
                    },
                    error: (err) => {
                        this.messageError('api_response.ERROR');
                        console.log(`${this.logComponent} - delItem : ${err}.`);
                    }
                });
    }

    save() {
        if (this.item.code.trim()) {
            if (this.item.id) {
                this.service
                    .updItem(this.item.id, this.item)
                    .subscribe(
                        {
                            next: () => {
                                this.messageSuccess('message.record_updated');
                                this.getList();
                            },
                            error: (err) => {
                                this.messageError('api_response.ERROR');
                                console.log(`${this.logComponent} - UpdItem : ${err}.`);
                            }
                        });

            } else {
                this.service
                    .addItem(this.item)
                    .subscribe(
                        {
                            next: (result) => {
                                if (result == null) {
                                    this.messageWarning('label.item_exists');
                                } else {
                                    this.messageSuccess('message.record_inserted');
                                    this.getList();
                                }
                            },
                            error: (err) => {
                                this.messageError('api_response.ERROR');
                                console.log(`ScCompanyStatusGrid - InsItem : ${err}.`);
                            }
                        });
            }

            this.list = [...this.list];
            this.item = {};
        }
    }

    messageSuccess(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000});
    }

    messageError(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000});
    }

    messageWarning(message: string){
        this.messageService.add({severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.warning'), detail: this.translateService.instant(message), life: 3000});
    }

    reciverFeedbackType(type: string) {
        this.type = type;
    }

    // reciverFeedbackItem(item: GenericItem) {
    //     this.item = item;
    //     if (this.type == this.constantsService.DELETE)
    //         this.delete();
    //     else
    //         this.save();
    // }

    addItem() {
        this.item = {};
        this.submitted = true;
        this.displayDialog = true;
        //this.feedbackType(this.constantsService.INSERT);
    }

    updItem(item: ScArchiveTypeOutput) {
        this.item = {...item};
        this.displayDialog = true;
        this.submitted = true;
        //this.feedbackType(this.constantsService.UPDATE);
    }

    delItem(item: ScArchiveTypeOutput) {
        this.item = {...item};
        //this.feedbackType(this.constantsService.DELETE);

        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_delete')+' ' + item.name + '?',
            header: this.translateService.instant('title.confirmation'),
            acceptLabel: this.translateService.instant('button.yes'),
            rejectLabel: this.translateService.instant('button.no'),
            acceptButtonStyleClass: 'p-button-success',
            rejectButtonStyleClass: 'p-button-danger',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.delete();
            }
      });
    }

    saveItem()
    {
        this.save();
        this.displayDialog = false;
    }

    hideDialog() {
        this.displayDialog = false;
        this.submitted = false;
    }

    clear(table: Table) {
        table.clear();
    }

    reciverFeedbackColor(color: string) {
        this.item.color = color;
    }

    reciverFeedbackIcon(icon: DropDown) {
        this.item.icon = icon.id;
    }

    getFiledValidation(value: boolean) {
        this.fieldValidation = value;
        return this.fieldValidation;
    }
}