import { WoOrder } from './../../../../pages/wo-order/wo-order.model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { SfMessageService } from 'src/app/core/services/core/sf-message.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { UtFormItemDefaultValueService } from 'src/app/core/services/ut/ut-form-item-default-value.service';
import { UtFormUtFormItemService } from 'src/app/core/services/ut/ut-form-ut-form-item.service';
import { UtFormItemDefaultValueOutput } from 'src/app/models/response/ut-form-item-default-value.model';
import { UtFormItemValue } from 'src/app/models/response/ut-form-item-value.mode';
import { UtFormUtFormItemOutput } from 'src/app/models/response/ut-form-ut-form-item.model';
import { WoOrderOutput } from 'src/app/pages/wo-order/wo-order.model';
import { WoOrderService } from 'src/app/pages/wo-order/wo-order.service';
import { WoWorkFlowTaskOutput } from 'src/app/pages/wo-workflow/wo-workflow.model';


@Component({
  selector: 'wo-order-client-detail-task',
  templateUrl: './wo-order-client-detail-task.component.html',
  styleUrls: ['./wo-order-client-detail-task.component.scss']
})
export class WoOrderClientDetailTaskComponent implements OnInit {

    @Input() itemTask: WoWorkFlowTaskOutput = {};
    @Input() itemOrder: WoOrderOutput = {};
    @Output() closeDialog = new EventEmitter();


    public loading: boolean = false;
    public language: string = "pt-BR";
    public flUpdate: boolean = false;
    public showForm: boolean = false;

    public userName: string = "";
    public listForm: UtFormUtFormItemOutput [] = [];
    public listUtFormItemValue: UtFormItemValue[] = [];

    public message: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1 text-lg font-semibold";
    public classBtnDel: string = "p-button p-button-danger p-button-outlined p-m-1 mr-1 text-lg font-semibold";




  public pageTitle: string = '';

  idTemplateItemValorPadrao: number = 0;

  constructor(private userTokenService: UserTokenService,
                private messageService: SfMessageService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private confirmationService: ConfirmationService,
                private woOrderService: WoOrderService,
                private utFormItemService: UtFormUtFormItemService) { }



    ngOnInit(): void {
        //this.loading = true;
        this.userName = this.userTokenService.getUserName();
        this.language = this.translateConfigService.getLanguage();
        this.flUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN','SF_SELLER','ROLE_ADMIN','ROLE_MANAGER','ROLE_SUPERVISOR']);
        this.onGetForm();

        if (this.flUpdate == false){
            this.messageService.messageError('message.accessDenied');
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
        }

    }

    onGetForm(){

        if (this.itemTask.utFormId != null && this.itemTask.utFormId != undefined && this.itemTask.utFormId != "00000000-0000-0000-0000-000000000000"){

            this.utFormItemService
            .getList(this.itemTask.utFormId, this.language)
            .subscribe(
                {
                    next: (res) => {
                        if (res) {
                            if (res.data != null) {
                                this.listForm = res.data;
                                this.showForm = true;
                                console.log(this.listForm);
                            }
                        }

                    },
                    error: (err) => {
                        console.log(`WorkFlowDetail - updStatus`)
                        console.log(err)
                        this.message = this.translateService.instant('api_response.' + err.error);

                        if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                            this.message = this.translateService.instant('api_response.ERROR');

                        this.messageService.messageError(this.message);
                    }
                });
        }
    }

    onSaveStatus(){



        this.woOrderService
        .updStatusByTask (this.itemOrder.id, this.itemTask.woOrderStatusNextId, this.itemTask.id, "Ação: "+this.itemTask.name+" realizada pelo usuário: "+this.userName, this.listForm)
        .subscribe(
            {
                next: () => {
                    this.messageService.messageSuccess(this.translateService.instant('message.record_updated'));
                    this.closeDialog.emit();
                },
                error: (err) => {
                    console.log(`WorkFlowDetail - updStatus`)
                    console.log(err)
                    this.message = this.translateService.instant('api_response.' + err.error);

                    if (this.message == "api_response.[object ProgressEvent]" || this.message == "api_response.[object Object]" || this.message == "api_response.null")
                        this.message = this.translateService.instant('api_response.ERROR');

                    this.messageService.messageError(this.message);
                }
            });
    }


    onCancel() {
        this.confirmationService.confirm({
            message: this.translateService.instant('message.confirm_cancel'),
        header: this.translateService.instant('title.confirmation'),
        acceptLabel: this.translateService.instant('button.yes'),
        rejectLabel: this.translateService.instant('button.no'),
        acceptButtonStyleClass: 'p-button-success',
        rejectButtonStyleClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.closeDialog.emit();
            }
        });
    }
}
