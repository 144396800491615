import { Table } from 'primeng/table';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { WoOrderOutput } from 'src/app/pages/wo-order/wo-order.model';
import { WoOrderService } from 'src/app/pages/wo-order/wo-order.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';

@Component({
    selector: 'wo-order-cartorio-grid',
    templateUrl: './wo-order-cartorio-grid.component.html',
    styleUrls: ['./wo-order-cartorio-grid.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class WoOrderCartorioGridComponent implements OnInit {

    public userId: string;
    public language: string = "pt-BR";
    public list: WoOrderOutput[] = [];
    public flUpdate: boolean = true;
    public pageMessage: string = "";
    public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";

    public woOrderClassId: string  = '';
    public isOnlyTicket: boolean = false;

    constructor(private orderService: WoOrderService,
                private router: Router,
                private notificationsService: NotificationsService,
                private activateRoute: ActivatedRoute,
                private confirmationService: ConfirmationService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private breadcrumbService: BreadcrumbService,
                private userTokenService: UserTokenService) { }

    ngOnInit(): void {


        this.activateRoute.paramMap.subscribe(params => {
            if (params.has('orderClassId')) {
                this.woOrderClassId = params.get('orderClassId');
            }
        });

        this.language = this.translateConfigService.getLanguage();
        this.userId = this.userTokenService.getUserId();


        if (this.flUpdate == false)
        {
            this.classBtnUpd = "p-button p-button-secondary p-m-1 mr-1";
        }

        this.getList();

        this.translate();

    }

     translate(){
        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        (async () => {
            await sleep(100);

            const labOrder = this.translateService.instant('label.order')+" ";
            const labService =  " " + this.translateService.instant('label.order_service_line')+" ";
            //const labServiceNew = " "+this.translateService.instant('label.order_service_new');
            this.breadcrumbService.setItems([{label: labOrder},{label: labService, routerLink: ['order-service']} ]);
            this.pageMessage = this.translateService.instant('grid.showing')+" {first} "+this.translateService.instant('grid.to')+" {last} "+this.translateService.instant('grid.of')+" {totalRecords} "+this.translateService.instant('grid.entries')
        })();
    }

    newService(type: string){

    }

    clear(table: Table, filter: any) {
        table.clear();
        filter.Text = "";
    }

    getList() {
        this.orderService.getList().subscribe(
        {
            next: (res) => {
                if (res)
                    this.list = res.data;
            },
            error: (err) => {
                this.notificationsService.toastNotify('warn', this.translateService.instant('title.sc_company'), this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderCartorioGrid - getList : ${err}.`);
            }
        });
    }


  addItem() {
    this.router.navigate(['order-service-cartorio-new/', this.woOrderClassId, this.isOnlyTicket]);
  }


  updItem(itemId: string) {
    this.router.navigate(['order-detail/', itemId]);
  }

}


