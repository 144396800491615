<sf-loader></sf-loader>
<sf-notification></sf-notification>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="col-12 text-xl">
    <div class="formgrid grid">
        <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
            <label for="login" class="mb1 font-bold">
                <span translate>label.login_email</span>
            </label>
            <input pInputText
                type="text"
                id="login"
                [(ngModel)]="item.login"
                onchange="checkLogin(user.login)"
                [disabled]="!canUpdate || !canChangeLogin"
                class="w-full text-xl"/>
        </div>

        <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
            <label for="email" class="mb1 font-bold">
                <span translate>label.email</span>
            </label>
            <input pInputText
                type="text"
                id="email"
                [(ngModel)]="item.email"
                autofocus
                class="w-full text-xl"
                email="true"
                onchange="checkEmail(user.email)"
                [disabled]="!canUpdate || isMyData"/>
            <small *ngIf="(!item.email || invalidEmail) && isLoaded"  class="p-invalid" translate>validation.invalid_field</small>
        </div>

        <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
            <label for="login" class="mb1 font-bold">
                <span translate>label.default_language</span>
            </label>
            <input pInputText
                type="text"
                id="languageCode"
                [(ngModel)]="item.languageCode"
                [disabled]="!canUpdate && !isMyData"
                class="w-full text-xl"/>
        </div>
    </div>

    <div class="formgrid grid">
        <div class="field col-12 md:col-12 lg:col-12 xl:col-8">
            <label for="name" class="mb1 font-bold">
                <span translate>label.name</span></label>
            <input pInputText
                type="text"
                id="name"
                [(ngModel)]="item.name"
                required
                class="w-full text-xl"
                [disabled]="!canUpdate && !isMyData"/>
            <small class="p-invalid" *ngIf="!item.name && isLoaded" translate>validation.required_field</small>
        </div>
        <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
            <label for="phone" class="mb1 font-bold">
                <span translate>label.phone_number</span>
            </label>
            <p-inputMask mask="(99) 99999-9999"
                [(ngModel)]="item.phone"
                placeholder="(99) 99999-9999"
                id="phone"
                styleClass="mask  text-xl"
                [disabled]="!canUpdate"
                [style]="{'width':'100%'}"
                class=" text-xl w-full">
            </p-inputMask>
        </div>
    </div>



    <div class="formgrid grid mt-1">
        <div class="field col-12 md:col-12 lg:col-8 xl:col-8">
            <label for="structure" class="mb-1 font-bold"><span translate>label.organizational_structure</span> (<span translate>label.access_level</span>)</label>
            <div *ngIf="canUpdate && !isMyData">
                <sf-organizationStructure-tree
                    [id]="detailItem.scOrganizationStructureId"
                    (chosedItem)="reciverFeedbackStructure($event)"
                    [placeHolderLanguage]="'label.select_organization_structure' | translate"
                    class=" text-xl text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary w-full"
                    style="appearance: auto;"
                    styleClass="text-xl">
                </sf-organizationStructure-tree>
            </div>
            <div *ngIf="!canUpdate || isMyData">
                <input pInputText
                type="text"
                id="scOrganizationStructureName"
                [(ngModel)]="item.scOrganizationStructureName"
                required
                class="w-full text-xl"
                disabled/>
            </div>
        </div>

        <div class="field col-12 md:col-12 lg:col-4 xl:col-4">
            <label for="listaPerfil" class="mb-1 font-bold"><span translate>label.access_profile</span></label>
            <sc-role-dropdown
                [id]="item.scRoleId"
                (chosedItem)="reciverFeedbackRole($event)"
                [disabled]="!canUpdate || isMyData "
                class="text-xl"
                styleClass="text-xl">
            </sc-role-dropdown>
        </div>
    </div>

    <div class="formgrid grid mt-1" *ngIf="isMyData">
        <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
            <div class="field-checkbox">
                <p-checkbox ngDefaultControl
                    [(ngModel)]="item.isBlocked"
                    binary="true" id="flBloqueado"
                    name="flAtivo"
                    class="p-d-inline"
                    [disabled]="!canUpdate">
                </p-checkbox>
                <label for="flBloqueado" class="mb1 font-bold"><span translate>label.bloked_user</span></label>
            </div>
        </div>

        <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
            <div class="field-checkbox">
                <p-checkbox ngDefaultControl
                    [(ngModel)]="item.isFirstAccess"
                    binary="true"
                    id="isFirstAccess"
                    name="isFirstAccess"
                    class="p-d-inline"
                    [disabled]="!canUpdate">
                </p-checkbox>
                <label for="isFirstAccess" class="mb1 font-bold"><span translate>label.first_access</span></label>
            </div>
        </div>

        <div class="field col-12 md:col-12 lg:col-6 xl:col-4">
            <div class="field-checkbox">
                <p-checkbox ngDefaultControl
                    [(ngModel)]="item.isEmailConfirmed"
                    binary="true" id="flAtivo"
                    name="isEmailConfirmed"
                    class="p-d-inline"
                    [disabled]="!canUpdate">
                </p-checkbox>
                <label for="isEmailConfirmed" class="mb1 font-bold"><span translate>label.email_verified</span></label>
            </div>
        </div>
    </div>
</div>


