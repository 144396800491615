import { Message } from 'primeng/api';
// import { UserTokenService } from 'src/app/core/services/user-token.service';

import {Component} from '@angular/core';
import { AppMainComponent} from '../../app.main.component';
import {trigger, state, transition, style, animate} from '@angular/animations';
import { Router } from '@angular/router';
import { UserToken } from '../../core/auth.model';
import { Observable } from 'rxjs';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { ScCompanyService } from 'src/app/pages/sc-company/sc-company.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { ScCompanyOutput } from 'src/app/pages/sc-company/sc-company.model';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { StringFunctionsService } from 'src/app/core/services/shared-functions/string-functions.service';

@Component({
    selector: 'app-inline-profile',
    templateUrl: './app.profile.component.html',
    styleUrls: ['./app.profile.component.scss'],
    animations: [
        trigger('menu', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*'
            })),
            state('hidden', style({
                height: '0px'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppProfileComponent {

    public user$: Observable<UserToken>;
    public user?: UserToken;
    public companyList?: ScCompanyOutput[];
    public pictureUri: string = "";
    public hasPicture: boolean = false;
    public id: string;
    public hasClerk: boolean = false;
    public hasCompanies: boolean = true;
    public dialogHeader: string = "";
    public showProfile: boolean = false;
    public lang: string = "";
    public lblLogout: string = "";
    public lblCompanies: string = "";

    constructor(public app: AppMainComponent,
                private router:Router,
                private authService: AuthService,
                private notificationsService: NotificationsService,
                private userTokenService: UserTokenService,
                private companyService: ScCompanyService,
                private translateService: TranslateService,
                private translateConfigService: TranslateConfigService,
                private stringFunctionsService: StringFunctionsService) {}

    ngOnInit(): void {


        this.lang = this.translateConfigService.getLanguage();
        this.changeLanguage(this.lang);
        this.user$ = this.userTokenService.getUser();
        //this.app.usermenuActive = true;

        this.user$.subscribe({
            next: (data) => { this.user = data; }
            });

        this.hasPicture = (this.user.PictureUri != "" || this.user.PictureUri.length > 1);
        this.hasClerk = (this.user.ScCompanyConfig.includes("CLERK") && this.user.ScUserSignatureId != null);
    }


    changeLanguage(type: string) {

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        this.translateConfigService.changeLanguage(type);
        (async () => {
            await sleep(100);
            this.dialogHeader = this.translateService.instant('label.user_details');
            this.lblLogout = this.translateService.instant('label.logout');
            this.lblCompanies = this.translateService.instant('label.companyChange');
        })();
    }

    getCompanyList() {
        this.companyService.getDropDownList().subscribe(
            {
                next: (res) => {
                    if (res)
                    {
                        this.companyList = res.data;
                        this.hasCompanies = (this.companyList.length > 1);
                    }
                },
                error: (err) => {
                    console.log(`AppMainComponent - getCompanyList : ${err}.`);
                }
            });
    }


    onProfileClick(event) {
        this.getCompanyList();
        this.app.usermenuClick = true;
        this.app.usermenuActive = !this.app.usermenuActive;
        event.preventDefault();
    }

    onProfileItemClick(event, item) {
        this.app.usermenuClick = true;

        if (this.app.activeProfileItem === item)
            this.app.activeProfileItem = null;
        else
            this.app.activeProfileItem = item;

        event.preventDefault();
    }

    onProfileSubItemClick(event) {
        event.preventDefault();
    }

    onProfileLogoutClick() {
        this.logout();
    }

    redirectToUserPwd(){
        this.router.navigate(['user-pwd']);
    }

    redirectToLogin(){
        this.router.navigate(['/login']);
    }

    logout(){
        this.userTokenService.logout();
        this.redirectToLogin();
        setTimeout(() => {
            this.redirectToLogin();
        }, 300 );
    }

    onChangeCompany(id: string) {
        this.authService
        .authenticateCompanyChange(this.user.email, id, this.lang)
        .subscribe(
        {
            next: (res) => {
                location.reload();
            },
            error: (err) => {
                console.log(err);
                this.notificationsService.toastNotify('error',this.translateService.instant('label.changeCompany'), this.translateService.instant('api_response.'+this.stringFunctionsService.getFirstWord(err.error.Message)));
            }
        });
    }
}
