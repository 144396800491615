import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { ListUtFormUtFormItem, UtFormUtFormItemOutput } from "src/app/models/response/ut-form-ut-form-item.model";


@Injectable({
    providedIn: 'any'
})
export class UtFormUtFormItemService {
    private environmentUrl: string = '';

    constructor(private httpClient: HttpClient) {
        this.environmentUrl = environment.ApiCore + '/Form/';
    }

    getList(utFormId: string, language: string): Observable<ListUtFormUtFormItem> {
        let url = this.environmentUrl + utFormId + "/FormItem?&language="+language;
        console.log(url);
        return this.httpClient.get<ListUtFormUtFormItem>(url);
    }

    addItem(utFormId: string, model: UtFormUtFormItemOutput): Observable<UtFormUtFormItemOutput> {
        return this.httpClient.post(this.environmentUrl + utFormId + "/FormItem", model);
    }

    delItem(utFormId: string, id: string) {
        return this.httpClient.delete(this.environmentUrl + utFormId + "/FormItem/" + id);
    }






}
