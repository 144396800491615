<sf-loader></sf-loader>
<sf-notification></sf-notification>
<section class="c-item-registration">
    <div class="c-item-registration__header">
        <span class="c-item-registration__header--title">{{ pageTitle }}</span>
    </div>
    <div class="box">
        <div class="wrapper">
            <p-tabView id="personTabView">
                <p-tabPanel header="Dados Pessoais" class="form__item--label" [selected]="true"  [headerStyle]='{"font-size": "19px"}'>
                    <div class="c-item-registration__content">

                        <form class="form" [formGroup]="form">
                            <div class="formgrid grid">
                                <div class="col-12 sm:col-6 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="ssn">
                                            <span translate>label.person_ssn</span>
                                        </label>
                                        <p-inputMask
                                            mask="999.999.999-99"
                                            formControlName="cpf"
                                            placeholder="999.999.999-99"
                                            id="cpf"
                                            inputId="cpf"
                                            styleClass="mask p-inputtext-lg"
                                            [disabled]="!flUpdate || isPersonActive"
                                            [style]="{'width':'100%'}"
                                            (onComplete)="onCompleteCpf(this)"
                                        ></p-inputMask>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-6 md:col-6 lg:col-9">
                                    <div class="form__item text-right" *ngIf="!flClient">
                                        <p-rating
                                            [readonly]="true"
                                            [cancel]="false">
                                        </p-rating>
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="name">
                                            <span translate>label.name</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="name"
                                            id="name"
                                            type="text"
                                        />
                                        <small class="p-invalid" *ngIf="isFieldValid('name')">
                                            <span translate>label.field_name_required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="email">
                                            <span translate>label.email</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="email"
                                            id="email"
                                            type="text"
                                        />
                                        <small class="p-invalid" *ngIf="isFieldValid('email')">
                                            <span translate>label.field_email_required</span>
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="dateOfBirth">
                                            <span translate>label.date_of_birth</span>
                                        </label>
                                        <div class="flex-auto p-fluid">
                                            <p-calendar
                                                formControlName="dateOfBirth"
                                                id="dateOfBirth"
                                                [showIcon]="true"
                                                inputId="buttondisplay"
                                                [showOnFocus]="false"

                                            />
                                        </div>
                                        <!-- <small class="p-invalid" *ngIf="isFieldValid('dateOfBirth')">
                                            <span translate>label.field_date_of_birth_required</span>
                                        </small> -->
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonGenderId">
                                            <span translate>label.gender</span>
                                        </label>
                                        <ng-container *ngIf="loaded">
                                            <sf-sc-person-gender-dropdown
                                                [id]="form.get('scPersonGenderId').value"
                                                (chosedItem)="reciverFeedbackPersonGender($event)"
                                                [placeHolderLanguage]="'label.gender' | translate">
                                            </sf-sc-person-gender-dropdown>
                                            <small class="p-invalid" *ngIf="isFieldValidDropdown('scPersonGenderId')">
                                                <span translate>label.field_gender_required</span>
                                            </small>
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" for="phone_number">
                                            <span translate>label.phone_number</span>
                                        </label>
                                        <p-inputMask
                                            mask="(99)9?99999999"
                                            formControlName="phone_number"
                                            placeholder="(99)999999999"
                                            inputId="phone_number"
                                            [disabled]="!flUpdate"
                                            styleClass="mask p-inputtext-lg"
                                            [style]="{'width':'100%'}">
                                        </p-inputMask>
                                        <!-- <small class="p-invalid" *ngIf="isFieldValid('phone_number')">*</small> -->
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" for="mobile_number">
                                            <span translate>label.mobile_number</span>
                                        </label>
                                        <p-inputMask
                                            mask="(99)9?99999999"
                                            formControlName="mobile_number"
                                            placeholder="(99)99999999"
                                            inputId="mobile_number"
                                            [disabled]="!flUpdate"
                                            styleClass="mask p-inputtext-lg"
                                            [style]="{'width':'100%'}">
                                        </p-inputMask>
                                        <!-- <small class="p-invalid" *ngIf="isFieldValid('mobile_number')">*</small> -->
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonTypeNationalityId">
                                            <span translate>label.type_nationality</span>
                                        </label>
                                        <ng-container *ngIf="loaded">
                                            <sf-sc-person-type-nationality-dropdown
                                                [id]="form.get('scPersonTypeNationalityId').value"
                                                (chosedItem)="reciverFeedbackPersonTypeNationality($event)"
                                                [placeHolderLanguage]="'label.type_nationality' | translate">
                                            </sf-sc-person-type-nationality-dropdown>
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scCountryId"><span
                                                translate>label.nationality</span></label>
                                        <ng-container *ngIf="loaded">
                                            <sf-dropdown-generic *ngIf="loadListCountry"
                                                InputId="scCountryId"
                                                [list]="listCountry"
                                                [id]="form.get('scCountryId').value"
                                                (selectedItemOutput)="reciverFeedbackCountry($event, 'person')"
                                                [filter]="true">
                                            </sf-dropdown-generic>
                                            <!-- <small class="p-invalid" *ngIf="isFieldValidDropdown('scCountryId')">
                                                <span translate>label.field_nationality_required</span>
                                            </small> -->
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scStateId">
                                            <span translate>label.nationality</span>
                                        </label>
                                        <ng-container *ngIf="loaded">
                                            <sf-dropdown-generic *ngIf="loadListState"
                                                InputId="scStateId"
                                                [list]="listState"
                                                [id]="form.get('scStateId').value"
                                                (selectedItemOutput)="reciverFeedbackState($event, 'person')"
                                                [filter]="true">
                                            </sf-dropdown-generic>
                                            <!-- <small class="p-invalid" *ngIf="isFieldValidDropdown('scStateId')">
                                                <span translate>label.field_birth_state_required</span>
                                            </small> -->
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scCityId">
                                            <span translate>label.birth_state</span>
                                        </label>
                                        <ng-container *ngIf="loaded">
                                            <sf-dropdown-generic *ngIf="loadListCity"
                                                InputId="birthCity"
                                                [list]="listCity"
                                                [id]="form.get('scCityId').value"
                                                (selectedItemOutput)="reciverFeedbackCity($event)"
                                                [filter]="true">
                                            </sf-dropdown-generic>
                                            <small class="p-invalid" *ngIf="isFieldValidDropdown('scCityId')">
                                                <span translate>label.field_birth_city_required</span></small>
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-6 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonMaritalStatusId">
                                            <span translate>label.marital_status</span>
                                        </label>
                                        <ng-container *ngIf="loaded">
                                            <sf-sc-person-marital-status-dropdown
                                                [id]="form.get('scPersonMaritalStatusId').value"
                                                (chosedItem)="reciverFeedbackPersonMaritalStatus($event)"
                                                [placeHolderLanguage]="'label.marital_status' | translate">
                                            </sf-sc-person-marital-status-dropdown>
                                            <small class="p-invalid" *ngIf="isFieldValidDropdown('scPersonMaritalStatusId')">
                                                <span translate>label.field_marital_status_required</span>
                                            </small>
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-6 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonMatrimonialPropertyId">
                                            <span translate>label.matrimonial_property</span>
                                        </label>
                                        <ng-container *ngIf="loaded">
                                            <sf-sc-person-matrimonial-property-dropdown
                                                [id]="form.get('scPersonMatrimonialPropertyId').value"
                                                (chosedItem)="reciverFeedbackPersonMatrimonialProperty($event)"
                                                [placeHolderLanguage]="'label.matrimonial_property' | translate">
                                            </sf-sc-person-matrimonial-property-dropdown>
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-12 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="profession">
                                            <span translate>label.profession</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="profession"
                                            id="profession"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="motherName">
                                            <span translate>label.mother_name</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="motherName"
                                            id="motherName"
                                            type="text"
                                        />
                                        <!-- <small class="p-invalid" *ngIf="isFieldValid('motherName')">
                                            <span translate>label.field_mother_name_required</span>
                                        </small> -->
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="fatherName">
                                            <span translate>label.father_name</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="fatherName"
                                            id="fatherName"
                                            type="text"
                                        />
                                        <!-- <small class="p-invalid" *ngIf="isFieldValid('fatherName')">
                                            <span translate>label.field_father_name_required</span>
                                        </small> -->
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="formgrid grid">
                                <div class="col-12">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="address">
                                            <span translate>label.principalAddress</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="address"
                                            id="observation"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div> -->

                            <div class="formgrid grid">
                                <div class="col-12">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="observation">
                                            <span translate>label.observation</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="observation"
                                            id="observation"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>

                </p-tabPanel>
                <p-tabPanel header="Dados do Cônjuge" [disabled]="!flagTabPanelSpouse" [headerStyle]='{"font-size": "20px"}'>
                    <div class="c-item-registration__content">

                        <form class="form" [formGroup]="formSpouse">

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-6 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="ssn">
                                            <span translate>label.person_ssn</span>
                                        </label>
                                        <p-inputMask
                                            mask="999.999.999-99"
                                            formControlName="cpf"
                                            placeholder="999.999.999-99"
                                            id="cpf"
                                            inputId="cpf"
                                            styleClass="mask p-inputtext-lg"
                                            [disabled]="!flUpdate || isPersonSpouseActive"
                                            [style]="{'width':'100%'}"
                                            (onComplete)="onCompleteCpf(this)"
                                        ></p-inputMask>
                                        <!-- [readonly]="formSpouse.get('cpf').value !== undefined && formSpouse.get('cpf').value != null && formSpouse.get('cpf').value != '' && formSpouse.get('cpf').value != '00000000000'" -->
                                    </div>
                                </div>
                                <div class="col-12 sm:col-6 md:col-6 lg:col-9">
                                    <div class="form__item text-right" *ngIf="!flClient">
                                        <p-rating
                                            [readonly]="true"
                                            [cancel]="false">
                                        </p-rating>
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="name">
                                            <span translate>label.name</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="name"
                                            id="name"
                                            type="text"
                                        />
                                        <small class="p-invalid" *ngIf="isFieldValidSpouse('name')">
                                            <span translate>label.field_name_required</span>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="email">
                                            <span translate>label.email</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="email"
                                            id="email"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="dateOfBirth">
                                            <span translate>label.date_of_birth</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="dateOfBirth"
                                            id="dateOfBirth"
                                            type="date"
                                        />
                                        <!-- <small class="p-invalid" *ngIf="isFieldValidSpouse('dateOfBirth')">
                                            <span translate>label.field_date_of_birth_required</span>
                                        </small> -->
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonGenderId">
                                            <span translate>label.gender</span>
                                        </label>
                                        <ng-container *ngIf="loadedSpouse">
                                            <sf-sc-person-gender-dropdown
                                                [id]="form.get('scPersonGenderId').value"
                                                (chosedItem)="reciverFeedbackPersonGender($event)"
                                                [placeHolderLanguage]="'label.gender' | translate">
                                            </sf-sc-person-gender-dropdown>
                                        </ng-container>
                                        <ng-container *ngIf="!loadedSpouse">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" for="phone_number">
                                            <span translate>label.phone_number</span></label>
                                        <p-inputMask
                                            mask="(99)9?99999999"
                                            formControlName="phone_number"
                                            placeholder="(99)999999999"
                                            inputId="phone_number"
                                            [disabled]="!flUpdate"
                                            styleClass="mask p-inputtext-lg"
                                            [style]="{'width':'100%'}">
                                        </p-inputMask>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" for="mobile_number">
                                            <span translate>label.mobile_number</span>
                                        </label>
                                        <p-inputMask
                                            mask="(99)99999999"
                                            formControlName="mobile_number"
                                            placeholder="(99)99999999"
                                            inputId="mobile_number"
                                            [disabled]="!flUpdate"
                                            styleClass="mask p-inputtext-lg"
                                            [style]="{'width':'100%'}">
                                        </p-inputMask>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonTypeNationalityId">
                                            <span translate>label.type_nationality</span>
                                        </label>
                                        <ng-container *ngIf="loadedSpouse">
                                            <sf-sc-person-type-nationality-dropdown
                                                [id]="form.get('scPersonTypeNationalityId').value"
                                                (chosedItem)="reciverFeedbackPersonTypeNationality($event)"
                                                [placeHolderLanguage]="'label.type_nationality' | translate">
                                            </sf-sc-person-type-nationality-dropdown>
                                        </ng-container>
                                        <ng-container *ngIf="!loadedSpouse">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scCountryId">
                                            <span translate>label.nationality</span>
                                        </label>
                                        <ng-container *ngIf="loadedSpouse">
                                            <sf-dropdown-generic *ngIf="loadListCountry"
                                                InputId="scCountryId"
                                                [list]="listCountry" [id]="form.get('scCountryId').value"
                                                (selectedItemOutput)="reciverFeedbackCountrySpouse($event, 'spouse')"
                                                [filter]="true">
                                            </sf-dropdown-generic>
                                        </ng-container>
                                        <ng-container *ngIf="!loadedSpouse">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scStateId">
                                            <span translate>label.nationality</span>
                                        </label>
                                        <ng-container *ngIf="loadedSpouse">
                                            <sf-dropdown-generic *ngIf="loadListState"
                                                InputId="scStateId"
                                                [list]="listState" [id]="form.get('scStateId').value"
                                                (selectedItemOutput)="reciverFeedbackStateSpouse($event, 'spouse')"
                                                [filter]="true">
                                            </sf-dropdown-generic>
                                        </ng-container>
                                        <ng-container *ngIf="!loadedSpouse">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scCityId">
                                            <span translate>label.birth_state</span>
                                        </label>
                                        <ng-container *ngIf="loadedSpouse">
                                            <sf-dropdown-generic *ngIf="loadListCity"
                                                InputId="birthCity"
                                                [list]="listCity" [id]="form.get('scCityId').value"
                                                (selectedItemOutput)="reciverFeedbackCitySpouse($event)"
                                                [filter]="true">
                                            </sf-dropdown-generic>
                                        </ng-container>
                                        <ng-container *ngIf="!loadedSpouse">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">

                                <div class="col-12 sm:col-6 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonMaritalStatusId">
                                            <span translate>label.marital_status</span>
                                        </label>
                                        <ng-container *ngIf="loaded">
                                            <sf-sc-person-marital-status-dropdown
                                                [id]="form.get('scPersonMaritalStatusId').value"
                                                (chosedItem)="reciverFeedbackPersonMaritalStatus($event)"
                                                [placeHolderLanguage]="'label.marital_status' | translate">
                                            </sf-sc-person-marital-status-dropdown>
                                            <small class="p-invalid" *ngIf="isFieldValidDropdown('scPersonMaritalStatusId')">
                                                <span translate>label.field_marital_status_required</span>
                                            </small>
                                        </ng-container>
                                        <ng-container *ngIf="!loaded">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="scPersonMatrimonialPropertyId">
                                            <span translate>label.matrimonial_property</span>
                                        </label>
                                        <ng-container *ngIf="loadedSpouse">
                                            <sf-sc-person-matrimonial-property-dropdown
                                                [id]="form.get('scPersonMatrimonialPropertyId').value"
                                                (chosedItem)="reciverFeedbackPersonMatrimonialProperty($event)"
                                                [placeHolderLanguage]="'label.matrimonial_property' | translate">
                                            </sf-sc-person-matrimonial-property-dropdown>
                                        </ng-container>
                                        <ng-container *ngIf="!loadedSpouse">
                                            <span class="container__list--loader">Carregando...</span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-12 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="profession">
                                            <span translate>label.profession</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="profession"
                                            id="profession"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-12 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="motherName">
                                            <span translate>label.mother_name</span></label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="motherName"
                                            id="motherName"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 sm:col-12 md:col-12 lg:col-6">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="fatherName">
                                            <span translate>label.father_name</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="fatherName"
                                            id="fatherName"
                                            type="text"/>
                                    </div>
                                </div>
                            </div>
                            <div class="formgrid grid">
                                <div class="col-12 sm:col-12 md:col-12 lg:col-12">
                                    <div class="form__item">
                                        <label class="form__item--label" htmlFor="observation">
                                            <span translate>label.observation</span>
                                        </label>
                                        <input pInputText
                                            class="form__item--field p-inputtext-lg"
                                            formControlName="observation"
                                            id="observation"
                                            type="text"/>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </p-tabPanel>
            </p-tabView>

            <div class="c-item-registration__footer">
                <div style="float: right; ">
                    <div  class="grid">
                        <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                            <button pButton pRipple
                                icon="pi pi-external-link"
                                style="width: 100%;"
                                class="p-button p-mr-2 mt-3 p-button-lg"
                                [label]="'label.addresses' | translate"
                                (click)="uploadFiles()">
                            </button>
                        </div>
                        <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                            <button pButton pRipple
                                icon="pi pi-id-card"
                                style="width: 100%;"
                                class="p-button p-mr-2 mt-3 p-button-lg"
                                [label]="'label.document_inserted' | translate"
                                (click)="uploadFiles()">
                            </button>
                        </div>
                        <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                            <button pButton pRipple
                                icon="pi pi-times"
                                style="width: 100%;"
                                class="p-button-danger p-mr-2 mt-3 p-button-lg"
                                [label]="'button.cancel' | translate"
                                (click)="onGoBack()">
                            </button>
                        </div>
                        <div class="col-12 sm:col-6 md:col-3 lg:col-3">
                            <button pButton pRipple
                                icon="pi pi-check"
                                style="width: 100%;"
                                class="p-button-success p-mr-2 mt-3 p-button-lg"
                                [label]="'button.confirm' | translate"
                                (click)="onConfirm()"
                                loadingIcon="pi pi-spin pi-sync"
                                [loading]="loading"
                                [disabled]="!form.valid || !flUpdate">
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>


