import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthorizationGuard } from "src/app/core/guards/authorization.guard";
import { AuthenticationGuard } from "src/app/core/guards/authentication.guard";
import { ScPersonFullGridComponent } from "./pages/sc-person-full/sc-person-full-grid/sc-person-grid/sc-person-full-grid.component";
import { ScPersonFullDetailComponent } from "./pages/sc-person-full/sc-person-full-detail/sc-person-full-detail/sc-person-full-detail.component";
import { ScPersonFileComponent } from "src/app/shared/components/sc-person-file/sc-person-file.component";

const routes: Routes = [
    {
        path: ':flagPersonType', component: ScPersonFullGridComponent,
        canActivate: [AuthenticationGuard, AuthorizationGuard],
        data:{
            roles:['SF_ADMIN','SF_SELLER','SC_PERSON_U','SC_PERSON_R']
        }
    },
    {
      path: 'full/insert/:type', component: ScPersonFullDetailComponent,
      canActivate: [AuthenticationGuard, AuthorizationGuard],
      data:{
          roles:['SF_ADMIN','SF_SELLER','SC_PERSON_U','SC_PERSON_R']
      }
    },
    {
      path: 'full/edit/:type/:id', component: ScPersonFullDetailComponent,
      canActivate: [AuthenticationGuard, AuthorizationGuard],
      data:{
          roles:['SF_ADMIN','SF_SELLER','SC_PERSON_U','SC_PERSON_R','ROLE_CLIENT','HOME_R']
      }
    },
    {
      path: 'documents/:id/:name', component: ScPersonFileComponent,
      canActivate: [AuthenticationGuard, AuthorizationGuard],
      data:{
          roles:['SF_ADMIN','SF_SELLER','SC_PERSON_U','SC_PERSON_R']
      }
    }
  ];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class ScPersonRoutingModule { }
