import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router } from '@angular/router';


import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { delay } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { DataPwdForgot } from 'src/app/core/auth.model';
import { StringFunctionsService } from 'src/app/core/services/shared-functions/string-functions.service';

@Component({
    templateUrl:'./forgot-step.component.html',
    styleUrls:  ['./forgot-step.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ForgotStepComponent implements OnInit{

    public items: MenuItem[];
    public labelForgot: string;
    public labelCode: string;
    public labelNewPassword: string;
    public labelConfirmation: string;
    public activeIndex: number = 0;

    public backDisabled: boolean = true;
    public nextDisabled: boolean = false;
    public valid: boolean = true;

    public item: DataPwdForgot = {};
    public pwd: string;
    public pwd2: string;
    public lang: string = "pt-BR";



    constructor(
        private router: Router,
        private translateConfigService: TranslateConfigService,
        private translateService: TranslateService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private stringFunctionsService: StringFunctionsService
    ){}



    ngOnInit() {
        this.lang = this.translateConfigService.getLanguage();
        this.changeLanguage(this.lang);
    }


    translate(){

        this.labelForgot = this.translateService.instant('label.email_info');
        this.labelCode = this.translateService.instant('label.access_code');
        this.labelNewPassword = " "+this.translateService.instant('label.pwd_update');
        this.labelConfirmation = this.translateService.instant('label.pwd_updated');

        this.items = [
            {
                label: this.labelForgot
            },
            {
                label: this.labelCode,
            },
            {
                label: this.labelNewPassword,
            },
            {
                label: this.labelConfirmation,
            }
        ];

    }

    changeLanguage(type: string) {

        const sleep = (ms) => new Promise(r => setTimeout(r, ms));

        //Need 50ms to get and set de language by cookie
        this.translateConfigService.changeLanguage(type);
        (async () => {
            await sleep(50);
            this.translate();
        })();
    }

    next(){
        this.valid = true;

        if (this.activeIndex == 0){

            if (this.item.email != "" && this.item.email != undefined)
            {
                this.forgot("Forgot");
            }
            else {
                this.valid = false;
            }
        }
        else if (this.activeIndex == 1){
            if (this.item.email != "" && this.item.email != undefined && this.item.accessCode != "" && this.item.accessCode != undefined)
            {
                this.forgot("Code");
            }
            else {
                this.valid = false;
            }
        }
        else if (this.activeIndex == 2){

            if (this.item.email != "" && this.item.email != undefined && this.pwd != "" && this.pwd != undefined)
            {
                if (this.pwd == this.pwd2)
                    this.forgot("PWD");
                else{
                    this.notificationsService.toastNotify('error',this.translateService.instant('title.reset_password'), this.translateService.instant('message.pwd_confirm_invalid'));
                    this.valid = false;
                }
            }
            else {
                this.valid = false;
            }
        }


    }

    back(){
        if (this.activeIndex > 0)
            this.activeIndex--;

        this.valid = true;
        this.setButton();
    }

    setButton(){
        this.nextDisabled = false;
        this.backDisabled = false;

        if (this.activeIndex == 0)
            this.backDisabled = true;
        else if (this.activeIndex == 3)
            this.nextDisabled = false;

    }

    forgot(option: string): boolean{
        this.authService
        .resetPwd(this.item.email, this.item.accessCode, this.pwd, this.pwd2, option)
        .subscribe(
            {
                next: (res) =>{
                    if (res){

                        if (res.data.returnCode.length >= 2 && res.data.returnCode.substring(0,2) == "OK"){
                            this.valid = true;

                            if (this.activeIndex < 3){
                                this.activeIndex++;
                                this.setButton();
                            }

                            if (this.item.accessCode != "" && this.item.accessCode != undefined)
                                this.item.accessCode = res.data.accessCode;
                        }
                        else {
                            this.notificationsService.toastNotify('error',this.translateService.instant('title.reset_password'), this.translateService.instant('api_response.'+ this.stringFunctionsService.getFirstWord(res.data.returnCode)));
                            this.valid = false;
                        }


                    }
                    else{
                        this.valid = false;
                        this.notificationsService.toastNotify('error',this.translateService.instant('title.reset_password'), this.translateService.instant('message.email_invalid'));

                    }
                },
                error: (err) => {
                    this.valid = false;
                    this.notificationsService.toastNotify('error',this.translateService.instant('title.reset_password'), this.translateService.instant('api_response.ERROR'));
                }
            });



        return false;
    }



    ngOnDestroy() {
        // if (this.subscription) {
        //     this.subscription.unsubscribe();
        // }
    }

    login()
    {
        this.router.navigate(['/login']);
    }


}

