import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserToken } from 'src/app/core/auth.model';
import { BreadcrumbService } from 'src/app/layout/topbar/app.breadcrumb.service';
import { BreadCrumb } from 'src/app/shared/components/sf-breadcrumb/sf-breadcrumb.model';
import { DropDown } from 'src/app/shared/model/base-model';
import { WoOrderServiceOutput } from '../wo-order-service.model';
import { TranslateConfigService } from 'src/app/core/services/translate-config.service';
import { UserTokenService } from 'src/app/core/services/user-token.service';
import { WoOrderServiceTemplateService } from '../../wo-order-service-template/wo-order-service-template.service';
import { MessageService } from 'primeng/api';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { WoOrderServiceService } from '../wo-order-service.service';

@Component({
  selector: 'sf-wo-order-service-detail',
  templateUrl: './wo-order-service-detail.component.html',
  styleUrls: ['./wo-order-service-detail.component.scss']
})
export class WoOrderServiceDetailComponent implements OnInit {

  @Input() id: string;
  public user$: Observable<UserToken>;
  public user?: UserToken;
  public item: WoOrderServiceOutput = {};
  public OrderServiceTemplateList: DropDown[] = [];

  public classBtnUpd: string = "p-button p-button-success p-m-1 mr-1";
  public classBtnDel: string = "p-button p-button-warning p-m-1 mr-1";
  public showValueB: boolean = false;
  public showTaxA: boolean = true;
  public showTaxB: boolean = false;
  public showRange: boolean = true;
  public labelUnitValueA: string = "";
  public labelUnitValueB: string = "";
  public labelUnitValueTaxA: string = "";
  public labelUnitValueTaxB: string = "";
  public TotalRangeValueFrom: string = "";
  public TotalRangeValueTo: string = "";

  public language: string = "pt-BR";
  public pageMessage: string = "";
  public displayDialog: boolean = false;
  public submitted: boolean = false;
  public canUpdate: boolean = false;
  public isLoading: boolean = true;
  public isCartorio: boolean = false;
  public dialogHeader: string = "";

  public BREAD_CRUMB_LIST: Array<BreadCrumb> = [
    { label: 'Configuração', routerLink: '/', selected: false },
    { label: 'Serviços', routerLink: '/sfc-order-service', selected: true }
  ];

  public icons: DropDown[] = [
    { id: 'null', name: '' },
    { id: 'abertura-firma.png', name: 'Abertura Firma' },
    { id: 'alteracao-contratual.png', name: 'Alteração Contratual' },
    { id: 'autenticacao.png', name: 'Autenticação' },
    { id: 'escritura.png', name: 'Escritura' },
    { id: 'inventario.png', name: 'Inventário' },
    { id: 'procuracao.png', name: 'Procuração' },
    { id: 'reconhecimento-firma.png', name: 'Reconhecimento Firma' },
    { id: 'separacao-divorcio.png', name: 'Separacao/Divorcio' },
    { id: 'testamento.png', name: 'Testamento' }
  ];

  @Output() closeDialog = new EventEmitter();

  constructor(private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService,
    private translateConfigService: TranslateConfigService,
    private userTokenService: UserTokenService,
    private orderServiceTemplateService: WoOrderServiceTemplateService,
    private messageService: MessageService,
    private constantsService: ConstantsService,
    private orderServiceService: WoOrderServiceService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.language = this.translateConfigService.getLanguage();
    this.user$ = this.userTokenService.getUser();

    this.user$.subscribe({
      next: (data) => { this.user = data; }
    }); ""

    this.isCartorio = true; //this.user.ScCompanyConfig.includes("M_SFC_NOTAS");
    this.canUpdate = this.userTokenService.hasPermissionArray(['SF_ADMIN', 'SF_SELLER', 'ROLE_ADMIN', 'ROLE_MANAGER']);

    this.getOrderServiceTemplateList()
    this.geItem();
    this.translate();

    if (this.canUpdate == false) {
      this.classBtnDel = "p-button p-button-secondary p-m-1 mr-1";
    }
  }

  geItem() {
    this.orderServiceService.getItem(this.id).subscribe(
      {
        next: (res) => {
          if (res)
            this.item = res.data;
          this.isLoading = false;
        },
        error: (err) => {
          this.messageError(this.translateService.instant('api_response.ERROR'));
          console.log(`WoOrderServiceDetailComponent - getItem : ${err}.`);
        }
      });
  }

  translate() {
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));

    (async () => {
      await sleep(100);
      const labA = this.translateService.instant('label.services') + " ";
      const labB = " " + this.translateService.instant('label.service');
      this.breadcrumbService.setItems([{ label: labA }, { label: labB, routerLink: ['order-service'] }]);
      this.pageMessage = this.translateService.instant('grid.showing') + " {first} " + this.translateService.instant('grid.to') + " {last} " + this.translateService.instant('grid.of') + " {totalRecords} " + this.translateService.instant('grid.entries');
      this.dialogHeader = this.translateService.instant('title.woOrderServiceEdit');

      if (this.isCartorio) {
        this.showValueB = this.showTaxA = this.showTaxB = this.showRange = true;
        this.labelUnitValueA = "Recompe";
        this.labelUnitValueB = "Emolumentos";
        this.labelUnitValueTaxA = "Fiscalização";
        this.labelUnitValueTaxB = "ISS";
      }
      else {
        this.showValueB = (this.user.ScCompanyConfig.includes("UNIT_VALUE_B"));
        this.showTaxA = (this.user.ScCompanyConfig.includes("UNIT_VALUE_TAX_A"));
        this.showTaxB = (this.user.ScCompanyConfig.includes("UNIT_VALUE_TAX_B"));
        this.showRange = (this.user.ScCompanyConfig.includes("RANGE_VALUE"));

        this.labelUnitValueA = this.translateService.instant('label.unitValue');
        this.labelUnitValueB = this.translateService.instant('label.unitValueAux');
        this.labelUnitValueTaxA = this.translateService.instant('label.unitTaxValue');
        this.labelUnitValueTaxB = this.translateService.instant('label.unitTaxValueAux');
      }

    })();
  }

  getOrderServiceTemplateList() {
    this.orderServiceTemplateService.getDropDownList().subscribe(
      {
        next: (res) => {
          if (res)
            this.OrderServiceTemplateList = res.data;

        },
        error: (err) => {
          this.messageError(this.translateService.instant('api_response.ERROR'));
          console.log(`WoOrderServiceGrid - getOrderServiceTemplateList : ${err}.`);
        }
      });
  }

  messageSuccess(message: string) {
    this.messageService.add({ severity: this.constantsService.SEVERITY_SUCCESS, summary: this.translateService.instant('title.success'), detail: this.translateService.instant(message), life: 3000 });
  }

  messageError(message: string) {
    this.messageService.add({ severity: this.constantsService.SEVERITY_WARNING, summary: this.translateService.instant('title.error'), detail: this.translateService.instant(message), life: 3000 });
  }

  reciverFeedbackIcon(event) {
    if (event != null && event !== undefined) {
      this.item.icon = event;
    }
  }

  feedbackArchiveType(event) {        
    this.item.archiveTypeList = event;
  }

  saveItem() {
    this.submitted = true;
    if (this.item.code.trim()) {
      if (this.item.id) {

        this.orderServiceService
          .updItem(this.item)
          .subscribe(
            {
              next: () => {
                this.messageSuccess(this.translateService.instant('message.record_updated'));
                this.isLoading = true;
                //this.getList();
              },
              error: (err) => {
                this.messageError(this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderServiceDetailComponent - updItem : ${err}.`);
              }
            });

      } else {
        this.item.executionTime = new Date('1970-01-01T00:10:00Z');
        this.item.productivityFactor = 1.00;
        this.orderServiceService
          .addItem(this.item)
          .subscribe(
            {
              next: () => {
                this.messageSuccess(this.translateService.instant('message.record_inserted'));
                this.isLoading = true;
                //this.getList();
              },
              error: (err) => {
                this.messageError(this.translateService.instant('api_response.ERROR'));
                console.log(`WoOrderServiceDetailComponent - updItem : ${err}.`);
              }
            });
      }

      this.displayDialog = false;
      this.submitted = false;
      this.item = {};
    }
  }

  reciverFeedbackTemplate(event) {
    {
      if (event != null && event !== undefined && event != 'null') {
        this.item.woOrderServiceTemplateId = event;
      }
    }
  }

  hideDialog() {
    this.displayDialog = false;
    this.submitted = false;
    this.closeDialog.emit();
  }
}