import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SfSubHeaderComponent {
  @Input() title!: string;
  @Input() description!: string;
}
