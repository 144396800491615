import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  readonly ROLE_SF_ADMIN: string = 'SF_ADMIN';
  readonly ROLE_SF_SELLER: string = 'SF_SELLER';
  readonly ROLE_PAYMENT: string = 'ROLE_PAYMENT';

  readonly DELETE: string = 'DEL';
  readonly INSERT: string = 'INS';
  readonly UPDATE: string = 'UPD';

  readonly SEVERITY_SUCCESS: string = 'success';
  readonly SEVERITY_WARNING: string = 'warn';



  constructor() { }
}
