import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { ScPersonSearchComponent } from './pages/sc-person-search/sc-person-search.component';
import { TableModule } from 'primeng/table';
import { InputMaskModule } from 'primeng/inputmask';
import { TranslateModule } from '@ngx-translate/core';
import { ScPersonDropdownComponent } from './pages/sc-person-dropdown/sc-person-dropdown.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScPersonBasicComponent } from './pages/sc-person-basic/sc-person-basic.component';
import { ScPersonRoutingModule } from './sc-person.routing.module';
import { ScPersonFullGridComponent } from './pages/sc-person-full/sc-person-full-grid/sc-person-grid/sc-person-full-grid.component';
import { ScPersonFullDetailComponent } from './pages/sc-person-full/sc-person-full-detail/sc-person-full-detail/sc-person-full-detail.component';
import { ScPersonGenderDropdownComponent } from './pages/sc-person-gender/sc-person-gender-dropdown/sc-person-gender-dropdown.component';
import { ScPersonMatrimonialPropertyDropdownComponent } from './pages/sc-person-matrimonial-property/sc-person-matrimonial-property-dropdown/sc-person-matrimonial-property-dropdown.component';
import { ScPersonMaritalStatusDropdownComponent } from './pages/sc-person-marital-status/sc-person-marital-status-dropdown/sc-person-marital-status-dropdown.component';
import { ScPersonTypeNationalityDropdownComponent } from './pages/sc-person-type-nationality/sc-person-type-nationality-dropdown/sc-person-type-nationality-dropdown.component';
import { ScPersonService } from 'src/app/core/services/sc-person/sc-person.service';
import { LoaderModule } from 'src/app/shared/components/loader/loader.module';
import { NotificationsModule } from 'src/app/shared/notifications/notifications.module';
import { CheckboxModule } from 'primeng/checkbox';
import { SfDropDownModule } from 'src/app/shared/components/dropdown/dropdown.module';
import { RatingModule } from 'primeng/rating';
import { SharedModule } from 'src/app/shared/shared.module';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';

const components = [
    ScPersonSearchComponent,
    ScPersonDropdownComponent,
    ScPersonBasicComponent,
    ScPersonFullGridComponent,
    ScPersonFullDetailComponent,
    ScPersonGenderDropdownComponent,
    ScPersonMatrimonialPropertyDropdownComponent,
    ScPersonMaritalStatusDropdownComponent,
    ScPersonTypeNationalityDropdownComponent
];

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [
        CommonModule,
        ScPersonRoutingModule,
        ReactiveFormsModule,
        InputTextModule,
        InputMaskModule,
        ButtonModule,
        TableModule,
        TranslateModule,
        DropdownModule,
        DialogModule,
        ConfirmDialogModule,
        FormsModule,
        CheckboxModule,
        SfDropDownModule,
        LoaderModule,
        NotificationsModule,
        AutoCompleteModule,
        RatingModule,
        SharedModule,
        DividerModule,
        TabViewModule,
        CalendarModule
    ],
    providers: [ScPersonService]
})

export class SCPersonModule { }
