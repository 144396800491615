import { DataViewModule } from 'primeng/dataview';
import { WoOrderSorting } from 'src/app/models/response/wo-order-sorting.model';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {ProgressBarModule} from 'primeng/progressbar';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';

// Application Components
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppMenuComponent} from './layout/menu/app.menu.component';
import {AppMenuitemComponent} from './layout/menu/app.menuitem.component';
import {AppBreadcrumbComponent} from './layout/topbar/app.breadcrumb.component';
import {AppMegamenuComponent} from './layout/menu/app.megamenu.component';
import {AppRightPanelComponent} from './layout/menu/app.rightpanel.component';
import {AppTopBarComponent} from './layout/topbar/app.topbar.component';



// Demo pages
import {AppNotfoundComponent} from './pages/basic/app.notfound.component';
import {AppErrorComponent} from './pages/basic/app.error.component';
import {AppAccessdeniedComponent} from './pages/basic/app.accessdenied.component';



// Application services
import {BreadcrumbService} from './layout/topbar/app.breadcrumb.service';
import {MenuService} from './layout/menu/app.menu.service';

//import { AppConfig } from './layout/app.config';
import { LoginModule } from './login/login.module';
import { NotificationsService } from './shared/notifications/notifications.service';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { LoaderModule } from './shared/components/loader/loader.module';
import { NotificationsModule } from './shared/notifications/notifications.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PipesModule } from './shared/pipes/pipes.module';

import { ScCompanyModule } from './pages/sc-company/sc-company.module';
import { BasicModule } from './pages/basic/basic.module';
import { ScClaimModule } from './pages/sc-claim/sc-claim.module';
import { ScRoleModule } from './pages/sc-role/sc-role.module';
import { ScMenuModule } from './pages/sc-menu/sc-menu.module';
import { CoreModule } from './core/core.module';
import { ScOrganizationStructureModule } from './pages/sc-organization-structure/sc-organization-structure.module';
import { WoOrderModule } from './pages/wo-order/wo-order.module';
import { WoWorkFlowModule } from './pages/wo-workflow/wo-workflow.module';
import { WoOrderCartorioModule } from './modules/cartorio/wo-order-cartorio/wo-order-cartorio.module';
import { SfcServiceAutenticacaoModule } from './modules/cartorio/component/sfc-service-autenticacao/sfc-service-autenticacao.module';
import { WoOrderTypeModule } from './pages/wo-order-type/wo-order-type.module';
import { WoNotarialSealModule } from './pages/wo-notarial-seal/wo-notarial-seal.module';
import { WoSkillModule } from './pages/wo-skill/wo-skill.module';
import { WoOrderServiceModule } from './features/mo-cartorio/wo-order-service/wo-order-service.module';
import { ScModuleModule } from './pages/sc-module/sc-module.module';
import { ScDocumentModule } from './pages/sc-document/sc-document.module';
import { PaPaymentModule } from './pages/pa-payment/pa-payment.module';
import { WoOrderStatusModule } from './features/wo-order-status/wo-order-status.module';
import { SfSubHeaderModule } from './shared/components/sub-header/sub-header.module';
import { ScUserModule } from './features/sc-user/sc-user.module';
import { ChatboxModule } from './shared/components/chatbox/chatbox.module';
import { AvatarModule } from 'primeng/avatar';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        //BreadcrumbModule,
        MenuModule,
        MenubarModule,
        ProgressBarModule,
        ToastModule,
        TabViewModule,

        PipesModule,
        LoaderModule,
        NotificationsModule,
        CoreModule,

        LoginModule,
        BasicModule,
        ScCompanyModule,
        ScClaimModule,
        ScModuleModule,
        ScRoleModule,
        ScMenuModule,
        ScUserModule,
        ScOrganizationStructureModule,
        WoOrderModule,
        WoOrderStatusModule,
        WoOrderTypeModule,
        WoWorkFlowModule,

        WoNotarialSealModule,
        WoOrderCartorioModule,
        SfcServiceAutenticacaoModule,
        WoOrderServiceModule,

        WoSkillModule,


        ScDocumentModule,
        PaPaymentModule,


        SfSubHeaderModule,
        WoOrderModule,

        ChatboxModule,
        AvatarModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'pt'
        })

    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        //AppFooterComponent,
        AppRightPanelComponent,
        AppMegamenuComponent,
        //AppProfileComponent,
        AppBreadcrumbComponent,
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MenuService,
        BreadcrumbService,
        NotificationsService,
        AuthenticationGuard,
        AuthenticationGuard.guards,
        { provide: LOCALE_ID, useValue: 'pt'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
