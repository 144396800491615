<p-card>
    <div class="formgrid grid">
        <div class="card">
            <p-dataView #dv [value]="list">
                <ng-template let-item pTemplate="listItem">

                        <div class="field col-12 sm:col-12 md:col-12 lg:col-2 order-1">
                            <span class="text-xl font-semibold text-surface-900 dark:text-surface-0">
                                {{ item.reference }}
                            </span>
                        </div>

                        <div class="field col-12 sm:col-12 md:col-12 lg:col-7 order-3">
                            <div style="display: block;" class="text-lg font-medium text-surface-900 dark:text-surface-0 mt-2">
                                {{ item.formattedAddress }}
                            </div>
                            <div style="display: block;" class="mt-3 mb-3" >
                                <span class="text-surface-900 dark:text-surface-0 font-medium text-sm">
                                    {{item.city}} / {{item.state}} - {{item.city}}
                                </span>
                            </div>
                        </div>

                        <!--  -->
                        <div class="field col-12 sm:col-12 md:col-12 lg:col-3 order-2">
                            <div class="flex flex-col md:items-end gap-8">
                                <div class="flex flex-row-reverse md:flex-row gap-2">
                                    <p-button
                                        icon="pi pi-edit"
                                        class="flex-auto md:flex-initial whitespace-nowrap"

                                        label="Atualizar Endereço"
                                    />
                                    <p-button
                                    icon="pi pi-edit"
                                    class="flex-auto md:flex-initial whitespace-nowrap"

                                    label="Atualizar Endereço"
                                />
                                </div>
                            </div>
                        </div>

                </ng-template>
            </p-dataView>
        </div>
    </div>

</p-card>


<!-- <div class="grid grid-nogutter justify-content-center">
    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
</div>
<div *ngIf="list != null">
<p-dataView #dv [value]="list" [style]="{'height': '50vh', 'overflow-y': 'auto'}">
    <ng-template #list let-items>
        <div class="grid grid-cols-12 gap-4 grid-nogutter">
            <div class="col-span-12" *ngFor="let item of items; let first = first" class="col-span-12">
                <div
                    class="flex flex-col sm:flex-row sm:items-center p-6 gap-4"
                    [ngClass]="{ 'border-t border-surface-200 dark:border-surface-700': !first }"
                >
                    <div class="md:w-40 relative">
                        <img
                            class="block xl:block mx-auto rounded-border w-full"
                            [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + item.image"
                            [alt]="item.name"
                        />
                    </div>
                    <div class="flex flex-col md:flex-row justify-between md:items-center flex-1 gap-6">
                        <div class="flex flex-row md:flex-col justify-between items-start gap-2">
                            <div>
                                <span class="font-medium text-secondary text-sm">{{ item.city }}</span>
                                <div class="text-lg font-medium text-surface-900 dark:text-surface-0 mt-2">{{ item.city }}</div>
                            </div>
                            <div class="bg-surface-100 dark:bg-surface-700 p-1" style="border-radius: 30px">
                                <div
                                    class="bg-surface-0 dark:bg-surface-900 flex items-center gap-2 justify-center py-1 px-2"
                                    style="border-radius: 30px; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"
                                >
                                    <span class="text-surface-900 dark:text-surface-0 font-medium text-sm">
                                        {{item.city}} / {{item.city}} - {{item.city}}
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col md:items-end gap-8">
                            <span class="text-xl font-semibold text-surface-900 dark:text-surface-0">{{
                                '$' + item.city
                            }}</span>
                            <div class="flex flex-row-reverse md:flex-row gap-2">
                                <p-button
                                    icon="pi pi-trash"
                                    class="flex-auto md:flex-initial whitespace-nowrap"
                                    label="Buy Now"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-dataView>
</div>

 -->
