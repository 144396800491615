import { AuthService } from 'src/app/core/auth/auth.service';
import { InputTextModule } from 'primeng/inputtext';
import { NgModule } from '@angular/core';
import { SignInComponent } from './signin/signin.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StepsModule } from 'primeng/steps';

import { SignUpComponent } from './signup/signup.component';
import { ForgotStepComponent } from './forgot-step/forgot-step.component';

import { LoginRoutingModule } from './login.routing.module';
import { EnvModule } from '../shared/components/enviroment/env.module';
import { CarimboModule } from '../shared/components/carimbo/carimbo.module';
import { ForgotComponent } from './forgot/forgot.component';
import { NotificationsModule } from '../shared/notifications/notifications.module';
import { VMessageModule } from '../shared/components/vmessage/vmessage.module';
import { NotificationsService } from '../shared/notifications/notifications.service';

import { LoginComponent } from './login.component';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PwdComponent } from './pwd/pwd.component';
import { PlatformDetectorService } from '../core/services/platform-detector.service';

import { TranslateModule } from '@ngx-translate/core';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';




@NgModule({
    declarations: [SignInComponent, SignUpComponent, LoginComponent, ForgotComponent, PwdComponent, ForgotStepComponent],
    exports: [SignInComponent, SignUpComponent, ForgotComponent, LoginComponent, PwdComponent, ForgotStepComponent],
    imports: [FormsModule,
        ReactiveFormsModule,
        CommonModule,
        VMessageModule,
        RouterModule,
        LoginRoutingModule,
        EnvModule,
        CarimboModule,
        NotificationsModule,
        ButtonModule,
        ProgressSpinnerModule,
        InputTextModule,
        TranslateModule,
        InputMaskModule,
        MessageModule,
        MessagesModule,
        StepsModule,
        ToastModule,
        CardModule,
        ButtonModule,
        PasswordModule


        ],
    providers: [NotificationsService, AuthService, PlatformDetectorService]
})
export class LoginModule { }
